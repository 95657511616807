//Habit Landing, Widget and Edit page

import { useState, useEffect, useCallback, useRef } from "react";
//import { storage } from "Config";
import { useHistory } from "react-router-dom";
import { useUserContext } from "Authentication";
import { HabitName } from "../UserHabit/style";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { CheckBox, Container, Text, TextContainer } from "./style";
import { RoutineStreakCalculator } from "./RoutineStreakCalculator";
import moment from "moment";
//import { summary } from "date-streaks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClose,
  faCalendarDay,
  faEdit,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
//import CheckMark from "../../assets/img/CheckMark.svg";
import {
  Calendar,
  // Views,
  //DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import { Modal } from "Common";
//import "../UserTodayHabit/habitcalendar.css";
import { WeekDays } from "../HabitsPage/style";
//import { Input } from "../generic/Input";
import { Button } from "../HabitsPage/style";
import { DayOfTheWeek } from "../DayOfTheWeek";
import { db } from "Config";
//import RMModal from "react-modal";
import classes from "../../../Journal/modals/EventsModal.module.css";
//import TextareaAutosize from "react-textarea-autosize";
//import TimePicker from "react-time-picker";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
//import { google, outlook, office365, yahoo, ics } from "calendar-link";
//import { ReminderForAddNewHabit } from "../generic/ReminderForAddNewHabit";
import { useTranslation } from "react-i18next";
import { HabitForm } from "../generic/HabitForm";

var Total_Days_Habit_Tracking = 0;

const localizer = momentLocalizer(moment);

var selectSlotStart = new Date();
var checkDate = new Date();
//used for the modal on the calendar view
var lognotes;
var logreps;
var logrepsNumber;
var logdate;
var logdateformatted;
var logid;

export function UserHabits({
  data,

  confetti,

  isDetailView,
}) {
  const [statusLog, setStatusLog] = useState(
    data.statusLog === undefined || null ? [] : data.statusLog
  );
  const [calendarDates, setCalendarDates] = useState(
    data.statusLog === undefined || null ? [] : data.statusLog
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isUpdateHabitOpen, setIsUpdateHabitOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [statusLogID, setStatusLogID] = useState();
  const {
    completeDailyHabit,
    deleteHabit,
    editHabit,
    completeDailyHabitRemove,
    completeDailyHabitCalendar,
  } = useUserContext();
  const user = useUserContext();
  const [isCalCellOpen, setIsCalCellOpen] = useState(false);
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [isHabitSchedulingReminder, setIsHabitSchedulingReminder] =
    useState(false);
  const [valueTime, setValueTime] = useState("12:00");
  const [getTodayReps, setGetTodayReps] = useState(
    data.totalReps === undefined || data.totalReps === NaN ? 0 : data.todayReps
  );
  const [getRepsModalCalendar, setGetRepsModalCalendar] = useState(false);
  const [myRepsCalendar, setMyRepsCalendar] = useState(1);
  const [myRepsNotesCalendar, setMyRepsNotesCalendar] = useState("Done");
  const [startSelectCalendar, setStartSelectCalendar] = useState(new Date());
  const [getRepsModal, setGetRepsModal] = useState(false);
  const [myReps, setMyReps] = useState(1);
  const { t } = useTranslation();
  var preloadReps = t("habits-userhabits-preloadrepnotes");
  const [myRepsNotes, setMyRepsNotes] = useState(preloadReps);

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isDopeNotesApp, setIsDopeNotesApp] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const history = useHistory();

  //android/ios check

  const [isMobile, setIsMobile] = useState(false);
  // const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  //from Calendar Setting, it'll change the start day of the week.
  const calendarStartDay =
    localStorage.getItem("calendarStartWeekDay") != undefined
      ? parseInt(localStorage.getItem("calendarStartWeekDay"))
      : 0;

  //console.log("what is day", calendarStartDay);

  moment.updateLocale("en", {
    week: {
      dow: calendarStartDay, // 1 = Monday is the first day of the week
    },
  });

  const goDetails = () => {
    history.push(`habits/${data.id}`);
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteHabitId, setDeleteHabitId] = useState("");
  const [totalReps, setTotalReps] = useState(
    data.totalReps === undefined || data.totalReps === NaN ? 0 : data.totalReps
  );
  //const [isJournalDone, setIsJournalDone] = useState(false);
  const [statusLogs, setStatusLogs] = useState([
    {
      id: "",
      date: Date,
      notes: "",
    },
  ]);

  // console.log("what is the ID", data.id)
  //console.log("what is the range", HabitDates)

  const today = moment(new Date()).format("YYYY/MM/DD");

  //  console.log("what is data", data);
  const todayDate = new Date();
  const add60Days = new Date(todayDate);
  add60Days.setDate(todayDate.getDate() + 60);
  const lastDay = data.lastDay != undefined ? data.lastDay : add60Days;
  const durationMinutes = data.durationMinutes ? data.durationMinutes : 15;

  //console.log("what is add60days", add60Days);
  //console.log("what is lastDay", lastDay);

  const [newHabit, setNewHabit] = useState({
    id: data.id,
    name: data.name,
    days: data.days,
    isTrackingReps: data.isTrackingReps,
    durationMinutes: durationMinutes,
    RepsLabel: data.RepsLabel,
    lastDay: lastDay,
  });

  const [events, setEvents] = useState([
    {
      start: Date,
      end: Date,
      title: "",
    },
  ]);

  var statusLogCompleted = [];
  var check =
    data.dateLastUpdated === moment(new Date()).format("YYYYMMDD") &&
    data.done === true
      ? true
      : false;
  const [isDone, setIsDone] = useState(
    data.dateLastUpdated === moment(new Date()).format("YYYYMMDD") &&
      data.done === true
      ? true
      : false
  );
  var completionNumber = data.completionNumber;

  //const [streak, setStreak] = useState([]);

  const addTracking = () => {
    if (newHabit.isTrackingReps) {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: true });
    }
  };

  const addPlanner = () => {
    if (newHabit.isPlanner) {
      setNewHabit({ ...newHabit, ["isPlanner"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isPlanner"]: true });
    }
  };

  const CloseMenu = () => {
    setIsCalCellOpen(false);
    setIsHabitSchedulingReminder(false);
  };

  const mapToRBCFormat = (e) =>
    Object.assign({}, e, {
      title: <div>{e.notes}</div>,
      start: new Date(e.date),
      end: new Date(e.date),
    });

  const close = () => {
    setIsCalendarOpen(false);
    setIsEditOpen(false);
  };

  const OpenEdit = () => {
    setNewHabit({
      id: data.id,
      name: data.name,
      days: data.days,
      duration: data.duration ? data.duration : "12:30",
      time: data.time ? data.time : "12:00",
      isTrackingReps: data.isTrackingReps,
      isPlanner: data.isPlanner != undefined ? data.isPlanner : true,
      RepsLabel: data.RepsLabel,
      lastDay: lastDay,
    });
    setIsEditOpen(true);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    //   console.log(event);
    var backgroundColor = "lightgreen";

    var style = {
      //  disply: "flex",
      backgroundColor: backgroundColor,
      //   height: "100px",
      alignItems: "center",
      borderRadius: "0px",
      opacity: 0.7,
      color: "black",
      border: "2px",
      display: "block",
      paddingBottom: 5,
    };
    return {
      style: style,
    };
  };

  const getStatusLogs = async () => {
    // console.log("status log userhabitToday");
    setStatusLogs([]);
    const statusLogRef = collection(
      db,
      `users/${user.user.id}/habits/${data.id}/statuslog`
    );

    const unsubscribe = onSnapshot(
      statusLogRef,
      (snapshot) => {
        const addedHabits = [];
        snapshot.docChanges().forEach((change) => {
          const item = { ...change.doc.data(), id: change.doc.id };
          switch (change.type) {
            case "added": {
              addedHabits.push(item);
              break;
            }
            case "modified": {
              setStatusLogs((old) =>
                old.map((i) => (i.id === item.id ? item : i))
              );
              break;
            }
            case "removed": {
              setStatusLogs((old) => old.filter((i) => i.id !== item.id));
              break;
            }
          }
        });
        setStatusLogs((old) => [...old, ...addedHabits]);
      },
      (error) => {
        window.location.reload();
      }
    );

    return unsubscribe;
  };

  //setup email log button

  const generateEmail = () => {
    const emailContent = `${statusLogs
      .map(
        (log) =>
          `Date: ${log.date} - %20%0ANote(s): ${log.notes}` + "%20%0A%20%0A"
      )
      .join("\n")}`;

    // Here, you can implement the logic to send the email.
    // This example just logs the email content to the console.
    //console.log(emailContent);
    // console.log(statusLogs);
    var url = `mailto:?subject=My%20Habit%20Logs&body=${data.name}%20%0A%20%0A${emailContent}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  //console.log(formattedDate);
  //console.log("how many journal", getEventsCountForDay());

  useEffect(() => {
    CheckPlatform();
    //bad name on my part, duration is end, time is the start for habits.

    setStatusLogs([]);
    var current = new Date();
    const time = current.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    setValueTime(time);

    //getTotalDaysTracked()
    if (window.isNative) {
      // Post the message back to expo
      setIsNativeApp(true);
    }

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }

    if (window.isDopeNotesApp) {
      setIsDopeNotesApp(true);
    }

    if (window.isIOS) {
      setIsIOS(true);
    }

    var today = moment(new Date()).format("YYYYMMDD");
    //console.log("What is Today", data.dateLastUpdated, today);
    if (today === data.dateLastUpdated) {
      setIsDone(data.done);
    } else {
      setIsDone(false);
    }

    return () => {};
  }, [data.done, statusLog, isDone]);

  const handleOnClick = useCallback(
    async (id) => {
      var statusID = moment(new Date()).format("YYYYMMDD");
      if (check === true) {
        check = false;
        setIsDone(false);
        completionNumber = data.completionNumber - 1;

        completeDailyHabitRemove(
          id,
          statusID,
          completionNumber,
          check,
          totalReps
        );
      } else {
        check = true;
        setIsDone(true);
        completionNumber = data.completionNumber + 1;
        var reps = 0;
        var date = moment(new Date()).format("MM/DD/YYYY");

        var notes = "Done";

        completeDailyHabit(
          id,
          date,
          check,
          statusID,
          completionNumber,
          statusLogCompleted,
          notes,
          reps,
          totalReps
        );

        confetti();

        //window.location.reload()
      }
    },
    [
      check,
      completionNumber,
      completeDailyHabit,
      completeDailyHabitRemove,
      confetti,
      statusLogCompleted,
      totalReps,
      data,
    ]
  );

  const checkClickStatus = (e) => {
    if (check === false) {
      setGetRepsModal(true);
    }
    if (check === true) {
      handleOnClickIsTracking(e);
    }
  };

  const updateMyReps = (e) => {
    e.preventDefault(e);

    handleOnClickIsTracking(e);
  };

  const updateMyRepsCalendar = (e) => {
    e.preventDefault(e);
    // console.log(startSelectCalendar, myRepsNotesCalendar, myRepsCalendar)
    //  alert('what', startSelectCalendar, myRepsNotesCalendar, myRepsCalendar)
    editHabitAdd(startSelectCalendar, myRepsNotesCalendar, myRepsCalendar);
    setGetRepsModalCalendar(false);
  };

  const CloseRepModalCalendar = () => {
    setGetRepsModalCalendar(false);
  };

  const handleOnClickIsTracking = async (e) => {
    e.preventDefault(e);
    var statusID = moment(new Date()).format("YYYYMMDD");
    if (check === true) {
      check = false;
      setIsDone(false);
      completionNumber = data.completionNumber - 1;

      var totalRepsUpdateRemove =
        data.totalReps != undefined || data.totalReps != NaN
          ? data.totalReps
          : 0;
      var totalRepsRemove =
        Number(totalRepsUpdateRemove) - Number(getTodayReps);
      //  console.log("what is this", e.myReps)
      setTotalReps(totalRepsRemove);

      completeDailyHabitRemove(
        data.id,
        statusID,
        completionNumber,
        check,
        totalRepsRemove
      );
    } else {
      check = true;
      setIsDone(true);
      completionNumber = data.completionNumber + 1;

      var notes = myRepsNotes;
      var reps = myReps;
      var totalRepsUpdateCheck =
        data.totalReps != undefined || NaN ? data.totalReps : 0;
      var totalRepsUpdate = Number(totalRepsUpdateCheck) + Number(myReps);
      var date = moment(new Date()).format("MM/DD/YYYY");

      setTotalReps(totalRepsUpdate);
      setGetTodayReps(myReps);
      completeDailyHabit(
        data.id,
        date,
        check,
        statusID,
        completionNumber,
        statusLogCompleted,
        notes,
        reps,
        totalRepsUpdate
      );

      setMyRepsCalendar(1);
      setGetRepsModal(false);

      confetti();

      ///window.location.reload()
    }
  };

  function removeHabit(id) {
    setDeleteHabitId(id);
    setConfirmModalOpen(true);
  }

  function removeHabitAccepted() {
    deleteHabit(deleteHabitId);
    setConfirmModalOpen(false);

    /* setTimeout(() => {
      window.location.reload();
    }, 1000); */
  }

  const convertDate = () => {
    var convertedDate;

    if (lastDay.seconds != undefined) {
      convertedDate = moment(new Date(lastDay.seconds * 1000)).format(
        "MMMM DD, YYYY"
      );
    } else {
      convertedDate = "Not Set";
    }
    return convertedDate;
  };

  const editHabitAdd = async (newDate, notes, myReps) => {
    var newDateConverted = moment(newDate).format("YYYY/MM/DD");
    //console.log("new date", newDateConverted)
    // check = true
    //          isDone = true
    completionNumber = data.completionNumber + 1;
    var statusLogID = moment(newDate).format("YYYYMMDD");
    // console.log("what is the status ID and date".statusLogID, newDateConverted);

    if (data.isTrackingReps) {
      const newTotalReps = Number(totalReps) + Number(myReps);
      setTotalReps(newTotalReps);
      completeDailyHabitCalendar(
        data.id,
        newDateConverted,
        completionNumber,
        statusLogID,
        notes,
        myReps,
        newTotalReps
      );
    } else {
      completeDailyHabitCalendar(
        data.id,
        newDateConverted,
        completionNumber,
        statusLogID,
        notes,
        myReps,
        totalReps
      );
    }
  };

  const editHabitRemove = (newDate, id, newTotalReps) => {
    completionNumber = data.completionNumber - 1;
    setTotalReps(newTotalReps);
    completeDailyHabitRemove(
      data.id,
      id,
      completionNumber,
      check,
      newTotalReps
    );
    setIsCalCellOpen(false);
  };

  const handleSelectSlot = useCallback(({ start, end, id }) => {
    //setEvents((prev) => [...prev, { start, end, id}])

    const eventsOnSelectedDay = statusLogs.filter((event) =>
      moment(event.date).isSame(start, "day")
    );
    if (moment(start).isSame(today)) {
      toast(t("habits-userhabits-warning-date"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      return;
    }
    // console.log("what is the length", eventsOnSelectedDay, start);
    if (eventsOnSelectedDay.length > 0) {
      // Prevent selecting the slot if there are more than one event
      return;
    } else {
      selectSlotStart = start;
      // console.log("what is the date", selectSlotStart)
      const compareDate = moment(start).format("YYYY/MM/DD");
      const alerdateformatted = moment(start).format("MMMM DD, YYYY");
      if (today >= compareDate) {
        if (!data.isTrackingReps) {
          var notes = t("habits-userhabits-preloadrepnotes");
          editHabitAdd(start, notes, 0);
        } else {
          setStartSelectCalendar(start);
          setGetRepsModalCalendar(true);
        }
      } else {
        toast(t("habits-userhabits-warning-date"), {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "pink",
            border: "1px solid #713200",
            fontSize: "16px",
            padding: "10px",
            color: "black",
          },
        });
      }
    }
  });

  const handleClickItem = (event) => {
    //   console.log("clicked")
    if (moment(event.date).isSame(today)) {
      toast(t("habits-userhabits-warning-date"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      return;
    }

    lognotes = event.notes;
    logreps = event.myReps.toString();
    logrepsNumber = event.myReps;
    logdate = event.date;
    logid = event.id;
    logdateformatted = moment(logdate).format("MMMM DD, YYYY");
    setIsCalCellOpen(true);
  };

  const filterEventsToOnePerDay = (events) => {
    const eventsByDay = {};
    events.forEach((event) => {
      const startOfDay = moment(event.start).startOf("day").toISOString();
      if (!eventsByDay[startOfDay]) {
        eventsByDay[startOfDay] = event;
      }
    });
    return Object.values(eventsByDay);
  };

  const LogModalRemoveStatusLog = () => {
    const compareDate = moment(logdate).format("YYYY/MM/DD");
    const dateForAlert = moment(logdate).format("MMMM DD, YYYY");
    //console.log("what is the event", compareDate)
    //console.log("what is today", today)

    if (compareDate < today) {
      // deleteItemDB(id)
      if (!data.isTrackingReps) {
        editHabitRemove(logdate, logid, 0);
      } else {
        var newTotalReps = totalReps - logrepsNumber;
        editHabitRemove(logdate, logid, newTotalReps);
      }
    } else {
      // editHabitRemove(logdate, logid, newTotalReps);
      toast(t("habits-userhabits-warning-date"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
  };

  //console.log("what is data", data);

  const maxLength = 50; // Maximum length of text to display
  const truncatedText = (text) => {
    const newText =
      text.length > maxLength
        ? text.substring(0, maxLength) + "..." // Truncate text to maximum length
        : text;
    return newText;
  };
  const backgroundColor = () => {
    if (!isDetailView && isDone) {
      return "lightgreen";
    } else if (!isDetailView && !isDone) {
      return "mistyrose";
    } else {
      return "";
    }
  };
  return (
    <Container style={{ backgroundColor: backgroundColor() }}>
      {isDetailView ? (
        <TextContainer>
          {confirmModalOpen ? (
            <ConfirmationModal
              isOpen={confirmModalOpen}
              text={t("habits-userhabits-warning-delete")}
              onClickCancel={() => setConfirmModalOpen(false)}
              onClickConfirm={removeHabitAccepted}
            />
          ) : null}

          {!data.isTrackingReps ? (
            <div>
              <CheckBox
                style={{ cursor: "pointer" }}
                onClick={() => handleOnClick(data.id)}
                done={isDone}
              >
                <div
                  stlye={{
                    disply: "flex",
                    flexDirection: "column",
                  }}
                >
                  <HabitName
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.75em",
                      height: 75,
                      width: "100%",
                      justifyContent: "center",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {truncatedText(data.name)}
                    </span>
                  </HabitName>
                </div>
              </CheckBox>
              <p
                style={{
                  textAlign: "center",
                  marginTop: -20,
                  width: "100%",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
              >
                {t("habits-userhabits-status-label")}{" "}
                {isDone
                  ? t("habits-userhabits-status-completed")
                  : t("habits-userhabits-status-pending")}
              </p>
            </div>
          ) : (
            <>
              <CheckBox
                style={{ cursor: "pointer" }}
                onClick={checkClickStatus}
                done={isDone}
              >
                <div
                  stlye={{
                    disply: "flex",
                    flexDirection: "column",
                  }}
                >
                  <HabitName
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.75em",
                      height: 75,
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        textOverflow: "ellipsis",

                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {truncatedText(data.name)}
                    </span>
                  </HabitName>
                </div>
              </CheckBox>
              <p style={{ textAlign: "center", marginTop: -30, width: "100%" }}>
                {t("habits-userhabits-status-instructions")}
              </p>
            </>
          )}
          <div>
            <button
              onClick={() => {
                setIsCalendarOpen(true);
                getStatusLogs();
              }}
              className="reminder-habit-buttons"
            >
              <FontAwesomeIcon
                icon={faCalendarDay}
                size="1x"
                style={{
                  fontFamily: "inherit",
                  paddingRight: 5,
                  cursor: "pointer",
                }}
              />
              {t("common-button-calendar")}
            </button>
          </div>
          {/*}   <div>
          <button
            onClick={() => setIsHabitSchedulingReminder(true)}
            className="reminder-habit-buttons"
          >
            <FontAwesomeIcon
              icon={faBell}
              size="1x"
              style={{
                fontFamily: "inherit",
                paddingRight: 5,
                cursor: "pointer",
              }}
            />
            Create Reminder
          </button>
        </div> */}
          <div style={{ paddingTop: 20 }}>
            <Text
              style={{
                fontWeight: "bold",
                paddingBottom: 8,
                paddingTop: 5,
                fontSize: 16,
              }}
            >
              {t("habits-userhabits-label-dayscompleted")}{" "}
              <mark
                style={{ fontWeight: "bold", fontSize: 16, color: "purple" }}
              >
                {data.completionNumber}
              </mark>
            </Text>
            {data.isTrackingReps ? (
              <Text
                style={{
                  fontWeight: "bold",
                  paddingBottom: 8,
                  paddingTop: 5,
                  fontSize: 16,
                }}
              >
                {t("habits-userhabits-label-total")} {data.RepsLabel}:{" "}
                <mark
                  style={{ fontWeight: "bold", fontSize: 16, color: "purple" }}
                >
                  {Number(totalReps)}
                </mark>{" "}
              </Text>
            ) : (
              <Text
                style={{
                  fontWeight: "bold",
                  paddingBottom: 8,
                  paddingTop: 5,
                  fontSize: 16,
                }}
              >
                {t("habits-userhabits-label-notrecording")}
              </Text>
            )}
          </div>

          <div>
            <div style={{ paddingBottom: 10, paddingTop: 20 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 16,
                  paddingBottom: 15,
                  paddingTop: 5,
                }}
              >
                {t("habits-userhabits-label-habitschedule")}
              </Text>
              <WeekDays>
                {Array.from(Array(7).keys()).map((element, index) => (
                  <div style={{ paddingLeft: 5 }} key={index}>
                    <DayOfTheWeek
                      key={element}
                      state={data}
                      createHabit={false}
                      weekday={element}
                    />
                  </div>
                ))}
              </WeekDays>
            </div>

            <div style={{ paddingBottom: 10, paddingTop: 20 }}>
              <span
                style={{ paddingLeft: 5, fontWeight: "bold", fontSize: 16 }}
              >
                {t("habits-userhabits-label-time")}{" "}
                {data.time ? data.time : "12:00pm"}
              </span>
            </div>
            <div style={{ paddingBottom: 10, paddingTop: 10 }}>
              <span
                style={{ paddingLeft: 5, fontWeight: "bold", fontSize: 16 }}
              >
                {t("habits-userhabits-label-duration")}{" "}
                {data.durationMinutes ? data.durationMinutes : "15 "}{" "}
              </span>
            </div>

            <div style={{ paddingBottom: 10, paddingTop: 10 }}>
              <span
                style={{ paddingLeft: 5, fontWeight: "bold", fontSize: 16 }}
              >
                {t("habits-userhabits-label-lastday")} {convertDate()}
              </span>
            </div>

            <div style={{ paddingBottom: 10, paddingTop: 10 }}>
              <input
                type="checkbox"
                readOnly
                style={{
                  transform: "scale(1.3)",
                  marginLeft: 0,
                  marginTop: 0,
                }}
                //  onChange={addPlanner}
                checked={data.isPlanner != undefined ? data.isPlanner : true}
              />
              <span
                style={{
                  paddingLeft: 5,
                  fontWeight: "bold",
                  fontSize: 16,
                  userSelect: "none",
                }}
              >
                {t("habits-userhabits-label-showplanner")}
              </span>
            </div>

            <div>
              <div>
                <div>
                  <button onClick={OpenEdit} className="reminder-habit-buttons">
                    <FontAwesomeIcon
                      icon={faEdit}
                      size="1x"
                      style={{ paddingRight: 5 }}
                    />
                    {t("common-button-edit")}
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => removeHabit(data.id)}
                    className="reminder-habit-buttons"
                  >
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      size="1x"
                      style={{ paddingRight: 5 }}
                    />
                    {t("common-button-remove")}
                  </button>
                </div>
              </div>
              <Text
                style={{
                  fontWeight: "bold",
                  paddingTop: 10,
                  textAlign: "center",
                }}
              >
                {t("common-label-created")}:{" "}
                {moment(data.dateAdded).format("MMMM DD, YYYY")}{" "}
              </Text>
            </div>
            <div style={{ paddingBottom: 150 }} />
          </div>
        </TextContainer>
      ) : (
        <TextContainer>
          <ConfirmationModal
            isOpen={confirmModalOpen}
            text={t("habits-userhabits-warning-delete")}
            onClickCancel={() => setConfirmModalOpen(false)}
            onClickConfirm={removeHabitAccepted}
          />

          {!data.isTrackingReps ? (
            <div>
              <input
                type="checkbox"
                checked={isDone}
                style={{ marginTop: 0 }}
                onChange={() => handleOnClick(data.id)}
                //  className={classes.details}
              />

              <span
                style={{
                  textAlign: "center",
                  lineHeight: 1.3,
                  fontWeight: "bold",
                  fontSize: "1.2em",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  paddingLeft: 7,
                  paddingBottom: 10,
                }}
                onClick={() => handleOnClick(data.id)}
              >
                {" "}
                {truncatedText(data.name)}
              </span>
            </div>
          ) : (
            <div>
              <input
                type="checkbox"
                checked={isDone}
                style={{ marginTop: 0 }}
                onChange={checkClickStatus}
                //  className={classes.details}
              />
              <span
                style={{
                  textAlign: "center",
                  lineHeight: 1.3,
                  fontWeight: "bold",
                  fontSize: "1.2em",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  paddingLeft: 7,
                  paddingBottom: 10,
                }}
                onClick={checkClickStatus}
              >
                {" "}
                {truncatedText(data.name)}
              </span>
            </div>
          )}
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
            }}
          >
            <button
              onClick={() => {
                setIsCalendarOpen(true);
                getStatusLogs();
              }}
              style={{
                color: "blue",
                textDecoration: "underline",
                backgroundColor: "transparent",
                border: 0,
              }}
            >
              <FontAwesomeIcon
                icon={faCalendarDay}
                size="1x"
                style={{
                  fontFamily: "inherit",
                  paddingRight: 5,
                  cursor: "pointer",
                }}
              />
              {t("common-button-calendar")}
            </button>
            <span style={{ paddingLeft: 5 }} />
            <button
              onClick={goDetails}
              style={{
                color: "blue",
                textDecoration: "underline",
                backgroundColor: "transparent",
                border: 0,
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="1x"
                style={{
                  fontFamily: "inherit",
                  paddingRight: 5,
                  cursor: "pointer",
                }}
              />
              {t("common-button-details")}
            </button>
          </div>
        </TextContainer>
      )}
      <Modal
        isOpen={getRepsModal}
        close={CloseMenu}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginRight: 10,
              marginTop: 5,
              cursor: "pointer",
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faClose}
              size="1x"
              onClick={() => setGetRepsModal(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <form onSubmit={updateMyReps}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingleft: 0,
                paddingTop: 0,
                paddingBottom: 10,
              }}
            >
              <h2 style={{ alignText: "center" }}>
                {t("habits-userhabits-label-nicejob")}
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                flexDirection: "column",
                paddingleft: 0,
                paddingTop: 0,
                paddingBottom: 10,
              }}
            >
              <span style={{ marginTop: 5 }}>
                {" "}
                {t("habits-userhabits-label-howmany")} {data.RepsLabel}?{" "}
              </span>
              <input
                type="number"
                value={myReps}
                onChange={(e) => setMyReps(e.target.value)}
                style={{ padding: 10, fontSize: 16, width: "95%" }}
                placeholder="0"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                flexDirection: "column",
                paddingleft: 10,
                paddingTop: 20,
                paddingBottom: 10,
              }}
            >
              <span style={{ marginTop: 5 }}> {t("common-label-notes")}? </span>
              <input
                type="text"
                //value={newHabit.RepsLabel}
                onChange={(e) => setMyRepsNotes(e.target.value)}
                style={{ padding: 10, fontSize: 16, width: "95%" }}
                placeholder="Set a new PR today"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 10,
                marginTop: 5,
                cursor: "pointer",
              }}
            >
              <button className="actions_button" type="submit">
                {t("common-button-save")}
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {isCalendarOpen ? (
        <Modal
          isOpen={isCalendarOpen}
          onRequestClose={close}
          style={{ overlay: { zIndex: 50 } }}
        >
          <div style={{ paddingBottom: 200 }}>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",

                  marginRight: 10,
                  marginTop: -30,
                  paddingBottom: 20,
                  cursor: "pointer",
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faClose}
                  size="2x"
                  onClick={close}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <Toaster containerStyle={{ marginTop: 125 }} />

              <div
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontFamily: "inherit",
                  paddingBottom: 30,
                }}
              >
                {t("habits-userhabits-label-calendarinstructions")}{" "}
                <button
                  onClick={() => generateEmail()}
                  style={{ border: 0, textDecoration: "underline" }}
                >
                  {t("habits-userhabits-button-emaillog")}
                </button>
              </div>
              {/*}   <RoutineStreakCalculator
                routineDays={data.days}
                statusLog={statusLogs}
              />*/}

              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={filterEventsToOnePerDay(statusLogs.map(mapToRBCFormat))}
                style={{ height: "500px" }}
                dayLayoutAlgorithm="no-overlap"
                eventPropGetter={eventStyleGetter}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleClickItem}
                //need to be changed
                startAccessor="start"
                endAccessor="end"
                selectable
                // onSelectSlot={()=>console.log("Clicked")}
                views={[
                  "month",
                  //  'week',
                  //  'agenda',
                ]}
              />
              <p
                style={{
                  textAlign: "center",
                  textDecoration: "underline",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={close}
              >
                Close
              </p>
            </Modal.Body>
          </div>
          <Modal
            isOpen={getRepsModalCalendar}
            close={CloseRepModalCalendar}
            style={{ overlay: { zIndex: 50 } }}
          >
            <div style={{ padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "right",
                  marginRight: 10,
                  marginTop: 5,
                  cursor: "pointer",
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faClose}
                  size="1x"
                  onClick={CloseRepModalCalendar}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <form onSubmit={updateMyRepsCalendar}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    flexDirection: "column",
                    paddingleft: 0,
                    paddingTop: 0,
                    paddingBottom: 10,
                  }}
                >
                  <h2>{t("habits-userhabits-label-nicejob")}</h2>
                  <span style={{ marginTop: 5 }}>
                    {" "}
                    {t("habits-userhabits-label-howmany")} {data.RepsLabel}?{" "}
                  </span>
                  <input
                    type="number"
                    value={myRepsCalendar}
                    onChange={(e) => setMyRepsCalendar(e.target.value)}
                    style={{ padding: 10, fontSize: 16, width: "95%" }}
                    placeholder="0"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    flexDirection: "column",
                    paddingleft: 10,
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  <span style={{ marginTop: 5 }}>
                    {" "}
                    {t("common-label-notes")}?{" "}
                  </span>
                  <input
                    type="text"
                    //value={newHabit.RepsLabel}
                    onChange={(e) => setMyRepsNotesCalendar(e.target.value)}
                    style={{ padding: 10, fontSize: 16, width: "95%" }}
                    placeholder="Set a new PR today"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 10,
                    marginTop: 5,
                    cursor: "pointer",
                  }}
                >
                  <Button
                    smallerFontSize={true}
                    width={"100%"}
                    background={"#52B6FF"}
                    type="submit"
                  >
                    {t("common-button-save")}
                  </Button>
                </div>
              </form>
            </div>
          </Modal>
        </Modal>
      ) : null}

      <HabitForm
        isOpen={isEditOpen}
        newHabit={newHabit}
        addTracking={addTracking}
        addPlanner={addPlanner}
        setNewHabit={setNewHabit}
        close={() => setIsEditOpen(false)}
        user={user}
        isEdit={true}
      />

      {isCalendarOpen ? (
        <Modal
          isOpen={isCalCellOpen}
          close={CloseMenu}
          style={{ overlay: { zIndex: 50 } }}
        >
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 10,
                marginTop: -20,
                paddingBottom: 15,
                cursor: "pointer",
              }}
            >
              {" "}
              <FontAwesomeIcon
                icon={faClose}
                size="1x"
                onClick={CloseMenu}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h1
              style={{
                paddingLeft: 15,
                fontSize: 22,
                marginTop: -15,
                textAlign: "center",
              }}
            >
              {logdateformatted}
            </h1>
            <hr />
            <p style={{ paddingLeft: 15, paddingBottom: 0, textAlign: "left" }}>
              {lognotes}
            </p>
            {data.isTrackingReps ? (
              <p
                style={{
                  paddingLeft: 15,
                  paddingBottom: 20,
                  textAlign: "left",
                }}
              >
                {data.RepsLabel} {data.RepsLabel != "" ? ":" : null} {logreps}
              </p>
            ) : null}
            <div className={classes.modalActions}>
              <button
                type="button"
                onClick={LogModalRemoveStatusLog}
                style={{
                  color: "blue",
                  backgroundColor: "transparent",
                  fontFamily: "inherit",
                  fontSize: "inherit",
                  borderColor: "transparent",
                  cursor: "pointer",
                }}
                //   className={classes.secondaryButton}
              >
                {t("common-button-remove")}
              </button>

              {/*}   <button
              type="button"
              onClick={CloseMenu}
              style={{cursor:"pointer"}}
             // className={classes.secondaryButton}
            >
              Close
 </button>*/}
            </div>
          </div>
        </Modal>
      ) : null}
    </Container>
  );
}
