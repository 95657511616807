import { useUserContext } from "./UserContext";
import { useEffect, useState, useRef } from "react";

import { Modal } from "Common";
import { Link, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
//import toast, { Toaster } from "react-hot-toast";
import { User } from "./models";
import { BuyNowModal } from "./BuyNowModal";
//import { SquareAd } from "GoogleAdSense/SquareAd";
import { useTranslation } from "react-i18next";
//import { BannerAd } from "GoogleAdSense/BannerAd";
//import { IbottaAd } from "GoogleAdSense/IbottaAd";

//import emailjs from '@emailjs/browser';

function loadAdScript() {
  const script = document.createElement("script");
  script.async = true;
  script.src =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9685886004588833";
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);
  //console.log("did I fire");
}

function removeAdScript() {
  //  console.log("did I fire");
  // Identify the script by its src attribute, or you could use an id if you add one when appending the script
  const scriptSrc =
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9685886004588833";
  let scriptTag = document.querySelector(`script[src="${scriptSrc}"]`);
  // console.log("what is scripttag", scriptTag);

  if (scriptTag) {
    document.head.removeChild(scriptTag);
  }
}

interface SubscriptionCheckerProps {
  user: User;
  //  isAdsOff: boolean;
  setIsAdsOff: React.Dispatch<React.SetStateAction<boolean>>;
  setIsTrialOff: () => void;
  setIsTrialOn: () => void;
  setIsExpiredOn: () => void;
  setIsExpiredOff: () => void;
  isOnline: boolean;
}

export const SubscriptionChecker = (props: SubscriptionCheckerProps) => {
  const {
    user,
    // isAdsOff,
    setIsAdsOff,
    setIsTrialOff,
    setIsTrialOn,
    setIsExpiredOn,
    setIsExpiredOff,
    isOnline,
  } = props;
  const [isExpired, setIsExpired] = useState(false);
  const [isBannerAd, setIsBannerAd] = useState(false);
  const [isBuyNow, setIsBuyNow] = useState(false);
  //const userProfile = useUserSubscription();

  const { updateDBCloseUpdateNotice } = useUserContext();
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(false);

  const [isTrial, setIsTrial] = useState(false);
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);

  const [isPayPalSubscriber, setIsPayPalSubscriber] = useState(
    localStorage.getItem("isPayPalSubscriber") != undefined
      ? JSON.parse(localStorage.getItem("isPayPalSubscriber"))
      : false
  );

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const handleBuyNow = () => {
    history.push("/buynow");
    // setIsBannerAd(false);
  };

  const isProCheck = useRef([]);

  var isAndroid = isMobile && !isIOS ? true : false;
  var isIOSDevice = isMobile && isIOS ? true : false;

  const [isValidPromoCode, setIsValidPromoCode] = useState(false);

  const checkPromoCode = async () => {
    //@ts-ignore
    const promoCode = user.promocode;
    const userId = user.id;
    // console.log("what is user ID", user.id);

    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/checkPromoCode",
        //  "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/checkPromoCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ promoCode, userID: userId }),
        }
      );

      if (!response.ok) {
        //   console.log("did I fire");
        throw new Error("Network response was not ok");
      }

      const isValid = await response.json();
      //setPromoCodeStatus(isValid ? "Valid" : "Invalid");
      // setIsValidPromoCode(isValid);
      // console.log("what is valid:", isValid ? "Valid" : "Invalid");
      if (isValid) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      //  console.error("There was a problem with the fetch operation:", error);
      //  console.log("Error checking promo code", error);
      return false;
    }
  };

  //Check Dope Notes API to check Revenue Cat for entitlements
  const fetchData = async () => {
    try {
      return await fetch(
        `https://us-central1-dopenotes-20ee4.cloudfunctions.net/checksubscriptionstatus/${user.id}`
      )
        //  return fetch(`https://us-central1-dopenotes-20ee4.cloudfunctions.net/checksubscriptionstatus/xxxx`)
        // We get the API response and receive data in JSON format
        .then((response) => response.json())
        //    .then((data) => console.log(data.subscriber.entitlements))
        // .then((data) => setRCEntitlements(data.subscriber.entitlements.pro.expires_date))
        .then((data) => {
          //  console.log("entitlement", data.subscriber.entitlements.pro)
          var isPro = data.subscriber.entitlements.pro;

          isProCheck.current = isPro;
          checkSubscription(isProCheck.current);
          // console.log("what is pro ", isPro);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log(error);
    }
  };

  const checkPayPalSubscriptions = async () => {
    //@ts-ignore

    try {
      const response = await fetch(
        `https://us-central1-dopenotes-20ee4.cloudfunctions.net/paypal/checksubscriptionstatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // use the "body" param to optionally pass additional order information
          // like product ids and quantities
          body: JSON.stringify({
            //@ts-ignore
            id: user.payPal.subscriptionID,
          }),
        }
      );

      const orderData = await response.json();
      //console.log("what is orderData", orderData.status);
      if (orderData.status == "ACTIVE") {
        //localStorage.setItem("isPayPalSubscriber", "true");
        return true;
      } else {
        //  localStorage.setItem("isPayPalSubscriber", "false");
        return false;
      }
    } catch (error) {}
  };

  const checkForUpdates = async () => {
    //@ts-ignore
    //  console.log("what is user", user.payPal.lifetime);
    //@ts-ignore
    if (user.payPal != undefined && user.payPal.lifetime) {
      // console.log("I'm lifetime paypal");
      setIsTrial(false);
      setIsTrialOff();
      setIsExpired(false);
      removeAdScript();
      setIsExpiredOff();
      return;
    }
    //@ts-ignore
    if (user.payPal != undefined && !user.payPal.lifetime && isOnline) {
      if (checkPayPalSubscriptions()) {
        //   console.log("I'm subscription paypal online check");
        localStorage.setItem("isPayPalSubscriber", "true");
        setIsTrial(false);
        setIsTrialOff();
        setIsExpired(false);
        removeAdScript();
        setIsExpiredOff();
        return;
      }
    }

    /*   if (isPayPalSubscriber) {
      console.log("I'm paypal sub offline");
      setIsTrial(false);
      setIsTrialOff();
      setIsExpired(false);
      // removeAdScript();
      setIsExpiredOff();
      return;
    }*/

    //@ts-ignore

    if (user.promocode != undefined) {
      // await checkPromoCode();
      //  console.log("I'm promocode", checkPromoCode());
      if (await checkPromoCode()) {
        setIsTrial(false);
        setIsTrialOff();
        setIsExpired(false);
        removeAdScript();
        setIsExpiredOff();
        return;
      } else {
        //Check Revenue Cat
        localStorage.setItem("isPayPalSubscriber", "false");
        fetchData();
      }
    } else {
      fetchData();
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "50%",
      //marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      // paddingLeft: 65,
      zindx: 100,
      height: "500px",
      width: "300px",
    },
  };

  const close = () => {
    setIsConnecting(false);
  };

  // @ts-ignore

  // @ts-ignore

  const SubscriptiontDate = user.subscriptionEndDate;

  //@ts-ignore

  //@ts-ignore
  //console.log("what is substatus", userProfile.userProfile.subscriptionStatus)

  var SubscriptionNotice;
  // @ts-ignore
  var convertedDate;

  const convertDate = async (date) => {
    // @ts-ignore
    const update = new Date(date.seconds * 1000);
    //console.log("what is udpate", update);

    //  const update = date.toDate()
    SubscriptionNotice = moment(update).format("MMMM DD, YYYY");
    convertedDate = moment(update).format("YYYYMMDD");
    return convertedDate;
  };

  const today = new Date();
  const subscription =
    // @ts-ignore
    SubscriptiontDate !== undefined || ""
      ? convertDate(SubscriptiontDate)
      : new Date();

  //  const subscription  = new Date()

  const checkSubscription = async (isPro) => {
    /*  console.log(
      "what is pro expiration date",
      isPro.expires_date,
      Number(moment(new Date(isPro.expires_date)).format("YYYYMMDD"))
    );*/

    if ((window as any).isAloha) {
      if (isPro != undefined) {
        //  console.log("did I fire isPro");
        setIsExpired(false);
        removeAdScript();
        setIsExpiredOff();
        setIsAlohaPlanner(true);
        if (
          Number(moment(new Date()).format("YYYYMMDD")) <=
          Number(moment(new Date(isPro.expires_date)).format("YYYYMMDD"))
        ) {
          setIsAdsOff(true);
          removeAdScript();

          try {
            (window as any).ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "bannerAdStatus",
                value: "off",
              })
            );
          } catch (error) {}
        }
      } else {
        //  console.log("did I fire everything else");
        setIsTrial(false);
        setIsTrialOff();
        setIsExpired(false);
        removeAdScript();
        setIsExpiredOff();
        setIsAlohaPlanner(true);
        setIsAdsOff(false);

        try {
          (window as any).ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "bannerAdStatus",
              value: "on",
            })
          );
        } catch (error) {}
      }
    } else {
      if (isPro == undefined) {
        if (
          Number(moment(new Date()).format("YYYYMMDD")) > Number(convertedDate)
        ) {
          //Temp Fix
          setIsExpired(true);
          // loadAdScript();

          setIsExpiredOn();
          setIsTrial(false);
          setIsTrialOff();
          //  console.log("trial expired");
        } else {
          setIsTrial(true);
          //loadAdScript();

          setIsTrialOn();
          setIsExpired(false);
          removeAdScript();
          setIsAlohaPlanner(false);
          setIsExpiredOff();
          //   console.log("trial is good");
        }
      }

      if (isPro != undefined) {
        // console.log("what is isPro", isPro);
        if (
          Number(moment(new Date()).format("YYYYMMDD")) >
          Number(moment(new Date(isPro.expires_date)).format("YYYYMMDD"))
        ) {
          setIsTrial(false);
          setIsTrialOff();
          setIsExpired(true);
          //  loadAdScript();

          setIsExpiredOn();
          // console.log("subscription expired");
        } else {
          setIsTrial(false);
          setIsTrialOff();
          setIsExpired(false);
          removeAdScript();
          setIsExpiredOff();
          //console.log("subscription is good");}
        }
      }
      if (isPro != undefined) {
        // console.log("what is it", isPro.product_identifier);
        if (isPro.product_identifier == "com.dopenotes.lifetime") {
          setIsTrial(false);
          setIsTrialOff();
          setIsExpired(false);
          removeAdScript();
          setIsExpiredOff();
          // console.log("subscription expired");
        }
      }
    }
  };

  // console.log("subDate", subscription)
  //  console.log("today", today)

  const CloseUpdateNotice = () => {
    setUpdateMessage(false);
    //for now, need to update user context.  I'll move it to it's own collect later or setup an APP.
    updateDBCloseUpdateNotice();
  };

  const CheckPlatform = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if ((window as any).isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const backButtonAction = () => {
    //  history.goBack();
    setIsBuyNow(false);
  };

  useEffect(() => {
    //@ts-ignore
    if (user.email == "testing0208@dopenotes.io") {
      // removeAdScript();
    }

    //@ts-ignore
    if (user.email == "testing0209@dopenotes.io") {
      //  loadAdScript();
    }

    checkForUpdates();
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkForUpdates();

        // window.location.reload();
      }
      //@ts-ignore
      if (user.email == "testing0209@dopenotes.io") {
        // loadAdScript();
      }
    };

    // Add event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    CheckPlatform();
    // console.log("did I fire", isExpired, isTrial);

    var myTimeout;
    myTimeout = setTimeout(() => {
      setIsBuyNow(true);
      // console.log("did I fire", isExpired, isTrial);
    }, 60000);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(myTimeout);
    };
  }, []);

  return (
    <div style={{ marginTop: 0 }}>
      {/*isExpired || isTrial ? <BannerAd /> : null*/}

      {isExpired ? (
        <BuyNowModal
          isBuyNow={isBuyNow}
          close={backButtonAction}
          isExpired={isExpired}
          isOnline={isOnline}
          user={user}
        />
      ) : null}

      <div style={{ paddingBottom: 40 }} />

      {/*   {isTrial ? (
        <div
          style={{
            textAlign: "center",
            color: "snow",
            backgroundColor: "black",
            fontSize: 14,
            padding: 5,

            marginTop: 0,
            marginLeft: 0,
          }}
        >
          {location.pathname == "/settings" ? (
            <span>
              Your trial will end on{" "}
              <span style={{ color: "white" }}>{SubscriptionNotice}. </span>
            </span>
          ) : (
            "Go Pro!"
          )}
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="1x"
            color="white"
            onClick={() => setIsBuyNow(true)}
            style={{
              marginTop: 0,
              paddingRight: 5,
              paddingLeft: 5,
              cursor: "pointer",
            }}
          />
          <span
            style={{
              color: "lightblue",
              paddingRight: 5,
              cursor: "pointer",
              fontSize: 16,
            }}
            onClick={() => setIsBuyNow(true)}
          >
            Buy Now
          </span>
        </div>
      ) : null} */}
      {/*  <BuyNowModal
        isBuyNow={isBuyNow}
        isOnline={isOnline}
        isExpired={isExpired}
        close={() => setIsBuyNow(false)}
        user={user}
    />*/}
    </div>
  );
};
