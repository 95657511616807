// src/components/ChoreChart/ChoreChart.jsx

import React, { useState, useEffect } from "react";
import "./ChoreChart.css";
import { Chore } from "./Chore";

export const ChoreChart = () => {
  const [chores, setChores] = useState([]);
  const [members, setMembers] = useState(["Mom", "Dad", "Alice", "Bob"]); // Example family members
  const [choreName, setChoreName] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [frequency, setFrequency] = useState("Weekly");

  // Load chores from localStorage on component mount
  useEffect(() => {
    const storedChores = JSON.parse(localStorage.getItem("familyChores"));
    if (storedChores) {
      setChores(storedChores);
    }
  }, []);

  // Save chores to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("familyChores", JSON.stringify(chores));
  }, [chores]);

  const handleAddChore = (e) => {
    e.preventDefault();
    if (choreName.trim() === "" || assignedTo === "") {
      alert("Please enter chore name and assign to a family member.");
      return;
    }
    const newChore = {
      id: Date.now(),
      name: choreName.trim(),
      assignedTo,
      frequency,
      completed: false,
    };
    setChores([...chores, newChore]);
    setChoreName("");
    setAssignedTo("");
    setFrequency("Weekly");
  };

  const toggleCompletion = (id) => {
    const updatedChores = chores.map((chore) => {
      if (chore.id === id) {
        return { ...chore, completed: !chore.completed };
      }
      return chore;
    });
    setChores(updatedChores);
  };

  const handleDeleteChore = (id) => {
    const updatedChores = chores.filter((chore) => chore.id !== id);
    setChores(updatedChores);
  };

  return (
    <div className="chore-chart">
      <h2>🧹 Family Chore Chart</h2>
      <form onSubmit={handleAddChore} className="chore-form">
        <input
          type="text"
          placeholder="Chore Name"
          value={choreName}
          onChange={(e) => setChoreName(e.target.value)}
          className="input-field"
        />
        <select
          value={assignedTo}
          onChange={(e) => setAssignedTo(e.target.value)}
          className="select-field"
        >
          <option value="">Assign To</option>
          {members.map((member) => (
            <option key={member} value={member}>
              {member}
            </option>
          ))}
        </select>
        <select
          value={frequency}
          onChange={(e) => setFrequency(e.target.value)}
          className="select-field"
        >
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
        </select>
        <button type="submit" className="add-button">
          Add Chore
        </button>
      </form>
      <div className="chores-list">
        {chores.length === 0 ? (
          <p>No chores assigned yet. Add some to get started!</p>
        ) : (
          chores.map((chore) => (
            <Chore
              key={chore.id}
              chore={chore}
              onToggle={toggleCompletion}
              onDelete={handleDeleteChore}
            />
          ))
        )}
      </div>
    </div>
  );
};
