import React, {
  createRef,
  FormEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
} from "react";
//import CreatableSelect from "react-select/async-creatable";

//import { useCategories } from "../../CategoriesContext"
import { useCategorySubscriptionToDo } from "../../../ListToDo";
import { ItemModel } from "../../models";

//import { Item } from "../Common/Item";
//import { CategoryModalToDo } from "../../List/CategoryModal/CategoryModalToDo";
import { CategoryEditModalToDo } from "../CategoryModal/CategoryEditModalToDo";
//import { CategoryAddItemModalToDo } from "../CategoryModal/CategoryAddItemModalToDo";
import { useItemInputToDo } from "./useItemInputToDo";
import { LoadOptionsCallback, SelectRef } from "./types";
import toast, { Toaster } from "react-hot-toast";
import classes from "./AddItem.module.css";
import { useTranslation } from "react-i18next";

export interface AddItemsProps {
  addedItemsToDo: ItemModel[];
  allItemsToDo: ItemModel[];
  categoryToDo: string;
  setCategoryToDo: React.Dispatch<React.SetStateAction<string>>;
  isDarkMode: boolean;
  disableCategorySelect?: boolean;
}

export const AddItemToDoWidget = forwardRef<SelectRef, AddItemsProps>(
  (props, ref) => {
    const {
      addedItemsToDo,
      categoryToDo,
      allItemsToDo,
      setCategoryToDo,
      isDarkMode,
      disableCategorySelect,
    } = props;
    const {
      alreadyAdded,
      isValid,
      previouslyAdded,
      value,
      addItemToDo,
      setValue,
    } = useItemInputToDo(
      addedItemsToDo,
      categoryToDo,
      setCategoryToDo,
      allItemsToDo
    );
    const inputRef = createRef<SelectRef>();
    useImperativeHandle(ref, () => inputRef.current as SelectRef);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
    const [isCatAddItemModalOpen, setIsCatAddItemModalOpen] = useState(false);
    const [categoryToDoNew, setCategoryToDoNew] = useState("");
    const [valueNew, setValueNew] = useState("");
    //const categories = useCategories()
    const { categoriesToDo } = useCategorySubscriptionToDo();
    const [addIT, setAddIT] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const { t } = useTranslation();

    var quickAddLock = 0;

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      //   console.log("what is category on submit", category)
      //  console.log("what is the item name", value)
      event.preventDefault();
      addItemToDo(valueNew, categoryToDoNew);
      inputRef.current?.focus();
      setValueNew("");
      toast(t("generic-list-added-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    };

    const handleEnterPressed = (event) => {
      if (event.key === "Enter") {
        // Enter key was pressed
        setIsModalOpen(true);
        // You can perform any desired action here
      }
    };

    return (
      <>
        <form
          onSubmit={handleSubmit}
          className={classes.form}
          style={{ backgroundColor: "lavender" }}
          // style={{ backgroundColor: "#3e98c7" }}
        >
          <label style={{ paddingTop: 10, paddingBottom: 10 }}>
            {t("generic-list-additem-addnew")}
          </label>
          <input
            placeholder=" "
            // onKeyDown={handleEnterPressed}
            // onBlur={handleEnterPressed}
            type="text"
            value={valueNew}
            onChange={(e) => setValueNew(e.target.value)}
            style={{
              fontFamily: "inherit",
              fontSize: "1.4em",
              padding: 10,
              width: "100%",
            }}
            //  placeholder=""
            required
            className={classes.myform}
          />
          {valueNew != "" && !disableCategorySelect ? (
            <>
              <label style={{ paddingTop: 10 }}>
                {!disableCategorySelect
                  ? t("tasks-list-selectlist")
                  : t("tasks-list-selectlist")}{" "}
                <span style={{ paddingRight: 10 }} />{" "}
                <span
                  onClick={() => setIsCatEditModalOpen(true)}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "1em",
                    userSelect: "none",
                  }}
                >
                  {t("tasks-list-add")}
                </span>
              </label>
              <select
                value={categoryToDoNew}
                disabled={disableCategorySelect ? true : false}
                onChange={(event) => {
                  const selectedCategoryId = event.target.value;
                  const selectedCategory = categoriesToDo.find(
                    (c) => c.id === selectedCategoryId
                  );
                  setCategoryToDoNew(selectedCategoryId);
                  setCategoryName(
                    selectedCategory ? selectedCategory.name : ""
                  );
                  //  console.log("what is ID", selectedCategoryId);
                }}
                style={{
                  fontSize: "1.2em",
                  marginTop: 10,
                  padding: 10,
                  width: 175,
                  border: 0,
                  backgroundColor: "white",
                }}
              >
                <option value={"0"}>{t("generic-list-general")}</option>
                {categoriesToDo
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((c) => (
                    <option key={c.id} value={c.id} title={c.name}>
                      {c.name}
                    </option>
                  ))}
              </select>
            </>
          ) : null}
          <div className={classes.actions}>
            <button
              type="submit"
              //  disabled={!isValid}
              className={classes.blinkingButton}
              style={{
                width: "100%",
                fontFamily: "inherit",
                fontSize: 18,
                color: "white",
                backgroundColor: "#1e79c3",
                fontWeight: 500,
              }}
            >
              {previouslyAdded || value === ""
                ? t("tasks-list-additem")
                : t("tasks-list-additem")}
            </button>
          </div>
          <div
            style={{
              textAlign: "center",
              fontSize: 16,
              fontWeight: "normal",
              paddingTop: 15,
            }}
          ></div>
          {/*    {alreadyAdded ? (
          <small className={classes.error}>
            {alreadyAdded.name} has already been added
          </small>
   ) : null} */}
        </form>
        {isCatEditModalOpen ? (
          <CategoryEditModalToDo
            isOpen={isCatEditModalOpen}
            close={() => setIsCatEditModalOpen(false)}
            isDarkMode={isDarkMode}
          />
        ) : null}
        {/*  <div style={{display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', marginTop: 10 }} onClick={()=>setIsCatEditModalOpen(true)}>Manage Categories</div>*/}
      </>
    );
  }
);
