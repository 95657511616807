import styled from "styled-components";

const Container = styled.div`
  padding-top: 0px;
`;

const TitleContainer = styled.div`
  padding-left: 17px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  border-color: lightgrey;
  text-shadow: "1px 3px 3px lightgrey";
`;

const Percentage = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #bababa;

  h1 {
    color: #8fc549;
  }
`;

const ListOfHabits = styled.div`
  padding: 0px 10px 0px 10px;

  font-size: 1.2em;
  line-height: 22px;
  color: #666;
`;

export { Container, TitleContainer, Percentage, ListOfHabits };
