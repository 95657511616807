import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Default Marker Icons
const traveledIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/854/854866.png",
  iconSize: [25, 25],
});

const bucketListIcon = new L.Icon({
  iconUrl: "https://cdn-icons-png.flaticon.com/512/854/854878.png",
  iconSize: [25, 25],
});

export const MapComponent = () => {
  const [markers, setMarkers] = useState([]);
  const [markerType, setMarkerType] = useState("traveled");
  const [location, setLocation] = useState({
    city: "",
    state: "",
    country: "",
  });
  const [suggestions, setSuggestions] = useState([]);

  // Fetch suggestions as the user types
  const fetchSuggestions = async (query) => {
    if (query.length < 3) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          query
        )}&format=json&addressdetails=1`
      );

      if (!response.ok) throw new Error("Failed to fetch suggestions.");

      const data = await response.json();
      const formattedSuggestions = data.map((item) => ({
        displayName: item.display_name,
        city: item.address?.city || "",
        state: item.address?.state || "",
        country: item.address?.country || "",
        lat: item.lat,
        lon: item.lon,
      }));

      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleAddMarker = async () => {
    if (!location.city || !location.state || !location.country) {
      alert("Please fill in all location details.");
      return;
    }

    try {
      const query = `${location.city}, ${location.state}, ${location.country}`;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          query
        )}&format=json`
      );

      if (!response.ok) throw new Error("Failed to fetch location data.");

      const data = await response.json();
      if (data.length === 0) {
        alert("Location not found. Please try again.");
        return;
      }

      const { lat, lon } = data[0]; // Use the first result

      setMarkers((prevMarkers) => [
        ...prevMarkers,
        {
          lat: parseFloat(lat),
          lng: parseFloat(lon),
          type: markerType,
          ...location,
        },
      ]);

      setLocation({ city: "", state: "", country: "" });
    } catch (error) {
      console.error("Error fetching location data:", error);
      alert("Could not fetch location. Please try again later.");
    }
  };

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <div style={{ marginBottom: "10px", textAlign: "center" }}>
        <button
          onClick={() => setMarkerType("traveled")}
          style={{
            margin: "0 5px",
            padding: "10px 20px",
            backgroundColor: markerType === "traveled" ? "#4caf50" : "#f1f1f1",
            color: markerType === "traveled" ? "white" : "black",
            border: "1px solid #ccc",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Mark as Traveled To
        </button>
        <button
          onClick={() => setMarkerType("bucket")}
          style={{
            margin: "0 5px",
            padding: "10px 20px",
            backgroundColor: markerType === "bucket" ? "#2196f3" : "#f1f1f1",
            color: markerType === "bucket" ? "white" : "black",
            border: "1px solid #ccc",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Add to Bucket List
        </button>
      </div>

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <input
          type="text"
          placeholder="Search location"
          value={location.city}
          onChange={(e) => {
            setLocation({ ...location, city: e.target.value });
            fetchSuggestions(e.target.value);
          }}
          style={{
            margin: "0 5px",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: "10px 0",
            maxHeight: "150px",
            overflowY: "auto",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => {
                setLocation({
                  city: suggestion.city,
                  state: suggestion.state,
                  country: suggestion.country,
                });
                setSuggestions([]);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor: "#f9f9f9",
                borderBottom: "1px solid #ddd",
              }}
            >
              {suggestion.displayName}
            </li>
          ))}
        </ul>
        <button
          onClick={handleAddMarker}
          style={{
            marginLeft: "10px",
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Add Marker
        </button>
      </div>

      <MapContainer
        center={[51.505, -0.09]}
        zoom={3}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={[marker.lat, marker.lng]}
            icon={marker.type === "traveled" ? traveledIcon : bucketListIcon}
          >
            <Popup>
              <div>
                <p>
                  <strong>
                    {marker.type === "traveled" ? "Traveled To" : "Bucket List"}
                  </strong>
                </p>
                <p>City: {marker.city}</p>
                <p>State: {marker.state}</p>
                <p>Country: {marker.country}</p>
                <p>
                  Coordinates: {marker.lat.toFixed(2)}, {marker.lng.toFixed(2)}
                </p>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};
