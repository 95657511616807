import { Redirect, Route, Switch } from "react-router-dom";
import { AnonymousSplash } from "Splash";
import { EmailLinkAuthSplash } from "Splash";
import { NativeSplash } from "Splash/AnonymousSplash/NativeSplash";
import { Help } from "./help";
import { DeviceRedirect } from "Splash/AnonymousSplash/DeviceRedirect";
import { ResetUserLogout } from "Authentication/ResetUserLogout";

export const AnonymousContent = () => (
  <Switch>
    <Route path="/emailauth" exact>
      <EmailLinkAuthSplash />
    </Route>
    <Route path="/privacy" exact>
      <div>
        {" "}
        <object
          data="https://www.iubenda.com/privacy-policy/54575108"
          width="97%"
          height="650px"
          type="text/html"
        ></object>
      </div>
    </Route>
    <Route path="/logout" exact>
      <div>
        <ResetUserLogout />
      </div>
    </Route>

    <Route path="/terms" exact>
      <div>
        {" "}
        <object
          data="https://www.iubenda.com/terms-and-conditions/54575108"
          width="97%"
          height="650px"
          type="text/html"
        ></object>
      </div>
    </Route>
    <Route path="/cookiepolicy" exact>
      <div>
        {" "}
        <object
          data="https://www.iubenda.com/privacy-policy/54575108/cookie-policy"
          width="97%"
          height="650px"
          type="text/html"
        ></object>
      </div>
    </Route>
    <Route path="/contact" exact>
      <Help />
    </Route>
    <Route path="/nativelogin" exact>
      <NativeSplash />
    </Route>
    <Route path="/" exact>
      <AnonymousSplash />
    </Route>
    <Route path="/ad" exact>
      <DeviceRedirect />
    </Route>
    <Redirect to="/" />
  </Switch>
);
