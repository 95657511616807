import React, {
  useState,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
} from "react";
import {
  faAdd,
  faClose,
  faEnvelope,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusCircle,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Button, Modal } from "Common";
import { ItemModel } from "Lists";
import { useItemSubscriptionToDo } from "ListToDo/useItemSubscriptionToDo";
import { ListItemToDo } from "../../ListItem/ListItemToDo";
import { CategoryEditModalToDo } from "../../CategoryModal/CategoryEditModalToDo";
import classes from "./Category.module.css";
import { SelectRef } from "../../../../ListToDo/List/AddItem";
//import { AddItemToDoCat } from "ListToDo/List/AddItem/AddItemToDoCat";
import { AddItemToDo } from "../../../../ListToDo/List/AddItem";
import { useItemsToDo } from "ListToDo/useItemsToDo";
import { useTranslation } from "react-i18next";

export interface CategoryItems {
  items: ItemModel[];
  id?: string | undefined;
  name: string;
  colour: string;
  order: number;
  isDarkMode: boolean;
}

export interface CategoryProps {
  category: CategoryItems;
  showAllCat: boolean;
  showCompleted: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
}

function adjustBrightness(color, amount) {
  if (color[0] === "#") {
    color = color.slice(1);
  }

  const num = parseInt(color, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.max(Math.min(255, r), 0);
  g = Math.max(Math.min(255, g), 0);
  b = Math.max(Math.min(255, b), 0);

  // Return an rgba color with the adjusted color and a fixed alpha value (40%)
  return `rgba(${r}, ${g}, ${b}, 0.4)`; // 0.4 for 40% opacity
}

const SortableItem = SortableElement(
  ({
    value,
    category,
    onClickItem,
    onClickItemCategory,
    colour,
    adjustment,
  }) => (
    <div
      style={{
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        border: 1,
        borderStyle: "dashed",
        borderColor: "rgba(128, 128, 128, 0.6)",
        backgroundColor: colour
          ? adjustBrightness(colour, adjustment * 15)
          : adjustBrightness("#fffff", adjustment * 20), // Slightly adjust brightness
        //   backgroundColor: colour,
      }}
    >
      <ListItemToDo
        key={value.id}
        item={value}
        category={category}
        onClickCategory={() => onClickItemCategory(value)}
        onClickItem={() => onClickItem(value)}
      />
    </div>
  )
);

const SortableList = SortableContainer(
  ({ items, onClickItem, onClickItemCategory, category, colour }) => {
    const min = 1000;
    const max = 100000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    const filteredItems = items.filter((item) => item.name !== undefined);
    filteredItems.sort((a, b) => a.sortIndex - b.sortIndex);

    return (
      <div>
        {filteredItems.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            value={value}
            onClickItemCategory={onClickItemCategory}
            onClickItem={onClickItem}
            category={category}
            colour={colour}
            adjustment={index % 2 === 0 ? 10 : 20} // Slightly lighten or darken the color
          />
        ))}
      </div>
    );
  }
);

export const Category = (props: CategoryProps) => {
  const {
    category,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    showAllCat,
    showCompleted,
    isDarkMode,
  } = props;
  const { id, colour, items, name } = category;
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedCategory, setSelectedCategoryx] = useState(id);
  const [selectedCategoryName, setSelectedCategoryNamex] = useState(name);
  const [selectedCategoryxx, setSelectedCategory] = useState(id);
  const [selectedCategoryNamexx, setSelectedCategoryName] = useState(name);
  const inputRef = useRef<SelectRef>(null);
  const { addedItemsToDo, allItemsToDo } = useItemSubscriptionToDo();
  const [showAllCategory, setShowAllCategory] = useState(true);
  const [shareModal, setShareModal] = useState(false);
  const [launchModal, setLaunchModal] = useState(false);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [isOpenItems, setIsOpenItems] = useState(
    localStorage.getItem(`iscattodo-${name}`) != undefined
      ? JSON.parse(localStorage.getItem(`iscattodo-${name}`))
      : true
  );

  //android/ios check

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if ((window as any).isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const close = () => {
    setIsAddItemOpen(false);
  };
  const { t } = useTranslation();

  const Randomposition = Math.round(Math.random()) * 0.5 - 0.25;
  // const [newSortItems, setNewSortItems] = useState([]);

  const hanldeShowItems = () => {
    // console.log("click");
    if (isOpenItems == true) {
      setIsOpenItems(false);
      localStorage.setItem(`iscattodo-${name}`, "false");
    } else {
      setIsOpenItems(true);
      localStorage.setItem(`iscattodo-${name}`, "true");
    }
  };

  const { removeItem, setSortIndex } = useItemsToDo();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Create a copy of the items array
    //Check to make sure there is more then 1 item
    if (resetDB.current.length > 1 && oldIndex != newIndex) {
      var updatedItems = [...resetDB.current];

      // Update the copy of the list using arrayMoveImmutable
      const movedItem = updatedItems.splice(oldIndex, 1)[0];
      updatedItems.splice(newIndex, 0, movedItem);

      // Update the order property based on the new index
      const updatedItemsWithNewOrder = updatedItems.map((item, index) => ({
        ...item,
        sortIndex: index, // Assign the order based on the index
      }));

      //console.log("Updated items with new order:", updatedItemsWithNewOrder);

      const filteredItems = updatedItemsWithNewOrder.filter(
        (item) => item !== undefined
      );
      filteredItems.sort((a, b) => a.sortIndex - b.sortIndex);

      filteredItems.forEach((item, index) => {
        // console.log("which item", item);
        if (item.id != undefined) {
          setSortIndex(item.id, item.sortIndex);
        }
      });

      // Set the state using the updated copy of the list with the new order
      resetDB.current = filteredItems;
      //  setNewSortItems(filteredItems);
      // console.log("Did I fire?");
    } else {
      // console.log("Did I skip?");
    }
  };

  const compileListSMS = (items, name) => {
    // Ensure the input is valid
    if (!Array.isArray(items) || items.length === 0) {
      console.error("Invalid or empty items list");
      return;
    }

    // Filter the items to include only those that are not completed
    const uncompletedItems = items.filter((item) => !item.completed);

    // Construct the SMS body
    const smsBody = `My list ${name}\n${uncompletedItems
      .map((item) => `- ${item.name}`)
      .join("\n")}`;

    // Update the SMS link
    var smsUrl = `sms:?body=${encodeURIComponent(smsBody)}`;

    if (isAndroid) {
      window.open(smsUrl, "_blank");
    } else {
      window.location.href = smsUrl;
    }
  };

  const compileListEmail = (items, name) => {
    // console.log("What is it", items);
    // console.log("map it", items.map((item) => `- ${item.name}`).join("%0A"));
    // Ensure the input is valid
    if (!Array.isArray(items) || items.length === 0) {
      console.error("Invalid or empty items list");
      return;
    }

    // Filter the items to include only those that are not completed
    const uncompletedItems = items.filter((item) => !item.completed);
    // Update the mailto link
    var url = `mailto:?subject=My%20List%20${name}&body=My%20list%20${name}%0A${uncompletedItems
      .map((item) => `- ${item.name}`)
      .join("%0A")}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const resetDB = useRef(items);
  const sortDBItems = () => {
    // Check and assign a default sortIndex to items with undefined sortIndex
    const min = 1000;
    const max = 100000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    resetDB.current.forEach((item) => {
      //@ts-ignore
      if (item.sortIndex === undefined) {
        //@ts-ignore
        item.sortIndex = randomNumber;

        // console.log("which item", item);
        //@ts-ignore
        //   setSortIndex(item.id, item.sortIndex);
        //  console.log("updateing DB");
        // Assign a high value for undefined sortIndex
      }
    });

    // Sort the items based on sortIndex
    //@ts-ignore
    resetDB.current.sort((a, b) => a.sortIndex - b.sortIndex);
    // setNewSortItems(resetDB.current);
  };

  useEffect(() => {
    CheckPlatform();
    resetDB.current = items;
    sortDBItems();
    return () => {};
  }, [items]);

  return (
    <>
      {items.length >= 1 || showAllCat ? (
        <div
          className={classes.category}
          // style={{  transform: `rotate(${Randomposition}deg)`, backgroundColor: `${colour}40` }}
          style={{ backgroundColor: `${colour}50` }}
        >
          <div style={{ paddingBottom: 10, flexDirection: "row" }}>
            <div className={classes.categoryHeader}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setLaunchModal(true)}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "dimgray",
                    paddingLeft: 5,
                  }}
                >
                  {name.toLocaleUpperCase()}{" "}
                </span>
              </div>
            </div>
          </div>
          <Modal
            isOpen={isAddingNewItem}
            onRequestClose={() => setIsAddingNewItem(false)}
            style={{ overlay: { zIndex: 100 } }}
            //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
          >
            <div style={{ filter: "invert(100%)" }}>
              <AddItemToDo
                addedItemsToDo={addedItemsToDo}
                allItemsToDo={allItemsToDo}
                categoryToDo={id}
                //  categoryNameToDo={selectedCategoryName}
                ref={inputRef}
                setCategoryToDo={setSelectedCategory}
                // setCategoryNameToDo={setSelectedCategory}
                isDarkMode={isDarkMode}
                setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                disableCategorySelect={true}
              />
            </div>
          </Modal>

          {items.length > 0 ? (
            <div
              onClick={hanldeShowItems}
              style={{
                fontSize: 16,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "blue",
                  fontSize: 16,
                  textDecoration: "underline",
                  paddingLeft: 5,
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                {!isOpenItems ? "Show" : "Hide"} {items.length} Item(s)
              </span>

              {/*  <span style={{ fontSize: 14, paddingRight: 5, paddingLeft: 5 }}>
              {" "}
              Total: {items.length}
            </span>*/}
              <span
                onClick={() => setIsAddingNewItem(true)}
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  fontSize: 16,
                }}
              >
                {t("common-button-addnew")}
              </span>
            </div>
          ) : (
            <div
              onClick={() => setIsAddingNewItem(true)}
              style={{
                textAlign: "center",
                paddingLeft: 5,
                paddingRight: 5,
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Add Your First Item
            </div>
          )}

          {isOpenItems ? (
            <div style={{ marginTop: 5 }}>
              <SortableList
                items={resetDB.current}
                onSortEnd={onSortEnd}
                onClickItemCategory={onClickItemCategory}
                onClickItem={onClickItem}
                category={category}
                colour={colour}
                useWindowAsScrollContainer={true}
                //pressDelay={2}
                useDragHandle
              />
              {items.length > 0 ? (
                <div
                  onClick={hanldeShowItems}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,
                    textDecoration: "underline",
                    userSelect: "none",
                    cursor: "pointer",
                    color: "blue",
                    fontSize: 16,
                  }}
                >
                  Hide {items.length} Item(s)
                </div>
              ) : null}
            </div>
          ) : null}

          <Modal
            isOpen={launchModal}
            onRequestClose={() => setLaunchModal(false)}
            style={{
              overlay: { zIndex: 99 },
            }}
          >
            <div style={{}}>
              <h2
                style={{
                  fontWeight: "bold",
                  // color: "white",
                  paddingLeft: 5,
                }}
              >
                {name.toLocaleUpperCase()}{" "}
              </h2>
              {items.length > 0 ? (
                <SortableList
                  items={resetDB.current}
                  onSortEnd={onSortEnd}
                  onClickItemCategory={onClickItemCategory}
                  onClickItem={onClickItem}
                  category={category}
                  colour={colour}
                  useWindowAsScrollContainer={true}
                  //pressDelay={2}
                  useDragHandle
                />
              ) : (
                <div style={{ textAlign: "center", color: "red" }}>
                  <h2>No Items Found</h2>
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  paddingBottom: 150,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => setIsAddingNewItem(true)}
                >
                  <FontAwesomeIcon icon={faAdd} />
                  <span style={{ paddingLeft: 5 }}> Add Item(s)</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => compileListEmail(resetDB.current, name)}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span style={{ paddingLeft: 5 }}> Email List</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => compileListSMS(resetDB.current, name)}
                >
                  <FontAwesomeIcon icon={faSms} />
                  <span style={{ paddingLeft: 5 }}> SMS List</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => setLaunchModal(false)}
                >
                  Back
                </div>
              </div>
            </div>
          </Modal>

          {isCatEditModalOpen ? (
            <CategoryEditModalToDo
              isOpen={isCatEditModalOpen}
              close={() => setIsCatEditModalOpen(false)}
              isDarkMode={isDarkMode}
            />
          ) : null}
        </div>
      ) : null}
      {name === "Priority Items" ? (
        <div>
          <hr />
          <h4
            style={{
              textAlign: "center",
              marginTop: -4,
            }}
          >
            All Tasks
          </h4>
          <hr
            style={{
              marginTop: -20,
            }}
          />
        </div>
      ) : null}
    </>
  );
};
