import { useEffect, useState, useRef } from "react";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserContext } from "Authentication";
//import logo from "../../../Assets/logo192.png";
//import alohaicon from "../../../Assets/alohaicon.png";
import {
  faCog,
  faListCheck,
  faListAlt,
  faBars,
  faHome,
  faClose,
  faQuestionCircle,
  faBell,
  faPaintRoller,
  faRefresh,
  faF,
  faShoppingCart,
  faSpoon,
  faNoteSticky,
  faList,
  faBookOpen,
  faRunning,
  faArrowCircleLeft,
  faPlusCircle,
  faBolt,
  faEnvelope,
  faStar,
  faInfoCircle,
  faPen,
  faTrashCan,
  faPaintBrush,
  faEdit,
  faCalendarDays,
  faCheck,
  faEye,
  faArrowRight,
  faWifi,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FeedbackWidget } from "./FeedbackWidget";
import { DiApple, DiAndroid } from "react-icons/di";
//import audiointro from "../../../Assets/audiointro.mp3";
///import { useSpeechSynthesis } from "react-speech-kit";
import { Modal } from "Common";
import { useTranslation } from "react-i18next";
import { useItems } from "Lists";
import { GuestUserRegister } from "../GuestUserRegister";
import { useRecipes } from "Recipes";
import { classicRecipes } from "Recipes/RecipeData/classicRecipes";
import { classicRecipesFR } from "Recipes/RecipeData/recipe_data_fr";
import { classicRecipesES } from "Recipes/RecipeData/recipe_data_es";
import { classicRecipesDE } from "Recipes/RecipeData/recipe_data_de";
import { classicRecipesSE } from "Recipes/RecipeData/recipe_data_se";
import { classicRecipesAR } from "Recipes/RecipeData/recipe_data_ar";
import { classicRecipesCN } from "Recipes/RecipeData/recipe_data_cn";
import { classicRecipesKR } from "Recipes/RecipeData/recipe_data_kr";
import { classicRecipesJP } from "Recipes/RecipeData/recipe_data_jp";
import { FAQ } from "./FAQ";
//import classes from "../RegisteredSplash.module.css";

export const FirstLaunchHelp = ({ isOpen, close, user }) => {
  const [isContactSupport, setIsContactSupport] = useState(false);
  // const [isContactSupportTop, setIsContactSupportTop] = useState(false);
  const [isViewAll, setIsViewAll] = useState(false);
  const { initialGrocerySetup } = useUserContext();
  const { t } = useTranslation();
  const [isNative, setIsNative] = useState(false);
  const [linkEmailForm, setLinkEmailForm] = useState(
    user.email === "" ? true : false
  );
  const [isFAQ, setIsFAQ] = useState(false);

  const {
    addCommonGroceryItems,
    AddCommonCategories,
    //deleteAllGroceryItems,
    //deleteAllGroceryCategories,
  } = useItems();

  const { loadDefaultRecipes } = useRecipes();

  const handleCloseEmailLink = () => {
    // setLinkEmailForm(false);
    close();
  };

  const LoadRecipes = () => {
    const getlanguage = localStorage.getItem("userLanguage");
    var language = getlanguage != undefined ? getlanguage : "en";

    const whichVersion = () => {
      if (language == "en") {
        return classicRecipes;
      } else if (language == "cn") {
        return classicRecipesCN;
      } else if (language == "de") {
        return classicRecipesDE;
      } else if (language == "fr") {
        return classicRecipesFR;
      } else if (language == "es") {
        return classicRecipesES;
      } else if (language == "jp") {
        return classicRecipesJP;
      } else if (language == "kr") {
        return classicRecipesKR;
      } else if (language == "ar") {
        return classicRecipesAR;
      } else if (language == "se") {
        return classicRecipesSE;
      } else {
        return classicRecipes;
      }
    };

    loadDefaultRecipes(whichVersion());
  };
  //removing audio
  /*  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    const audioElement = audioRef.current;

    if (audioElement.readyState >= 2) {
      // Audio is loaded and can be played or paused
      if (audioElement.paused) {
        audioElement.play();
        setIsPlaying(true);
      } else {
        audioElement.pause();
        setIsPlaying(false);
      }
    } else {
      // Audio is not loaded yet, wait for canPlay event
      audioElement.addEventListener("canplay", () => {
        if (audioElement.paused) {
          audioElement.play();
          setIsPlaying(true);
        } else {
          audioElement.pause();
          setIsPlaying(false);
        }
      });
    }
  }; */

  // console.log("what is user", user);

  const uploadGroceryItems = async () => {
    await addCommonGroceryItems();
    await AddCommonCategories();
    await LoadRecipes();

    initialGrocerySetup();
    // console.log("uploaded");
  };

  const handleToggleFAQ = () => {
    setIsFAQ((old) => !old);
  };

  useEffect(() => {
    if (window.isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }
    // const isFirst = JSON.parse(localStorage.getItem("firstLaunch"));
    // console.log("what is it first launch?", isFirst);
    // console.log("isInitialGrocerySetup", user.isInitialGrocerySetup);

    //console.log("what is the users", user);

    if (localStorage.getItem("firstLaunchSetup") == undefined) {
      try {
        uploadGroceryItems();
        localStorage.setItem("firstLaunchSetup", "false");
      } catch (error) {
        window.location.reload();
      }

      if (window.isNative) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "NoficationSelected",
            name: "Aloha!  Just a gentle reminder to review your habits, task lists, and journal entries for the day.",
            days: [0, 1, 2, 3, 4, 5, 6],
            time: "08:45",
            id: "AlohaDailyCheckIn",
            isAdded: "create",
          })
        );
      }
      /*    window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "NoficationSelected",
          name: "Aloha!  Just a gentle reminder to review your habits, task lists, and journal entries for the day.",
          days: [0, 1, 2, 3, 4, 5, 6],
          time: "20:30",
          id: "100AlohaJournalCheckIn",
          isAdded: "create",
        })
      );*/
    }

    return () => {};
    //@ts-ignore
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 100, opacity: 1 } }}
      //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
    >
      <div
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 140,
          marginTop: -20,
          backgroundColor: "pink",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            paddingBottom: 10,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: 10,
              marginRight: 10,
            }}
            onClick={close}
          >
            <FontAwesomeIcon icon={faClose} size="2x" />
          </button>
        </div>
        <div style={{ textAlign: "center", padding: 10, marginTop: -20 }}>
          {" "}
          <h2 style={{ marginLeft: 25 }}>Aloha! &#127796;</h2>
          {/*}   <div>
            <button
              onClick={toggleAudio}
              //  className={!isPlaying ? classes.blinkingButton : ""}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                width: 200,
                lineHeight: 1.4,
                fontSize: 16,
                border: 1,
                borderRadius: 40,
                backgroundColor: "yellow",
                //  color: "white",
              }}
            >
              {isPlaying ? "Pause Audio" : "Tap Here To Play Audio Welcome"}
            </button>
            <audio ref={audioRef} controls={false}>
              <source src={audiointro} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
            </div>*/}
          We value your privacy. We don’t sell your data, run ads, or share your
          email with third parties. The only tracking we perform is for traffic
          analysis and error reporting—no nonsense, no compromises.
          <br />
          <br />
          Feel free to share the login with family and friends or keep it for
          your personal use to organize your home.
          <br />
          <br />
          {t("introhelp-intro3-text")}
          <br />
          <br />
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: "inherit",
              fontFamily: "inherit",
            }}
          >
            {linkEmailForm ? (
              <div style={{ padding: 10 }}>
                Create a login now for complimentary backups, instant
                synchronization, and the convenience of accessing your account
                across smartphones, tablets, and laptops.
                <br />
                <br />
                <GuestUserRegister close={handleCloseEmailLink} />
              </div>
            ) : null}
          </div>
        </div>
        {isFAQ ? (
          <span>
            <FAQ />
            <br />
          </span>
        ) : null}
        {isViewAll ? (
          <div style={{ padding: 5 }}>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-header-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faHome}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-header-home")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faPrint}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-print")}
              </li>

              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faPaintRoller}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-color")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-settings")}
              </li>

              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faWifi}
                  style={{
                    paddingRight: 10,
                    color: "grey",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-offline")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faBars}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-header-main")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faArrowCircleLeft}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-header-backarrow")}
              </li>
              {/*}   <li style={{ paddingBottom: 10 }}>
              <FontAwesomeIcon
                icon={faF}
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
                size="xl"
              />
              - Unhide Footer menu
              </li>*/}
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-footer-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faArrowCircleLeft}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-footer-backarrow")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faRunning}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-footer-habit")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faBookOpen}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-footer-journal")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faList}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-footer-tasks")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faNoteSticky}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-footer-notes")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faSpoon}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-footer-meal")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-footer-grocery")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-footer-planner")}
              </li>

              {/*}  <li style={{ paddingBottom: 10 }}>
              <FontAwesomeIcon
                icon={faClose}
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
                size="xl"
              />
              - Hide Footer Menu (Swipe left on footer Mobile)
            </li>*/}
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-setting-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                {t("introhelp-setting-text")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-help")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faRefresh}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-header-cache")}
              </li>
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-dashboard-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faList}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-dashboard-lists")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-dashboard-widgets")}
              </li>
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-journal-title")}
            </h3>

            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />
                - {t("introhelp-journal-add")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faListAlt}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-journal-log")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-journal-reminder")}
              </li>
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-tasks-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faBolt}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-tasks-quickadd")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faEnvelope}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-tasks-email")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faListCheck}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-tasks-category")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-tasks-hidechecked")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faEye}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-tasks-hidelists")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-tasks-details")}
              </li>
              <li style={{ paddingBottom: 10, paddingLeft: 20 }}>
                <FontAwesomeIcon
                  icon={faStar}
                  size="xl"
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                />{" "}
                - {t("introhelp-tasks-favorite")}
              </li>

              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />
                - {t("introhelp-tasks-reminder")}
              </li>
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-notes-title")}
            </h3>
            <ul style={{ listStyle: "none", marginLeft: -20 }}>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-notes-email")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faPaintBrush}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-notes-color")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faPen}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-notes-edit")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faBell}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-notes-reminder")}
              </li>
              <li style={{ paddingBottom: 10 }}>
                <FontAwesomeIcon
                  //onClick={OpenMenu}
                  icon={faTrashCan}
                  style={{
                    paddingRight: 10,
                    color: "black",
                  }}
                  size="xl"
                />{" "}
                - {t("introhelp-notes-delete")}
              </li>
            </ul>
            <h3 style={{ textAlign: "center" }}>
              {t("introhelp-functional-title")}
            </h3>
            <div style={{ padding: 10 }}>
              <FontAwesomeIcon
                icon={faCalendarDays}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />
              <b> {t("introhelp-functional-planner-title")}</b>
              <br />- {t("introhelp-functional-planner-text1")}
              <br />- {t("introhelp-functional-planner-text2")}
              <br />- {t("introhelp-functional-planner-text3")}
              <br />- {t("introhelp-functional-planner-text4")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faRunning}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />
              <b> {t("introhelp-functional-habit-title")}</b>
              <br />- {t("introhelp-functional-habit-text1")}
              <br />- {t("introhelp-functional-habit-text2")}
              <br />- {t("introhelp-functional-habit-text3")}
              <br />- {t("introhelp-functional-habit-text4")}
              <br />- {t("introhelp-functional-habit-text5")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faBookOpen}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />{" "}
              <b>{t("introhelp-functional-journal-title")}</b>
              <br />- {t("introhelp-functional-journal-text1")}
              <br />- {t("introhelp-functional-journal-text2")}
              <br />- {t("introhelp-functional-journal-text3")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faShoppingCart}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />{" "}
              <b>{t("introhelp-functional-grocery-title")}</b>
              <br />- {t("introhelp-functional-grocery-text1")}
              <br />- {t("introhelp-functional-grocery-text2")}
              <br />- {t("introhelp-functional-grocery-text3")}
              <br />- {t("introhelp-functional-grocery-text4")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faList}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />
              <b>{t("introhelp-functional-todo-title")}</b>
              <br />- {t("introhelp-functional-todo-text1")}
              <br />- {t("introhelp-functional-todo-text2")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faNoteSticky}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />{" "}
              <b>{t("introhelp-functional-notes-title")}</b>
              <br />- {t("introhelp-functional-notes-text1")}
              <br />- {t("introhelp-functional-notes-text2")}
              <br />
              <br />
              <FontAwesomeIcon
                icon={faSpoon}
                size="lg"
                style={{
                  paddingRight: 10,
                  color: "black",
                }}
              />{" "}
              <b>{t("introhelp-functional-meal-title")}</b>
              <br />- {t("introhelp-functional-meal-text1")}
              <br />- {t("introhelp-functional-meal-text2")}
              <br />- {t("introhelp-functional-meal-text3")}
              <br />
              <br />
              <b>{t("introhelp-functional-familyshare-title")}</b>
              <br />
              <br />
              {t("introhelp-functional-familyshare-text")}
            </div>
            {/*}   <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={toggleAudio}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
                paddingBottom: 10,
                width: 200,
                lineHeight: 1.4,

                fontSize: 16,
                border: 0,
                borderRadius: 40,

                backgroundColor: "yellow",
                color: "black",
              }}
            >
              {isPlaying ? "Pause Audio" : "Tap Here To Play Audio Welcome"}
            </button>
            <audio ref={audioRef} controls={false}>
              <source src={audiointro} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
            </div>*/}
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", // Center vertically
                alignItems: "center", // Center horizontally
              }}
            >
              <button
                onClick={handleToggleFAQ}
                style={{
                  cursor: "pointer",
                  fontFamily: "inherit",
                  fontSize: 16,
                  width: "90%",
                  border: 1,
                  fontWeight: "bold",
                  padding: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 40,
                  backgroundColor: "#1e79c3",
                  color: "black",
                }}
                // onClick={close}
              >
                {isFAQ ? "Hide FAQ" : "Show FAQ"}
              </button>
              <div style={{ paddingTop: 10 }} />
              <button
                onClick={() => setIsViewAll(false)}
                style={{
                  cursor: "pointer",
                  fontFamily: "inherit",
                  fontSize: 16,
                  width: "90%",
                  border: 1,
                  fontWeight: "bold",
                  padding: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  borderRadius: 40,
                  backgroundColor: "#1e79c3",
                  color: "black",
                }}
                // onClick={close}
              >
                Hide Button Help
              </button>
              <div style={{ paddingTop: 10 }} />
              <button
                style={{
                  cursor: "pointer",
                  fontFamily: "inherit",
                  fontSize: 16,
                  width: "90%",
                  height: "75px",
                  border: 1,
                  fontWeight: "bold",
                  padding: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  marginTop: 10,
                  borderRadius: 40,
                  backgroundColor: "blue",
                  color: "white",
                }}
                onClick={close}
              >
                Close This Window
              </button>
              <div
                style={{
                  paddingTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isContactSupport ? (
                  <div
                    style={{ backgroundColor: "lightyellow", paddingTop: 20 }}
                  >
                    <button
                      style={{
                        float: "right",
                        backgroundColor: "transparent",
                        borderStyle: "none",
                        cursor: "pointer",
                        marginTop: -10,
                        marginRight: 10,
                      }}
                      onClick={() => setIsContactSupport(false)}
                    >
                      <FontAwesomeIcon icon={faClose} size="2x" />
                    </button>
                    <FeedbackWidget close={() => setIsContactSupport(false)} />
                  </div>
                ) : (
                  <div>
                    <button
                      style={{
                        cursor: "pointer",
                        fontFamily: "inherit",
                        fontSize: 16,
                        width: "100%",
                        border: 1,
                        fontWeight: "bold",
                        padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        // textDecoration: "underline",
                        //  borderRadius: 40,
                        backgroundColor: "transparent",
                        color: "blue",
                      }}
                      onClick={() => setIsContactSupport(true)}
                    >
                      {t("introhelp-button-contact")}
                    </button>
                  </div>
                )}
                <div style={{ paddingLeft: 10 }}>
                  <Link to="/contact">
                    <button
                      style={{
                        cursor: "pointer",
                        fontFamily: "inherit",
                        fontSize: 16,
                        width: "100%",
                        border: 1,
                        fontWeight: "bold",
                        padding: 5,
                        paddingTop: 10,
                        paddingBottom: 10,
                        //  textDecoration: "underline",
                        //  borderRadius: 40,
                        backgroundColor: "transparent",
                        color: "blue",
                      }}
                      onClick={close}
                    >
                      {t("introhelp-button-legal")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center vertically
              alignItems: "center", // Center horizontally
              // Full viewport height
            }}
          >
            <button
              style={{
                cursor: "pointer",
                fontFamily: "inherit",
                fontSize: 16,
                width: "90%",
                height: "75px",
                border: 1,
                fontWeight: "bold",
                padding: 5,
                paddingTop: 10,
                marginTop: 20,
                paddingBottom: 10,
                borderRadius: 40,
                backgroundColor: "blue",
                color: "white",
              }}
              onClick={close}
            >
              Close Help Window
            </button>
            <div style={{ paddingTop: 10 }} />
            <button
              onClick={handleToggleFAQ}
              style={{
                cursor: "pointer",
                fontFamily: "inherit",
                fontSize: 16,
                width: "90%",
                border: 1,
                fontWeight: "bold",
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 40,
                backgroundColor: "#1e79c3",
                color: "black",
              }}
              // onClick={close}
            >
              {isFAQ ? "Hide FAQ" : "Show FAQ"}
            </button>
            <div style={{ paddingTop: 10 }} />
            <button
              onClick={() => setIsViewAll(true)}
              style={{
                cursor: "pointer",
                fontFamily: "inherit",
                fontSize: 16,
                width: "90%",
                border: 1,
                fontWeight: "bold",
                padding: 5,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 40,
                backgroundColor: "#1e79c3",
                color: "black",
              }}
              // onClick={close}
            >
              Show Button Help
            </button>

            <div
              style={{
                paddingTop: 10,
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {isContactSupport ? (
                <div style={{ backgroundColor: "lightyellow", paddingTop: 20 }}>
                  <button
                    style={{
                      float: "right",
                      backgroundColor: "transparent",
                      borderStyle: "none",
                      cursor: "pointer",
                      marginTop: -10,
                      marginRight: 10,
                    }}
                    onClick={() => setIsContactSupport(false)}
                  >
                    <FontAwesomeIcon icon={faClose} size="2x" />
                  </button>
                  <FeedbackWidget close={() => setIsContactSupport(false)} />
                </div>
              ) : (
                <div>
                  <button
                    style={{
                      cursor: "pointer",
                      fontFamily: "inherit",
                      fontSize: 16,
                      width: "100%",
                      border: 1,
                      fontWeight: "bold",
                      padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      // textDecoration: "underline",
                      //  borderRadius: 40,
                      backgroundColor: "transparent",
                      color: "blue",
                    }}
                    onClick={() => setIsContactSupport(true)}
                  >
                    {t("introhelp-button-contact")}
                  </button>
                </div>
              )}

              <div style={{ paddingLeft: 10 }}>
                <Link to="/contact">
                  <button
                    style={{
                      cursor: "pointer",
                      fontFamily: "inherit",
                      fontSize: 16,
                      width: "100%",
                      border: 1,
                      fontWeight: "bold",
                      padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      // textDecoration: "underline",
                      //  borderRadius: 40,
                      backgroundColor: "transparent",
                      color: "blue",
                    }}
                    onClick={close}
                  >
                    {t("introhelp-button-legal")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
        <hr />
        {!isNative ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>{t("settings-app-downloads")}</h3>
            <Link
              to={{
                pathname:
                  "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070",
              }}
              target="_blank"
            >
              {" "}
              <div
                style={{
                  padding: 10,
                  fontSize: 16,
                  marginTop: 0,
                  color: "black",
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  width: 200,
                  cursor: "pointer",
                }}
              >
                {" "}
                <DiApple /> Apple IOS{" "}
              </div>
            </Link>
            <Link
              to={{
                pathname:
                  "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes",
              }}
              target="_blank"
            >
              {" "}
              <div
                style={{
                  padding: 10,
                  fontSize: 16,
                  marginTop: 20,
                  color: "black",
                  borderRadius: 10,
                  textAlign: "center",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  width: 200,
                  cursor: "pointer",
                }}
              >
                {" "}
                <DiAndroid /> Google Android
              </div>
            </Link>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};
