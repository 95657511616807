import { useEffect, useState } from "react";
import Linkify from "linkify-react";
//import emailjs from "@emailjs/browser";
import TextareaAutosize from "react-textarea-autosize";
import toast, { Toaster } from "react-hot-toast";
import { useUserSubscription } from "Authentication/useUserSubscription";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const FeedbackWidget = ({ close }) => {
  const user = useUserSubscription();
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const location = useLocation();

  const submitFeedback = async (e) => {
    e.preventDefault();
    // console.log(user.userProfile.userId);
    const userEmail =
      user.userProfile.email != undefined ? user.userProfile.email : "";
    const userId = user.userProfile.userId;

    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/sendEmail/send-email",

        // "http://127.0.0.1:5001/dopenotes-20ee4/us-central1/sendEmail/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            recipient: "tim.holmgren@gmail.com",
            subject: "Site Feedback",
            body: {
              userEmail: userEmail,
              userId: userId,
              feedback: feedback,
            },
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        toast(t("feedback-widget-toast-thankyou"), {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "lightgreen",
            border: "1px solid #713200",
            fontSize: "16px",
            padding: "10px",
            color: "black",
          },
        });
      } else {
        // setResponseMessage(`Error: ${result.error}`);
        console.log(result.error);
      }
    } catch (error) {
      toast(t("feedback-widget-toast-nointernet"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }

    setFeedback("");
    setTimeout(() => {
      close();
    }, 1000);
  };

  useEffect(() => {
    return () => {};
    //@ts-ignore
  }, []);

  return (
    <div
      style={{
        padding: 20,
        paddingTop: 20,
        textAlign: "center",
        marginTop: location.pathname != "/" ? 0 : -20,
      }}
    >
      <Toaster />
      <h3>{t("feedback-widget-title")}</h3>
      {user.email == "" || user.email == undefined ? (
        <p style={{ fontSize: 14 }}>
          Please add your email below if you need a reply.
        </p>
      ) : null}
      <form onSubmit={submitFeedback}>
        <TextareaAutosize
          required
          value={feedback}
          minLength={2}
          placeholder={t("feedback-widget-input-placeholder")}
          onChange={(e) => setFeedback(e.target.value)}
          style={{
            width: "90%",
            //  fontWeight: "bold",
            fontSize: 18,
            fontFamily: "inherit",
            lineHeight: 1.35,
            paddingTop: 5,
            paddingBottom: 20,

            border: 0,
            color: "white",
            //backgroundColor: "#67d7cc",
            backgroundColor: "black",
          }}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 5,
          }}
        >
          <button
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
              border: 1,
              fontWeight: "bold",
              padding: 5,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 40,
              backgroundColor: "#1e79c3",
              color: "white",
            }}
            type="submit"
          >
            {t("feedback-widget-button-send")}
          </button>
        </div>
        {location.pathname != "/" ? (
          <p
            className="actions_link_default"
            style={{ paddingTop: 5, textAlign: "center" }}
            onClick={() => close()}
          >
            {t("common-button-cancel")}
          </p>
        ) : null}
      </form>
      <br />
    </div>
  );
};
