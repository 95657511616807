import { EventModel } from "Journal/models/events";
import { Link, useHistory } from "react-router-dom";
import classes from "./RegisteredSplash.module.css";
import { doc, updateDoc } from "firebase/firestore";
//import FadeIn from "react-fade-in";
import { useEffect, useState, useRef, memo } from "react";

//import mylisticon from "../../../src/Assets/checklist.png";
import moment from "moment";
//import journalicon from "../../../src/Assets/notebook.png";
//import recipeicon from "../../../src/Assets/recipe.png";
//import notesicon from "../../../src/Assets/sticky-notes.png";
import settingsicon from "../../../src/Assets/user-profile.png";
//import shoppingicon from "../../../src/Assets/wishlist.png";
import lightbulbicon from "../../../src/Assets/lightbulb.png";
import notificationicon from "../../../src/Assets/notifications.png";
import helpicon from "../../../src/Assets/help.png";
//import feedbackicon from "../../../src/Assets/feedback.png";
import scoreIcon from "../../Assets/score.png";
import { useUserContext } from "Authentication";
import { HabitsCompleted } from "./sections/habitTracker";
//import { useItemSubscriptionToDo } from "../../ListToDo";
import { CategoriesToDo } from "ListToDo/List/Categories";
//import { useItemSubscription } from "Lists";
import { Categories } from "Lists/List/Categories";
import { JournalWidget } from "./sections/JournalWidget";
import { NotesWidget } from "./sections/NotesWidget";
import { FirstLaunchHelp } from "./sections/FirstLaunchHelp";
import { RecipeWidget } from "./sections/RecipeWidget";
import { SelectRef } from "Lists/List/AddItem";
import { AddItemWidget } from "Lists/List/AddItem/AddItemWidget";
import { ItemModel } from "Lists";
import { CategoryModal } from "Lists/List/CategoryModal";
import { ItemModal } from "Lists/List/ItemModal";
import { AddItemToDoWidget } from "ListToDo/List/AddItem/AddItemToDoWidget";
import { ItemModalToDo } from "ListToDo/List/ItemModal";
import { CategoryModalToDo } from "ListToDo/List/CategoryModal";
import { useItems } from "Lists";
import { useItemsToDo } from "../../ListToDo/useItemsToDo";
import toast from "react-hot-toast";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { WhatsNewWidget } from "./sections/WhatsNewWidget";
import { FeedbackWidget } from "./sections/FeedbackWidget";
import { NotesModel } from "Notes/models/notes";
import { HabitModel } from "Habits/habitmodel";
import { DopeSummaryWidget } from "./DopeSummaryWidget";
import { User } from "../../Authentication/models/user";
import { RecipeModel } from "Recipes/models/recipe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  //faEdit,
  //faMinusCircle,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { EditDefaultItemsModal } from "Lists/List/Common/Item/EditDefaultItemsModal";
import { Planner } from "Planner/Planner";
import { PlannerModel } from "Planner/models/plannerModel";
import { useTranslation } from "react-i18next";
import { AIListGenerator } from "ListToDo/List/AIListGenerator/AIListGenerator";
import { RemoteControl } from "./RemoteControl";
import { SortableListWidget } from "./SortableListWidget";
//import { MealPlanner } from "Recipes/MealPlanner/MealPlanner";

//import { User } from "../../Authentication/models/user";

interface RegisteredSplashProps {
  //  itemCount: number;
  habits: HabitModel[];
  recipes: RecipeModel;
  notes: NotesModel[];
  addedItems: ItemModel[];
  allItems: ItemModel[];
  todo: ItemModel[];
  todoAll: ItemModel[];
  user: User;
  events: EventModel[];
  isExpired: boolean;
  eventsPlanner: PlannerModel[];
  isDarkMode: boolean;
  stepCount: number;
  isOnline: boolean;
  mealPlannerData: any[];
  categories: any[];
}

const styles = {
  button: {
    borderColor: "snow",
    paddingTop: 15,
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "1px 5px 9px snow",
    // borderStyle: 'groove',
    opacity: 0.85,
  },
  buttonContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 22,
    paddingBottom: 5,
    cursor: "pointer",
  },
};

const SortableList = SortableContainer(({ children }) => (
  <div
    style={{
      // display: !isSortMode ? "grid" : "",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr)",
      gap: "0.8em",
    }}
  >
    {children}
  </div>
));
const SortableItem = SortableElement(({ value, index }) => (
  <div
    key={index}
    style={{
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      border: 1,
      borderStyle: "groove",
      textAlign: "center",
      fontSize: 14,
      backgroundColor: "blue",
      color: "white",
      cursor: "pointer",
    }}
  >
    {value.name}
  </div>
));

export const RegisteredSplash = memo((props: RegisteredSplashProps) => {
  const {
    habits,
    events,
    notes,
    addedItems,
    recipes,
    allItems,
    todo,
    todoAll,
    user,
    eventsPlanner,
    isDarkMode,
    isExpired,
    stepCount,
    isOnline,
    mealPlannerData,
    categories,
  } = props;
  // console.log("what is notes RegisterSplash", notes);
  //  const [isMobile, setIsMobile] = useState(false);
  const [widgetSortHeight, setWidgeSorttHeight] = useState("100%");
  const [overFlowSort, setOverFlowSort] = useState("auto");
  //  const [touchTimeSort, setTouchTimeSort] = useState(2000);
  const [isSortMode, setIsSortMode] = useState(false);
  //const { UpdateWidgetOrder } = useUserContext();
  const { t } = useTranslation();

  //const user = useUserSubscription();
  //@ts-ignore
  const storedStateWidgetSize = localStorage.getItem("dashboardwidgetsize");
  const storedStateWidgetSizeText = localStorage.getItem(
    "dashboardwidgetsizetext"
  );
  //const categories = [];
  //@ts-ignore
  // console.log("what is expired", isExpired);
  const [isEmailValid, setIsEmailValid] = useState(
    //@ts-ignore
    user.email === "" ? false : true
  );

  const [widgetSize, setWidgetSize] = useState(
    storedStateWidgetSize ? Number(JSON.parse(storedStateWidgetSize)) : 440
  );
  const [widgetSizeText, setWidgetSizeText] = useState(
    storedStateWidgetSizeText ? JSON.parse(storedStateWidgetSizeText) : "large"
  );

  const items = [
    {
      id: "item-5",
      content: "NotesSummary",
      name: t("header-menu-notes"),
    },
    {
      id: "item-3",
      content: "ToDoSummary",
      name: t("header-menu-tasks"),
    },
    {
      id: "item-4",
      content: "ListSummary",
      name: t("header-menu-grocery"),
    },
    {
      id: "item-2",
      content: "EventsSummary",
      name: t("header-menu-journal"),
    },
    {
      id: "item-1",
      content: "HabitsCompleted",
      name: t("header-menu-habit"),
    },
    {
      id: "item-6",
      content: "RecipeSummary",
      name: t("header-menu-meal"),
    },
    {
      id: "item-7",
      content: "SortableListSummary",
      name: t("header-menu-sortableList"),
    },
  ];

  const ItemLookUp = (lookup) => {
    if (lookup === "HabitsCompleted")
      return (
        <div
          style={{
            backgroundColor: "#fef68a",
            borderRadius: 10,
            padding: 5,
          }}
        >
          <HabitsCompleted
            habits={habits}
            widgetSize={widgetSize}
            isExpired={isExpired}
            isSortMode={isSortMode}
            user={user}
          />
        </div>
      );

    if (lookup === "EventsSummary")
      return (
        <div>
          <EventsSummary
            events={events}
            widgetSize={widgetSize}
            isExpired={isExpired}
            isSortMode={isSortMode}
            isDarkMode={isDarkMode}
            isOnline={isOnline}
          />
        </div>
      );

    if (lookup === "ToDoSummary")
      return (
        <div>
          <ToDoSummary
            widgetSize={widgetSize}
            todo={todo}
            todoAll={todoAll}
            isExpired={isExpired}
            isSortMode={isSortMode}
            isDarkMode={isDarkMode}
          />
        </div>
      );

    if (lookup === "ListSummary")
      return (
        <div>
          <ListSummary
            widgetSize={widgetSize}
            addedItems={addedItems}
            allItems={allItems}
            isExpired={isExpired}
            isSortMode={isSortMode}
            isDarkMode={isDarkMode}
            isOnline={isOnline}
            categories={categories}
          />
        </div>
      );

    if (lookup === "NotesSummary")
      return (
        <div>
          <NotesSummary
            notes={notes}
            widgetSize={widgetSize}
            isExpired={isExpired}
            isSortMode={isSortMode}
          />
        </div>
      );

    if (lookup === "RecipeSummary")
      return (
        <div>
          <RecipeSummary
            widgetSize={widgetSize}
            isSortMode={isSortMode}
            mealPlannerData={mealPlannerData}
          />
        </div>
      );
    if (lookup === "SortableListSummary")
      return (
        <div>
          <SortableListSummary
            widgetSize={widgetSize}
            isSortMode={isSortMode}
            user={user}
          />
        </div>
      );
  };

  const storedState = localStorage.getItem("sortableListState");
  const [state, setState] = useState(
    storedState ? JSON.parse(storedState) : items
  );

  //console.log("what is stored state", storedState);
  //console.log("what is items", state);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex != newIndex) {
      const reorderedItems = state.slice();
      reorderedItems.splice(newIndex, 0, reorderedItems.splice(oldIndex, 1)[0]);
      setState(reorderedItems);
      localStorage.setItem("sortableListState", JSON.stringify(reorderedItems));
    }
  };

  const SortButtonStart = () => {
    //  setOverFlowSort("clip");
    // setWidgeSorttHeight("75px");
    // setTouchTimeSort(0);
    setIsSortMode(true);

    //temporary reduce width size for better sorting
    setWidgetSize(25);
  };

  const SortButtonClose = () => {
    // setOverFlowSort("auto");
    // setWidgeSorttHeight("100%");
    // setTouchTimeSort(30);
    setIsSortMode(false);

    //update the widget text and save to local device

    if (widgetSizeText === "xlarge") {
      setWidgetSize(600);
      localStorage.setItem("dashboardwidgetsize", JSON.stringify(600));
    } else if (widgetSizeText === "large") {
      setWidgetSize(440);
      localStorage.setItem("dashboardwidgetsize", JSON.stringify(440));
    } else if (widgetSizeText === "medium") {
      setWidgetSize(300);
      localStorage.setItem("dashboardwidgetsize", JSON.stringify(300));
    } else {
      setWidgetSize(220);
      localStorage.setItem("dashboardwidgetsize", JSON.stringify(220));
    }
    localStorage.setItem(
      "dashboardwidgetsizetext",
      JSON.stringify(widgetSizeText)
    );
  };

  //widget const and functions

  const [hideNotes, setHideNotes] = useState(
    localStorage.getItem("NotesSummary") != undefined
      ? localStorage.getItem("NotesSummary")
      : "true"
  );
  const [hideTodo, setHideTodo] = useState(
    localStorage.getItem("ToDoSummary") != undefined
      ? localStorage.getItem("ToDoSummary")
      : "true"
  );
  const [hideShopping, setHideShopping] = useState(
    localStorage.getItem("ListSummary") != undefined
      ? localStorage.getItem("ListSummary")
      : "true"
  );
  const [hideHabits, setHideHabits] = useState(
    localStorage.getItem("HabitsCompleted") != undefined
      ? localStorage.getItem("HabitsCompleted")
      : "true"
  );
  const [hideJournal, setHideJournal] = useState(
    localStorage.getItem("EventsSummary") != undefined
      ? localStorage.getItem("EventsSummary")
      : "true"
  );
  const [hideMeal, setHideMeal] = useState(
    localStorage.getItem("RecipeSummary") != undefined
      ? localStorage.getItem("RecipeSummary")
      : "true"
  );
  const [hideSortableList, setHideSortableList] = useState(
    localStorage.getItem("SortableListSummary") != undefined
      ? localStorage.getItem("SortableListSummary")
      : "true"
  );
  const [hideTips, setHideTips] = useState(
    localStorage.getItem("HideTips") != undefined
      ? localStorage.getItem("HideTips")
      : "false"
  );
  const [hideFeedback, setHideFeedback] = useState(
    localStorage.getItem("HideFeedback") != undefined
      ? localStorage.getItem("HideFeedback")
      : "false"
  );

  const [hideScorepad, setHideScorepad] = useState(
    localStorage.getItem("HideScorepad") != undefined
      ? localStorage.getItem("HideScorepad")
      : "true"
  );

  const [hideReminders, setHideReminders] = useState(
    localStorage.getItem("HideReminders") != undefined
      ? localStorage.getItem("HideReminders")
      : "true"
  );

  const [hideDopeSummary, setHideDopeSummary] = useState(
    localStorage.getItem("HideDopeSummary") != undefined
      ? localStorage.getItem("HideDopeSummary")
      : "false"
  );

  const [hidePlanner, setHidePlanner] = useState(
    localStorage.getItem("HidePlanner") != undefined
      ? localStorage.getItem("HidePlanner")
      : "true"
  );

  const checkWidgetHidden = (value) => {
    //notes
    if (value === "HabitsCompleted") {
      return hideHabits;
    } else if (value === "NotesSummary") {
      return hideNotes;
    } else if (value === "ToDoSummary") {
      return hideTodo;
    } else if (value === "ListSummary") {
      return hideShopping;
    } else if (value === "EventsSummary") {
      return hideJournal;
    } else if (value === "RecipeSummary") {
      return hideMeal;
    } else if (value === "SortableListSummary") {
      return hideSortableList;
    } else {
      return "false";
    }
  };

  const hideWidgetButton = (value) => {
    //habits
    if (value === "HabitsCompleted") {
      if (hideHabits === "false") {
        localStorage.setItem(value, "true");
        setHideHabits("true");
      } else {
        localStorage.setItem(value, "false");
        setHideHabits("false");
      }
    }
    //notes
    if (value === "NotesSummary") {
      if (hideNotes === "false") {
        localStorage.setItem(value, "true");
        setHideNotes("true");
      } else {
        localStorage.setItem(value, "false");
        setHideNotes("false");
      }
    }
    //todo
    if (value === "ToDoSummary") {
      if (hideTodo === "false") {
        localStorage.setItem(value, "true");
        setHideTodo("true");
      } else {
        localStorage.setItem(value, "false");
        setHideTodo("false");
      }
    }
    //shopping
    if (value === "ListSummary") {
      if (hideShopping === "false") {
        localStorage.setItem(value, "true");
        setHideShopping("true");
      } else {
        localStorage.setItem(value, "false");
        setHideShopping("false");
      }
    }
    //journal
    if (value === "EventsSummary") {
      if (hideJournal === "false") {
        localStorage.setItem(value, "true");
        setHideJournal("true");
      } else {
        localStorage.setItem(value, "false");
        setHideJournal("false");
      }
    }
    //recipes
    if (value === "RecipeSummary") {
      if (hideMeal === "false") {
        localStorage.setItem(value, "true");
        setHideMeal("true");
      } else {
        localStorage.setItem(value, "false");
        setHideMeal("false");
      }
    }
    //sortableList
    if (value === "SortableListSummary") {
      if (hideSortableList === "false") {
        localStorage.setItem(value, "true");
        setHideSortableList("true");
      } else {
        localStorage.setItem(value, "false");
        setHideSortableList("false");
      }
    }
    //tips
    if (value === "HideTips") {
      if (hideTips === "false") {
        localStorage.setItem(value, "true");
        setHideTips("true");
      } else {
        localStorage.setItem(value, "false");
        setHideTips("false");
      }
    }
    //feedback
    if (value === "HideFeedback") {
      if (hideFeedback === "false") {
        localStorage.setItem(value, "true");
        setHideFeedback("true");
      } else {
        localStorage.setItem(value, "false");
        setHideFeedback("false");
      }
    }

    //feedback
    if (value === "HideScorepad") {
      if (hideScorepad === "false") {
        localStorage.setItem(value, "true");
        setHideScorepad("true");
      } else {
        localStorage.setItem(value, "false");
        setHideScorepad("false");
      }
    }

    //reminders
    if (value === "HideReminders") {
      if (hideReminders === "false") {
        localStorage.setItem(value, "true");
        setHideReminders("true");
      } else {
        localStorage.setItem(value, "false");
        setHideReminders("false");
      }
    }

    //feedback
    if (value === "HideDopeSummary") {
      if (hideDopeSummary === "false") {
        localStorage.setItem(value, "true");
        setHideDopeSummary("true");
      } else {
        localStorage.setItem(value, "false");
        setHideDopeSummary("false");
      }
    }

    //planner
    if (value === "HidePlanner") {
      if (hidePlanner === "false") {
        localStorage.setItem(value, "true");
        setHidePlanner("true");
      } else {
        localStorage.setItem(value, "false");
        setHidePlanner("false");
      }
    }
  };

  const convertBool = (value) => {
    if (value === "true") {
      return true;
    } else {
      return false;
    }
  };

  //const TodayDate = moment(new Date()).format("MMMM DD, YYYY");
  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);

  useEffect(() => {
    //checkThemeSettings();
    // window.scrollTo({ top: 0, behavior: "smooth" });
    //console.log("test home");
    //console.log("ping");
    if ((window as any).isAloha) {
      setIsAlohaPlanner(true);
    }

    return () => {};
  }, []);

  const handleSelectWidgetSize = (e) => {
    setWidgetSizeText(e.target.value);
  };

  const [isFirstLaunchHelp, setIsFirstLaunchHelp] = useState(false);

  return (
    <article style={{ padding: 10, backgroundColor: "transparent" }}>
      <div>
        <h2 className="h2SlideIn" style={{ textAlign: "center", marginTop: 5 }}>
          ALOHA PLANNER
          <FontAwesomeIcon
            onClick={() => setIsFirstLaunchHelp(true)}
            icon={faQuestionCircle}
            size="xs"
            style={{
              marginBottom: 20,
              cursor: "pointer",
              color: "blue",
              paddingLeft: 5,
              paddingRight: 5,
            }}
          />
        </h2>
        <div style={{ textAlign: "center", paddingBottom: 20, marginTop: -15 }}>
          {isEmailValid ? (
            ""
          ) : (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setIsFirstLaunchHelp(true)}
            >
              Unregistered Version
            </span>
          )}
        </div>
      </div>
      <FirstLaunchHelp
        isOpen={isFirstLaunchHelp}
        close={() => setIsFirstLaunchHelp(false)}
        user={user}
      />
      {!isSortMode ? (
        <div />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              margin: 10,
            }}
          >
            {" "}
            <button
              style={{
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
                marginTop: 10,
                marginRight: 10,
              }}
              onClick={SortButtonClose}
            >
              <FontAwesomeIcon icon={faClose} size="3x" />
            </button>
          </div>
          <h2 style={{ textAlign: "center", color: "red" }}>
            {t("dashboard-edit-widgets-label")}
          </h2>
        </div>
      )}
      {!isSortMode ? (
        <div />
      ) : (
        <div>
          <label
            style={{ fontSize: 16, textAlign: "center", paddingBottom: 20 }}
          >
            {t("dashboard-edit-sort-widgets-label")} <br />(
            {t("dashboard-edit-drag-instructions")})
          </label>
        </div>
      )}
      <div>
        {isSortMode ? (
          <div>
            <SortableList
              onSortEnd={onSortEnd}
              // pressDelay={touchTimeSort}
              // disableAutoscroll={false}
            >
              {state.map((value, index) => (
                <SortableItem
                  key={`item-${value.id}`}
                  index={index}
                  value={value}
                  disabled={!isSortMode}
                />
              ))}
            </SortableList>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 30,
                paddingBottom: 20,
                fontSize: 14,
              }}
            >
              {" "}
              <p
                style={{
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  marginTop: -10,
                }}
              >
                {t("dashboard-edit-hide-widgets")}
                {/*}   ( {t("dashboard-edit-check-to-hide")})*/}
              </p>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideDopeSummary)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HideDopeSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideDopeSummary) ? "gray" : "black",
                    textDecoration: convertBool(hideDopeSummary)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("dashboard-today-summary-label")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hidePlanner)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HidePlanner");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hidePlanner) ? "gray" : "black",
                    textDecoration: convertBool(hidePlanner)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-planner")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideHabits)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HabitsCompleted");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideHabits) ? "gray" : "black",
                    textDecoration: convertBool(hideHabits)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-habit")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideNotes)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("NotesSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideNotes) ? "gray" : "black",
                    textDecoration: convertBool(hideNotes)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-notes")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideTodo)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("ToDoSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideTodo) ? "gray" : "black",
                    textDecoration: convertBool(hideTodo)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-tasks")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideShopping)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("ListSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideShopping) ? "gray" : "black",
                    textDecoration: convertBool(hideShopping)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-grocery")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideJournal)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("EventsSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideJournal) ? "gray" : "black",
                    textDecoration: convertBool(hideJournal)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-journal")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideMeal)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("RecipeSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideMeal) ? "gray" : "black",
                    textDecoration: convertBool(hideMeal)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-meal")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideSortableList)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("SortableListSummary");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideSortableList) ? "gray" : "black",
                    textDecoration: convertBool(hideSortableList)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-sortableList")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideTips)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HideTips");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideTips) ? "gray" : "black",
                    textDecoration: convertBool(hideTips)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("dashboard-edit-hints")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideFeedback)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HideFeedback");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideFeedback) ? "gray" : "black",
                    textDecoration: convertBool(hideFeedback)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("dashboard-edit-feedback")}
                </span>
              </div>
              <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideScorepad)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HideScorepad");
                  }}
                  //  className={classes.details}
                />{" "}
                <span
                  style={{
                    color: convertBool(hideScorepad) ? "gray" : "black",
                    textDecoration: convertBool(hideScorepad)
                      ? "line-through"
                      : "black",
                  }}
                >
                  {t("header-menu-score")}
                </span>
              </div>
              {/*}  <div style={{ paddingBottom: 10, minWidth: "200px" }}>
                <input
                  type="checkbox"
                  checked={convertBool(hideReminders)}
                  style={{ marginTop: 0 }}
                  onChange={() => {
                    hideWidgetButton("HideReminders");
                  }}
                  //  className={classes.details}
                />{" "}
                <span>Reminders</span>
                </div>*/}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: 20,
              }}
            >
              {" "}
              <label style={{ fontSize: 16 }}>
                {t("dashboard-edit-select-widget-size")}
              </label>
              <select
                value={widgetSizeText}
                onChange={(e) => handleSelectWidgetSize(e)}
                style={{
                  fontSize: 14,
                  marginTop: 10,
                  padding: 10,
                  width: 200,
                }}
              >
                <option value="small">{t("dashboard-edit-sm")}</option>
                <option value="medium">{t("dashboard-edit-md")}</option>
                <option value="large">{t("dashboard-edit-lg")}</option>
                <option value="xlarge">{t("dashboard-edit-xl")}</option>
              </select>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 30,
                paddingBottom: 20,
              }}
            >
              <button
                style={{
                  fontSize: 18,
                  fontFamily: "inherit",
                  //   zIndex: 10,
                  margin: 0,
                  padding: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "blue",
                  borderRadius: 5,
                  border: 1,
                  cursor: "pointer",
                }}
                onClick={SortButtonClose}
              >
                Cancel
              </button>
              <div style={{ padding: 10 }} />
              <button
                style={{
                  fontSize: 18,
                  fontFamily: "inherit",
                  //   zIndex: 10,
                  margin: 0,
                  padding: 10,
                  paddingLeft: 30,
                  paddingRight: 30,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "blue",
                  borderRadius: 5,
                  border: 1,
                  cursor: "pointer",
                }}
                onClick={SortButtonClose}
              >
                {t("common-button-update")}
              </button>
            </div>
          </div>
        ) : (
          <div>
            {!convertBool(hideDopeSummary) ? (
              <div style={{ paddingBottom: 20 }}>
                <DopeSummary
                  widgetSize={widgetSize}
                  stepCount={stepCount}
                  //@ts-ignore
                  recipes={recipes}
                  addedItems={addedItems}
                  notes={notes}
                  user={user}
                  todo={todo}
                  events={events}
                  habits={habits}
                  eventsPlanner={eventsPlanner}
                  isDarkMode={isDarkMode}
                  isOnline={isOnline}
                />
              </div>
            ) : null}
            {!convertBool(hidePlanner) ? (
              <div style={{ zIndex: -1, paddingBottom: 20, paddingTop: 20 }}>
                <Planner
                  data={eventsPlanner}
                  dataToDo={todoAll}
                  habits={habits}
                  isExpired={isExpired}
                />
              </div>
            ) : null}
            <div className={classes.grid}>
              {state.map((value, index) =>
                checkWidgetHidden(value.content) != "true" ? (
                  <div
                    key={value.id}
                    style={{
                      height: widgetSortHeight,
                      overflow: overFlowSort,
                    }}
                  >
                    {ItemLookUp(value.content)}
                  </div>
                ) : null
              )}
              {!convertBool(hideTips) ? (
                <div>
                  <WhatsNew widgetSize={widgetSize} />
                </div>
              ) : null}
              {!convertBool(hideFeedback) ? (
                <div>
                  <Feedback widgetSize={widgetSize} />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
                onClick={SortButtonStart}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  //  className={classes.blinkingButton}
                  //onClick={() => setIsAddingNewItem(true)}
                  style={{ marginTop: 0, paddingRight: 0 }}
                  size="5x"
                  color="blue"
                />
                <p style={{ color: "blue" }}>
                  {t("dashboard-add-edit-widgets")}
                </p>
              </div>
            </div>
          </div>
        )}
        {!isSortMode ? (
          <div className={classes.grid} style={{ paddingTop: 30 }}>
            {!convertBool(hideScorepad) ? (
              <div>
                <ScorePad />
              </div>
            ) : null}
            {!convertBool(hideReminders) ? (
              <div>
                <Notification />
              </div>
            ) : null}

            {/*}  <div>
                <InviteFriend />
              </div>
              <div>
                <Help />
              </div>*/}

            {/*}  <div>
                <Feedback />
          </div>*/}
          </div>
        ) : (
          <div />
        )}
      </div>
      <p
        style={{
          fontSize: 18,
          fontFamily: "inherit",
          textAlign: "center",
          marginTop: -20,
          lineHeight: 1.3,
          paddingBottom: 100,
          paddingTop: 30,
        }}
      >
        “{t("dashboard-footer-quote")}” ― Voltaire
      </p>
    </article>
  );
});

interface ListSummaryProps {
  widgetSize: number;
  addedItems: ItemModel[];
  allItems: ItemModel[];
  isExpired: boolean;
  isSortMode: boolean;
  isDarkMode: boolean;
  isOnline: boolean;
  categories: any[];
}

const ListSummary = memo((props: ListSummaryProps) => {
  const {
    widgetSize,
    addedItems,
    allItems,
    isExpired,
    isSortMode,
    isDarkMode,
    isOnline,
    categories,
  } = props;
  const { getDefaultItemsCollection } = useUserContext();
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemModel>();
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [isEditDefaultItem, setIsEditDefaultItem] = useState(false);
  const routeChange = (path) => {
    history.push(path);
  };
  // const { addedItems, allItems } = useItemSubscription();
  const inputRef = useRef<SelectRef>(null);
  const completedItems = addedItems.filter((i) => i.completed);
  const { batchRemoveItems } = useItems();
  const { t } = useTranslation();
  const handleClickAddCategoryItem = (category: string | undefined) => {
    setSelectedCategory(category ?? "");
    inputRef.current?.focus();
  };

  const handleClickItemCategory = (item: ItemModel) => {
    setSelectedItem(item);
    setIsCategoryModalOpen(true);
  };

  const handleClickItem = (item: ItemModel) => {
    setSelectedItem(item);
    setIsItemModalOpen(true);
  };

  const handleSelectCategory = async (category: string) => {
    const itemsRef = await getDefaultItemsCollection();
    const itemRef = doc(itemsRef, selectedItem?.id);
    await updateDoc(itemRef, {
      category,
    });
  };

  const handleClearCompleted = async () => {
    const addedIds = completedItems.map((i) => i.id);
    await batchRemoveItems(addedIds);

    /* toast("All completed items have been removed", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "pink",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });*/

    //console.log("clear completed")
  };

  return (
    <section
      style={{
        backgroundColor: "lavender",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div style={{ cursor: "pointer" }}>
          {!isSortMode ? (
            <div
              onClick={() => routeChange("/shopping")}
              style={{
                color: "white",
                backgroundColor: "purple",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <h3
            style={{ textAlign: "center", textDecoration: "underline" }}
            onClick={() => routeChange("/shopping")}
          >
            {t("grocery-list-title")}
          </h3>
        </div>
        {!isSortMode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: -15,
            }}
          >
            {isAddingNewItem ? (
              <div style={{ width: "80%", marginTop: 2 }}>
                <AddItemWidget
                  addedItems={addedItems}
                  allItems={allItems}
                  category={selectedCategory}
                  ref={inputRef}
                  setCategory={setSelectedCategory}
                  isDarkMode={isDarkMode}
                  categories={categories}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {completedItems.length >= 1 ? (
                    <>
                      <button
                        onClick={handleClearCompleted}
                        style={{
                          width: 85,
                          // height: 25,
                          //  marginTop: -5,
                          //   backgroundColor: "#3e98c7",
                          fontFamily: "inherit",
                          backgroundColor: "coral",
                          color: "white",
                          cursor: "pointer",
                          border: 0,
                          marginTop: 2,
                          borderRadius: 5,
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        {t("common-button-clear")}
                      </button>
                      <span style={{ paddingLeft: 5 }} />
                    </>
                  ) : null}

                  <button
                    onClick={() => setIsAddingNewItem(false)}
                    style={{
                      //width: 85,
                      // height: 25,
                      //  marginTop: -5,
                      //   backgroundColor: "#3e98c7",
                      fontFamily: "inherit",
                      backgroundColor: "blue",
                      color: "white",
                      cursor: "pointer",
                      border: 0,
                      marginTop: 2,
                      borderRadius: 5,
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {t("common-button-hideinput")}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{ justifyContent: "center" }}>
                {completedItems.length >= 1 ? (
                  <>
                    <button
                      onClick={handleClearCompleted}
                      style={{
                        // width: 85,
                        // height: 25,
                        //  marginTop: -5,
                        //   backgroundColor: "#3e98c7",
                        fontFamily: "inherit",
                        backgroundColor: "coral",
                        color: "white",
                        cursor: "pointer",
                        border: 0,
                        marginTop: 2,
                        borderRadius: 5,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      {t("common-button-clear")}
                    </button>
                    <span style={{ paddingLeft: 5 }} />
                  </>
                ) : null}
                <button
                  onClick={() => setIsAddingNewItem(true)}
                  style={{
                    // width: 85,
                    // height: 25,
                    //  marginTop: -5,
                    //   backgroundColor: "#3e98c7",
                    fontFamily: "inherit",
                    backgroundColor: "purple",
                    color: "white",
                    cursor: "pointer",
                    border: 0,
                    marginTop: 2,
                    borderRadius: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  + {t("common-button-add")}
                </button>
              </div>
            )}
          </div>
        ) : null}
        <div
          style={{
            height: widgetSize - 0,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <br />
          <Categories
            addedItems={addedItems}
            showCompleted={true}
            showAllCat={false}
            onClickAddCategoryItem={handleClickAddCategoryItem}
            onClickItem={handleClickItem}
            onClickItemCategory={handleClickItemCategory}
            isDarkMode={isDarkMode}
            categories={categories}
          />

          {isOnline ? (
            <p
              style={{
                textAlign: "center",
                fontSize: 14,
                fontWeight: "bold",
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
              onClick={() => setIsEditDefaultItem(true)}
            >
              {t("grocery-list-edit-list")}
            </p>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: 14,
                fontWeight: "bold",
                cursor: "pointer",
                color: "gray",
                textDecoration: "underline",
              }}
              // onClick={() => setIsEditDefaultItem(true)}
            >
              {t("grocery-list-edit-list-no-internet")}
            </p>
          )}
          <br />
          {isCategoryModalOpen ? (
            <CategoryModal
              isOpen={isCategoryModalOpen}
              selectedCategoryId={selectedItem?.category}
              close={() => setIsCategoryModalOpen(false)}
              select={handleSelectCategory}
              isDarkMode={isDarkMode}
              categories={categories}
            />
          ) : (
            <div />
          )}

          {isItemModalOpen ? (
            <ItemModal
              isOpen={isItemModalOpen}
              item={selectedItem}
              close={() => setIsItemModalOpen(false)}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
      <EditDefaultItemsModal
        isOpen={isEditDefaultItem}
        close={() => setIsEditDefaultItem(false)}
      />
    </section>
  );
});

interface ToDoProps {
  widgetSize: number;
  todo: ItemModel[];
  todoAll: ItemModel[];
  isExpired: boolean;
  isSortMode: boolean;
  isDarkMode: boolean;
}

const ToDoSummary = memo((props: ToDoProps) => {
  const { widgetSize, todoAll, todo, isExpired, isSortMode, isDarkMode } =
    props;
  const history = useHistory();
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ItemModel>();
  // const { addedItemsToDo, allItemsToDo } = useItemSubscriptionToDo();
  const [selectedCategory, setSelectedCategoryx] = useState("");
  const [selectedCategoryName, setSelectedCategoryNamex] = useState("");
  const [selectedCategoryxx, setSelectedCategory] = useState("");
  const completedItems = todo.filter((i) => i.completed);
  const { batchRemoveItems } = useItemsToDo();
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);

  const inputRef = useRef<SelectRef>(null);
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };
  const { getPrivateToDoItems } = useUserContext();

  const handleSelectCategory = async (category: string) => {
    const itemsRef = await getPrivateToDoItems();
    const itemRef = doc(itemsRef, selectedItem?.id);
    await updateDoc(itemRef, {
      category,
    });
  };

  const handleClickItemCategory = (item: ItemModel) => {
    setSelectedItem(item);
    setIsCategoryModalOpen(true);
  };

  const handleClickAddCategoryItem = (category: string | undefined) => {
    setSelectedCategory(category ?? "");
    inputRef.current?.focus();
  };

  const handleClickItem = (item: ItemModel) => {
    setSelectedItem(item);
    setIsItemModalOpen(true);
  };
  const handleClearCompleted = async () => {
    const addedIds = completedItems.map((i) => i.id);
    await batchRemoveItems(addedIds);
    //console.log("clear completed")
  };

  useEffect(() => {
    return () => {};
  }, [selectedCategory]);

  //console.log("loading how many?");
  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lemonchiffon",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div style={{ cursor: "pointer" }}>
          {!isSortMode ? (
            <div
              onClick={() => routeChange("/todo")}
              style={{
                color: "black",
                backgroundColor: "gold",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <div>
            <h3
              style={{ textAlign: "center", textDecoration: "underline" }}
              onClick={() => routeChange("/todo")}
            >
              {t("header-menu-tasks")}
            </h3>
          </div>
        </div>
        {!isSortMode ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: -15,
            }}
          >
            {isAddingNewItem ? (
              <div style={{ width: "80%", marginTop: 2 }}>
                {" "}
                <AddItemToDoWidget
                  addedItemsToDo={todo}
                  allItemsToDo={todoAll}
                  categoryToDo={selectedCategory}
                  ref={inputRef}
                  setCategoryToDo={setSelectedCategory}
                  isDarkMode={isDarkMode}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {completedItems.length >= 1 ? (
                    <>
                      <button
                        onClick={handleClearCompleted}
                        style={{
                          width: 85,
                          //  height: 25,
                          //  marginTop: -5,
                          //   backgroundColor: "#3e98c7",
                          backgroundColor: "coral",
                          color: "white",
                          cursor: "pointer",
                          border: 0,
                          marginTop: 2,
                          borderRadius: 5,
                          marginRight: 0,
                          padding: 5,

                          fontFamily: "inherit",
                        }}
                      >
                        {t("common-button-clear")}
                      </button>
                      <span style={{ paddingLeft: 5 }} />
                    </>
                  ) : null}

                  <button
                    onClick={() => setIsAddingNewItem(false)}
                    style={{
                      width: 85,
                      //  height: 25,
                      //  marginTop: -5,
                      //   backgroundColor: "#3e98c7",
                      backgroundColor: "blue",
                      color: "white",
                      cursor: "pointer",
                      border: 0,
                      marginTop: 2,
                      borderRadius: 5,
                      marginRight: 0,
                      padding: 5,

                      fontFamily: "inherit",
                    }}
                  >
                    {t("common-button-hideinput")}
                  </button>
                </div>
              </div>
            ) : (
              <div style={{}}>
                {completedItems.length >= 1 ? (
                  <>
                    <button
                      onClick={handleClearCompleted}
                      style={{
                        width: 85,
                        //  height: 25,
                        //  marginTop: -5,
                        //   backgroundColor: "#3e98c7",
                        backgroundColor: "coral",
                        color: "white",
                        cursor: "pointer",
                        border: 0,
                        marginTop: 2,
                        borderRadius: 5,
                        marginRight: 0,
                        padding: 5,

                        fontFamily: "inherit",
                      }}
                    >
                      {t("common-button-clear")}
                    </button>
                    <span style={{ paddingLeft: 5 }} />
                  </>
                ) : null}
                <button
                  onClick={() => setIsAddingNewItem(true)}
                  style={{
                    width: 85,
                    //  height: 25,
                    //  marginTop: -5,
                    //   backgroundColor: "#3e98c7",
                    backgroundColor: "purple",
                    color: "white",
                    cursor: "pointer",
                    border: 0,
                    marginTop: 2,
                    borderRadius: 5,
                    marginRight: 0,
                    padding: 5,

                    fontFamily: "inherit",
                  }}
                >
                  +{t("common-button-add")}
                </button>
              </div>
            )}
          </div>
        ) : null}
        <div
          style={{
            height: widgetSize - 20,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <br />
          <CategoriesToDo
            addedItems={todo}
            showCompleted={true}
            showAllCat={false}
            onClickAddCategoryItem={handleClickAddCategoryItem}
            onClickItem={handleClickItem}
            onClickItemCategory={handleClickItemCategory}
            isDarkMode={isDarkMode}
          />
          <br />

          <br />
          <br />
          {isCategoryModalOpen ? (
            <CategoryModalToDo
              isOpen={isCategoryModalOpen}
              selectedCategoryId={selectedItem?.category}
              close={() => setIsCategoryModalOpen(false)}
              select={handleSelectCategory}
              isDarkMode={isDarkMode}
            />
          ) : (
            <div />
          )}

          {isItemModalOpen ? (
            <ItemModalToDo
              isOpen={isItemModalOpen}
              item={selectedItem}
              close={() => setIsItemModalOpen(false)}
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    </section>
  );
});

interface NotesProps {
  widgetSize: number;
  notes: NotesModel[];
  isExpired: boolean;
  isSortMode: boolean;
}

const NotesSummary = memo((props: NotesProps) => {
  const { notes, widgetSize, isExpired, isSortMode } = props;
  const [addNoteEditor, setAddNoteEditor] = useState(false);
  // console.log("what is notes notessummary:", notes);
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  const CloseEditor = () => setAddNoteEditor(false);
  //console.log("what is note summary isExpired", isExpired);

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lightsalmon",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div style={{ cursor: "pointer" }}>
          {!isSortMode ? (
            <div
              onClick={() => routeChange("/notes")}
              style={{
                color: "white",
                backgroundColor: "firebrick",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <h3
            style={{ textAlign: "center", textDecoration: "underline" }}
            onClick={() => routeChange("/notes")}
          >
            {t("header-menu-notes")}
          </h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: -15,
          }}
        >
          <button
            //  className={classes.blinkingButton}
            onClick={() => setAddNoteEditor(true)}
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              fontSize: 14,
              paddingTop: 5,
              paddingBottom: 5,
              borderRadius: 20,
              border: 0,
              color: "white",
              backgroundColor: "purple",
              cursor: "pointer",
              fontFamily: "inherit",
            }}
          >
            +{t("common-button-addnew")}
          </button>
        </div>

        <div
          style={{
            height: widgetSize,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          {/*//@ts-ignore}*/}
          <NotesWidget
            notes={notes}
            addNoteEditor={addNoteEditor}
            CloseEditor={CloseEditor}
          />
        </div>
      </div>
    </section>
  );
});

interface EventsProps {
  events: EventModel[];
  widgetSize: number;
  isExpired: boolean;
  isSortMode: boolean;
  isDarkMode: boolean;
  isOnline: boolean;
}

const EventsSummary = memo((props: EventsProps) => {
  const { events, widgetSize, isExpired, isSortMode, isDarkMode, isOnline } =
    props;
  const [isEventsModalAddNewOpen, setIsEventsModalAddNewOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const CloseAddNew = () => setIsEventsModalAddNewOpen(false);

  const routeChange = (path) => {
    history.push(path);
  };

  var howManyEvents = 0;

  //console.log("what is isExpire Journal Summary", isExpired);

  /*const getupcomingevents = () => {
    const today = new Date();

    events.forEach((e) => {
      const eventDate = e.start;
      if (e.end < today) {
        howManyEvents = howManyEvents + 1;
      }
      // console.log("events", howManyEvents)
    });
    return howManyEvents;
  };*/

  // getupcomingevents();

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "burlywood",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div>
          {!isSortMode ? (
            <div
              onClick={() => routeChange("/journal")}
              style={{
                color: "white",
                backgroundColor: "darkgoldenrod ",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                cursor: "pointer",
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <h3
            style={{
              textAlign: "center",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => routeChange("/journal")}
          >
            {t("header-menu-journal")}
          </h3>
          {!isSortMode ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: -15,
              }}
            >
              <button
                //  className={classes.blinkingButton}
                onClick={() => setIsEventsModalAddNewOpen(true)}
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  fontSize: 14,
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderRadius: 20,
                  border: 0,
                  color: "white",
                  backgroundColor: "purple",
                  cursor: "pointer",
                  fontFamily: "inherit",
                }}
              >
                +{t("common-button-addnew")}
              </button>
            </div>
          ) : null}
        </div>
        <div
          style={{
            height: widgetSize,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <JournalWidget
            isExpired={isExpired}
            isEventsModalAddNewOpen={isEventsModalAddNewOpen}
            CloseAddNew={CloseAddNew}
            isDarkMode={isDarkMode}
            isOnline={isOnline}
          />
        </div>
      </div>
    </section>
  );
});

interface RecipeSummaryProps {
  widgetSize: number;
  isSortMode: boolean;
  mealPlannerData: any[];
}

const RecipeSummary = memo((props: RecipeSummaryProps) => {
  const { widgetSize, isSortMode, mealPlannerData } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lightcyan",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div
          onClick={() => routeChange("/recipes")}
          style={{ cursor: "pointer" }}
        >
          {!isSortMode ? (
            <div
              style={{
                color: "white",
                backgroundColor: "darkblue",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
          <h3 style={{ textAlign: "center", textDecoration: "underline" }}>
            {t("header-menu-meal")}
          </h3>
        </div>
        <div
          style={{
            height: widgetSize,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <RecipeWidget mealPlannerData={mealPlannerData} />
        </div>
      </div>
    </section>
  );
});

const SortableListSummary = ({ user, widgetSize, isSortMode }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  //console.log("what is widgetsize", widgetSize);

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lightcyan",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div
          onClick={() => routeChange("/sortablelists")}
          style={{ cursor: "pointer" }}
        >
          {!isSortMode ? (
            <div
              style={{
                color: "white",
                backgroundColor: "darkblue",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}
              className={classes.openSectionButton}
            >
              {t("dashboard-fullscreen")}
            </div>
          ) : null}
        </div>
        <div
          style={{
            // height: widgetSize,
            marginTop: 0,
            // overflowY: "scroll",
          }}
        >
          <SortableListWidget
            user={user}
            height={widgetSize - 10}
            isExpired={false}
          />
        </div>
      </div>
    </section>
  );
};

interface WhatsNewProps {
  widgetSize: number;
}

const WhatsNew = (props: WhatsNewProps) => {
  const { widgetSize } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lavender",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div>
        <div
        //  onClick={() => routeChange("/recipes")}
        //  style={{ cursor: "pointer" }}
        >
          <h3 style={{ textAlign: "center" }}>
            {" "}
            <img
              style={{
                height: "35px",
                width: "35px",
                verticalAlign: "middle",
                cursor: "pointer",
                paddingRight: 10,
              }}
              src={lightbulbicon}
              alt="Hints"
            />
            {t("dashboard-edit-hints")}
          </h3>
        </div>
        <div
          style={{
            height: widgetSize,
            margin: 0,
            overflowY: "scroll",
          }}
        >
          <WhatsNewWidget />
        </div>
      </div>
    </section>
  );
};

const InviteFriend = () => {
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.section}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/settings")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={settingsicon}
                alt="Settings Menu"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={{ paddingBottom: 5 }}> Settings</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Help = () => {
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.section}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/contact")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={helpicon}
                alt="Help"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={{ paddingBottom: 5 }}> Help </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

interface FeedbackProps {
  widgetSize: number;
}

const Feedback = (props: FeedbackProps) => {
  const { widgetSize } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section
      // className={classes.section}
      style={{
        backgroundColor: "lavender",
        borderRadius: 10,
        padding: 5,
      }}
    >
      <div
        style={{
          height: widgetSize + 95,
        }}
      >
        <FeedbackWidget close={() => console.log("sent")} />
      </div>
    </section>
  );
};

//Dope Summary

interface DopeSummaryProps {
  widgetSize: number;
  habits: HabitModel[];
  recipes: RecipeModel[];
  //planCount: number;
  notes: NotesModel[];
  addedItems: ItemModel[];
  //  allItems: ItemModel[];
  todo: ItemModel[];
  // todoAll: ItemModel[];
  user: User;
  events: EventModel[];
  eventsPlanner: PlannerModel[];
  isDarkMode: boolean;
  stepCount: number;
  mealPlannerData: [];
  isOnline: boolean;
}

const DopeSummary = (props: DopeSummaryProps) => {
  const {
    widgetSize,
    recipes,
    habits,
    notes,
    addedItems,
    // allItems,
    todo,
    // todoAll,
    stepCount,
    user,
    events,
    isDarkMode,
    eventsPlanner,
    mealPlannerData,
    isOnline,
  } = props;
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  const convertDate = (date) => {
    var update;
    var today = new Date();
    var pastDue = false;

    if (date !== undefined) {
      update = new Date(date.seconds * 1000);
      update = moment(new Date(update)).format("MMMM DD, YYYY");
      var updateCompare = moment(new Date(update)).format("YYYYMMDD");
      var todayCompare = moment(new Date(today)).format("YYYYMMDD");
      pastDue = updateCompare > todayCompare ? false : true;

      return pastDue;
    } else {
      return null;
    }
  };

  const incompleteCount = addedItems.filter((item) => !item.completed).length;
  const incompleteCountTodo = todo.filter((item) => !item.completed).length;

  const dueDateTodo = todo.filter(
    (item) =>
      !item.completed && item.dueDate != undefined && convertDate(item.dueDate)
  ).length;
  const today = new Date();

  const convertDay = (data) => {
    if (data === "Sunday") {
      return t("planner-form-sunday");
    }
    if (data === "Monday") {
      return t("planner-form-monday");
    }
    if (data === "Tuesday") {
      return t("planner-form-tuesday");
    }
    if (data === "Wednesday") {
      return t("planner-form-wednesday");
    }
    if (data === "Thursday") {
      return t("planner-form-thursday");
    }
    if (data === "Friday") {
      return t("planner-form-friday");
    }
    if (data === "Saturday") {
      return t("planner-form-saturday");
    }
  };
  const dayOfWeek = today.toLocaleString("default", { weekday: "long" });

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);
  const [isFirstLaunchHelp, setIsFirstLaunchHelp] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if ((window as any).isAloha) {
      setIsAlohaPlanner(true);
    }

    return () => {};
  }, []);

  //console.log("what is recipes", recipes);

  return (
    <section
      // className={classes.section}
      style={{
        // backgroundColor: "lavender",
        borderRadius: 10,
        padding: 5,
        paddingBottom: 10,
        color: "black",
      }}
    >
      <div
        style={{
          //  height: widgetSize,
          margin: 0,
          overflowY: "scroll",
        }}
      >
        <DopeSummaryWidget
          itemCount={incompleteCount}
          notes={notes.length}
          todo={incompleteCountTodo}
          dueDate={dueDateTodo}
          events={events}
          //   recipes={recipes}
          habits={habits}
          user={user}
          eventsPlanner={eventsPlanner}
          //   mealPlannerData={mealPlannerData}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </div>
    </section>
  );
};

//Notification Widget

const Notification = () => {
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.section}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/planner")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={notificationicon}
                alt="Notification Reminder"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={{ paddingBottom: 5 }}> Reminders </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ScorePad = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <section className={classes.section}>
      <div style={styles.button}>
        <div
          style={styles.buttonContainer}
          onClick={() => routeChange("/scorehome")}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {" "}
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  verticalAlign: "middle",
                  cursor: "pointer",
                  paddingRight: 10,
                }}
                src={scoreIcon}
                alt="ScorePad"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                verticalAlign: "middle",
                alignContent: "center",
                alignSelf: "center",
              }}
            >
              <div style={{ paddingBottom: 5 }}> {t("header-menu-score")} </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
