import React from "react";
import { useHistory } from "react-router-dom"; // Removed unused import

export const RemoteButton = ({ label, desc, color, link }) => {
  const history = useHistory();
  const description = desc != undefined ? desc : "";
  const routeChange = (path) => {
    history.push(path);
  };

  // Return statement added to return JSX
  return (
    <div
      className="metroButton"
      style={{
        backgroundColor: color,
        color: color == "lime" ? "black" : "white",
        borderRadius: 10,
      }}
      onClick={() => routeChange("/" + link)}
    >
      {label}
      {description != "" ? (
        <div style={{ fontSize: ".6em", lineHeight: "1.2em" }}>
          {description}
        </div>
      ) : null}
    </div>
  );
};
