import React, { useState, useEffect, useRef } from "react";
import { PayPal } from "./PayPal";
import { PayPalSubscription } from "./PayPalSubscription";
import { useTranslation } from "react-i18next";
import { GuestUserRegister } from "Splash/RegisteredSplash/GuestUserRegister";

export const PayPalOptionButtons = ({ userId, userEmail, userPayPal }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const planId = useRef("");
  const [pricing, setPricing] = useState("");
  const { t } = useTranslation();
  const [isLoginForm, setIsLoginForm] = useState(false);

  //https://www.sandbox.paypal.com/billing/plans
  const testingSubIds = {
    month: "P-6Y935874S0369012WMXD56FQ",
    threeMonth: "P-1TU69688KV812235XMXF7J6I",
    year: "P-15E643735J710405WMXD54QY",
  };

  //need to make on PayPal
  //console.log("what is login", isLoginForm);

  const productionSubIds = {
    month: "P-3T1525995X5837412MXGTMKQ",
    threeMonth: "P-99D93875J92105802MXGTM6I",
    year: "P-0Y624132PP9347619MXGTOAI",
  };

  const options = [
    { id: "one-year", label: "1 Year" },
    { id: "lifetime", label: "Lifetime" },
  ];

  const selectOption = (id) => {
    setSelectedOption(id);
    //console.log("what is id", id);
    if (id == "one-month") {
      planId.current = productionSubIds.month;
      setPricing("$0.99");
    } else if (id == "three-months") {
      // console.log("hello");
      setPricing("$1.99");
      planId.current = productionSubIds.threeMonth;
    } else if (id == "one-year") {
      planId.current = productionSubIds.year;
      setPricing("$4.99");
    } else {
      setPricing("$14.99");
    }
  };

  useEffect(() => {
    //  console.log("what is payPal", userPayPal);
    if (userEmail === "" || undefined) {
      setIsLoginForm(true);
    }
    return () => {};
  }, []);

  return (
    <div>
      <label>Please Select</label>
      <div className="paypal-options-container">
        {options.map((option) => (
          <button
            key={option.id}
            className={`paypal-option-button ${
              selectedOption === option.id ? "selected" : ""
            }`}
            onClick={() => selectOption(option.id)}
          >
            {option.label}
          </button>
        ))}
      </div>
      {selectedOption != "" ? (
        selectedOption == "lifetime" ? (
          <div style={{ fontSize: 20 }}>
            <span>{t("buynow-modal-option5")}</span>
            <br />
            {t("common-button-buynow")}: {pricing}
            <div style={{ paddingTop: 20 }} />
            {isLoginForm ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingBottom: 20,
                  // maxWidth: 500,
                  // width: "85%",
                }}
              >
                <p style={{ color: "red" }}>{t("buynow-guest-toast")}</p>
                <br />
                <GuestUserRegister close={() => setIsLoginForm(true)} />
                <br />
              </div>
            ) : (
              <PayPal userId={userId} />
            )}
          </div>
        ) : (
          <div style={{ fontSize: 20 }}>
            {t("common-button-buynow")}: {pricing}
            <div style={{ paddingTop: 20 }} />
            {isLoginForm ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingBottom: 20,
                  // maxWidth: 500,
                  // width: "85%",
                }}
              >
                <p style={{ color: "red" }}>{t("buynow-guest-toast")}</p>
                <br />
                <GuestUserRegister close={() => setIsLoginForm(true)} />
                <br />
              </div>
            ) : (
              <PayPalSubscription userId={userId} planId={planId.current} />
            )}
          </div>
        )
      ) : null}
    </div>
  );
};
