import { useEffect } from "react";
import { ItemModel, useCategories } from "Lists";
import { useCategorySubscriptionToDo } from "../../../ListToDo/useCategorySubscriptionToDo";
import { Category } from "./Category";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import classes from "./Categories.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck, faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface CategoriesProps {
  addedItems: ItemModel[];
  showCompleted: boolean;
  showAllCat: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
  addNewItem?: () => void;
}

export const CategoriesToDo = (props: CategoriesProps) => {
  const {
    addedItems,
    showCompleted,
    showAllCat,
    isDarkMode,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    addNewItem,
  } = props;

  // const categories = useCategories()
  const { categoriesToDo } = useCategorySubscriptionToDo();

  const location = useLocation();
  const { t } = useTranslation();

  // Check if the current URL matches '/'
  const showNoResultsHelp = location.pathname === "/";

  const dueDateItems = addedItems.filter((i) => i.dueDate != undefined);

  //console.log("what is dueDate Items", dueDateItems);

  const uncategorisedItems = addedItems.filter(
    (i) =>
      !i.category || categoriesToDo.findIndex((c) => c.id === i.category) < 0
  );

  const groupedItems = [...categoriesToDo]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((c) => ({
      ...c,
      items: addedItems
        .filter((i) => i.category === c.id)
        .sort((a, b) => a.lowerName.localeCompare(b.lowerName)),
    }));

  if (uncategorisedItems.length > 0) {
    groupedItems.splice(0, 0, {
      id: "-1",
      name: t("generic-list-general"),
      order: -1,
      colour: "var(--colour-uncategorised)",
      items: uncategorisedItems.sort((a, b) =>
        a.lowerName.localeCompare(b.lowerName)
      ),
    });
  }

  //removing priority for now

  /*  if (dueDateItems.length > 0) {
    groupedItems.splice(0, 0, {
      id: "-2",
      name: "Priority Items",
      order: -1,
      colour: "#ffb6c1",
      items: dueDateItems.sort(
        (a, b) =>
          //@ts-ignore
          Math.floor(new Date(a.dueDate.seconds).getTime() / 1000) -
          //@ts-ignore
          Math.floor(new Date(b.dueDate.seconds).getTime() / 1000)
      ),
    });
  }*/

  if (!showCompleted) {
    groupedItems.forEach((c) => {
      c.items = c.items.filter((i) => !i.completed);
    });
  }

  return (
    <div className={classes.list}>
      {groupedItems.map((c) => (
        <Category
          key={c.id}
          //@ts-ignore
          category={c}
          showAllCat={showAllCat}
          showCompleted={showCompleted}
          onClickAddCategoryItem={onClickAddCategoryItem}
          onClickItem={onClickItem}
          onClickItemCategory={onClickItemCategory}
          isDarkMode={isDarkMode}
        />
      ))}
    </div>
  );
};
