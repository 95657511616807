//import { LinkSaver } from "LinkSaver/LinkSaver";
import { ChoreChart } from "ChoreChart/ChoreChart";
import { EncryptText } from "Cipher/EncryptText";
import { Hangman } from "Games/Hangman/Hangman";
import { Islandtraders } from "IslandTraders/IslandTraders";
import { UserExperienceRater } from "Layout/Header/UserExperienceRater";
import { LanguageSwitcher } from "Locales/LanguageSwitcher";
import { MapComponent } from "Map/MapComponent";
import { MealPlanner } from "Recipes/MealPlanner/MealPlanner";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const Test = ({ recipes }) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    //console.log("what is recipes", recipes);
    return () => {};
  }, []);

  return (
    <div style={{}}>
      <div className="App">
        <header className="App-header"></header>

        <main>
          <ChoreChart />
          {/*}  <Hangman />*/}
          {/*}  <MapComponent />*/}
          {/*  <Islandtraders />*/}
        </main>
        <div style={{ paddingBottom: 150 }} />
      </div>
    </div>
  );
};
