import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useUser, useUserContext } from "Authentication";
import { Spinner, Modal } from "Common";
//import Spinner from "react-bootstrap/Spinner";
import { createGroup } from "./CreateGroupForm.service";
import { PreloadNewTodo } from "ListToDo/PreloadNewTodo";

import classes from "./CreateGroupForm.module.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//import Button from "react-bootstrap/Button";

interface Form {
  name: string;
}

function clearAllTimers() {
  const allTimers = [];

  // Capture all active timers
  for (let i = 1; i < 1000; i++) {
    const timer = setTimeout(() => {}, i);
    allTimers.push(timer);
    //   console.log("what are they ", allTimers);
    clearTimeout(timer); // Clear the timeout immediately after capturing
  }

  // Clear all captured timers
  allTimers.forEach((timer) => clearTimeout(timer));
  //console.log("what are they after ", allTimers);
}

export const CreateGroupForm = () => {
  const [folderName, setFolderName] = useState("My Home");
  const { formState, handleSubmit, register } = useForm<Form>({
    defaultValues: {
      name: folderName,
    },
    mode: "onChange",
  });
  const { setUserGroup, refreshUser } = useUserContext();
  const user = useUser();
  const [inviteCode, setInviteCode] = useState("");
  const [isReloadButtonShown, setIsReloadButtonShown] = useState(false);
  const [isJoinActive, setIsJoinActive] = useState(false);
  const { t } = useTranslation();

  const checkInvitationCode = () => {
    var link = "/join/" + inviteCode;
    window.open(link);
  };

  if (!user) {
    throw new Error("Must be authenticated to use CreateGroupForm");
  }

  const { errors, isValid, isSubmitting } = formState;
  const [show, setShow] = useState(false);
  const [nickName, setUserNickName] = useState(user.name);
  const { setNickName } = useUserContext();

  var deviceOS = "web";

  if ((window as any).isNative) {
    if ((window as any).isIOS) {
      deviceOS = "iOS";
    } else {
      deviceOS = "Android";
    }
  }

  var app = "Dope Notes";

  if ((window as any).isAloha) {
    app = "Aloha Planner";
  }

  const {
    addItem,
    JournalExample,
    HabitExample,
    NoteExample,
    PlannerExample,
    addToDoCategoryDB,
    //LoadGroceryItems,
  } = PreloadNewTodo();

  const PreloadNewTodoJournal = async () => {
    addItem(t("example-tasks-start-journal"), "");
  };
  const PreloadNewTodoShopping = async () => {
    addItem(t("example-tasks-create-grocery"), "");
  };

  const PreloadNewTodoAIMagic = async () => {
    addItem(t("whatsnew-widget-item-6"), "");
  };

  async function handleCreateGroup(data: Form) {
    if (!user) {
      throw new Error(
        "Should not be able to create group if user is undefined"
      );
    }
    setShow(true);
    var today = new Date();
    setNickName(nickName, true, deviceOS, app);
    // console.log("what is data.name", folderName);
    const group = await createGroup(folderName, user.id, user.name);

    try {
      await setUserGroup(group);
      await PlannerExample();
      await PreloadNewTodoJournal();
      await PreloadNewTodoShopping();
      await PreloadNewTodoAIMagic();
      await JournalExample();
      await HabitExample();
      await NoteExample();
      await addToDoCategoryDB();
    } catch (error) {
      //@ts-ignore
      window.location.reload(true);
    }

    refreshUser();
  }

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  const setUpUser = async () => {
    setShow(true);
    // var today = new Date();

    //await setNickName(nickName, true, deviceOS, app);

    const group = await createGroup(folderName, user.id, user.name);

    try {
      await setUserGroup(group);
    } catch (error) {
      //@ts-ignore
      window.location.reload(true);
    }

    try {
      await preloadItems();
    } catch (error) {
      //@ts-ignore
      window.location.reload(true);
    }

    //@ts-ignore
    //  window.location.reload(true);

    //console.log("group finished");
    // await createSampleCateogories();
    //LoadGroceryItems();
  };

  const preloadItems = async () => {
    await PlannerExample();
    await PreloadNewTodoJournal();
    await PreloadNewTodoAIMagic();
    await JournalExample();
    await HabitExample();
    await NoteExample();
    await PreloadNewTodoShopping();
    await addToDoCategoryDB();
    // console.log("preload finished");
    refreshUser();
  };

  const tryAgain = () => {
    refreshUser();
    //@ts-ignore
    window.location.reload(true);
  };

  const enterInvitationCode = (e) => {
    setInviteCode(e.target.value);
    if (e.target.value.length > 4) {
      setIsJoinActive(true);
    } else {
      setIsJoinActive(false);
    }
  };

  //GroupType and addItem should be updated together if changed.
  const [addItems, setAddItems] = useState(true);
  const [groupType, setGroupType] = useState("category");

  const handleChangeItemsCheckbox = () => {
    if (addItems === true) {
      setAddItems(false);
    } else {
      setAddItems(true);
    }
  };

  const handleSelectGroupType = (e) => {
    setGroupType(e.target.value);
    if (e.target.value === "category") {
      setAddItems(true);
    } else {
      setAddItems(false);
    }
  };

  useEffect(() => {
    {
      /*const timer = setTimeout(() => {
      // console.log("fired setup");
      try {
        //  setUpUser();
      } catch (error) {
        //@ts-ignore
        window.location.reload(true);
      }
    }, 3000);*/
    }

    return () => {
      //  clearTimeout(timer);
      // clearAllTimers();
    };
  }, []);

  return (
    <>
      {isSubmitting ? (
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <span />
      )}
      <div
        style={{
          display: "flex",
        }}
      >
        <form
          onSubmit={handleSubmit(handleCreateGroup)}
          style={{ padding: 10, fontFamily: "Kalam" }}
        >
          <div
            className={classes.formGroup}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 50,
            }}
          >
            <label style={{ fontSize: 20, paddingBottom: 10 }}>
              {t("login-registration-folder")}
            </label>
            <input
              style={{ fontSize: 20, padding: 10, width: "90%" }}
              id="name"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              required
              minLength={1}
              placeholder="e.g. My Home"
            />
            <div>{errors?.name ? errors.name.message : null}</div>
          </div>

          {/*  <div
            style={{ paddingTop: 30, display: "flex", flexDirection: "column" }}
          >
            <label style={{ fontSize: 20 }}>Select Shopping List Type:</label>
            <select
              value={groupType}
              onChange={(e) => handleSelectGroupType(e)}
              style={{ fontSize: 18, marginTop: 10, padding: 10, width: 200 }}
            >
              <option value="category">Preload Grocery Items (default)</option>
              <option value="store">Manual</option>
            </select>
            <p
              style={{
                paddingTop: 0,
                fontSize: 16,
                color: "black",
                fontStyle: "italic",
                //fontWeight: "bold",
                backgroundColor: "lightyellow",
              }}
            >
              {groupType === "category" ? (
                <span>
                  We will pre-load popular grocery items within their respective
                  grocery store categories, such as placing "Milk" in the
                  "Dairy" list.
                </span>
              ) : (
                <span>
                  You will create your own items and categories such as "Milk"
                  in the "Walmart" list.
                </span>
              )}
            </p>
              </div> */}

          {/*}    <div style={{ paddingLeft: 10, paddingTop: 20, lineHeight: 1.5 }}>
            <input
              type="checkbox"
              checked={addItems}
              onChange={() => {
                handleChangeItemsCheckbox();
              }}
              //  className={classes.details}
            />
            <span style={{ paddingLeft: 10 }}>
              Add common items like bread, milk, eggs
            </span>
            </div>*/}

          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
              paddingTop: 0,
            }}
          >
            <button
              type="submit"
              // disabled={!isValid || isSubmitting}
              className={classes.submit}
              style={{
                fontSize: 20,
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 30,
              }}
            >
              Continue
            </button>
          </div>
          {/*}  <p
            style={{
              paddingTop: 0,
              fontSize: 16,
              color: "black",
              //  fontStyle: "italic",
              //  fontWeight: "bold",
            }}
          >
            If you change your mind, don't worry! You can always select the
            "Edit Shopping List Settings" later to make any adjustments.
          </p>*/}
        </form>

        {/*}    <form onSubmit={checkInvitationCode}>
          <div style={{paddingTop:40}} className={classes.formGroup}>
            <label htmlFor="name">Invitation Code</label>
            <input
              id="name"
              style={{fontSize: 18, padding: 10}}
              minLength={6}
              required
              onChange={enterInvitationCode}
            />
         
          </div>
          <button
            type="submit"
          style={{fontSize: 18}}
            className={classes.submit}
            disabled={!isJoinActive}
          >
            Join
          </button>
  </form> */}

        <Modal isOpen={show} style={{ overlay: { zIndex: 100 } }}>
          <div
            style={{
              marginTop: 50,
              marginBottom: 350,
              paddingLeft: 20,
              paddingRight: 20,
              fontFamily: "Kalam",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Aloha! &#127796;</h2>
            <p style={{ textAlign: "center", fontSize: 20 }}>
              {" "}
              {t("register-loader-setup")}
            </p>

            <div
              style={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
            {isReloadButtonShown ? (
              <p
                onClick={tryAgain}
                style={{
                  color: "blue",
                  textAlign: "center",
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingTop: 20,
                }}
              >
                Slow Connection. Please Tap Here To Reload.
              </p>
            ) : null}
          </div>
        </Modal>
      </div>
    </>
  );
};
