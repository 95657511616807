import { useEffect, useState, useRef } from "react";
//import { useUserSubscription } from "./useUserSubscription";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DiApple, DiAndroid } from "react-icons/di";
import { Modal } from "Common";
import { Link, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AppUpdateNoticeModal } from "./AppUpdateNoticeModal";
import { useTranslation } from "react-i18next";
import { GuestUserRegister } from "Splash/RegisteredSplash/GuestUserRegister";
import { QuoteBox } from "Splash/AnonymousSplash/QuoteBox";
import { useUserContext } from "./UserContext";
import { Spinner } from "Common";

import { PayPalOptionButtons } from "./PayPalOptionButtons";
//import emailjs from '@emailjs/browser';

export const BuyNowModal = ({ isBuyNow, close, user, isOnline, isExpired }) => {
  //  const [isBuyNow, setIsBuyNow] = useState(false);
  //const userProfile = useUserSubscription();

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [appVersion, setAppVersion] = useState();
  const [isUpdateNotice, setIsUpdateNotice] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;
  var isIOSDevice = isMobile && isIOS ? true : false;
  const { addPromoCode, refreshUser } = useUserContext();
  const { t } = useTranslation();

  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const closePayPalLogin = () => {};

  const handlePromoCode = async () => {
    //@ts-ignore
    //  const promoCode = user.promocode;
    const userId = user.id;
    setIsLoading(true);
    // await setPromoCode(promoCode.toLowerCase());
    const lowerCase = promoCode.toLowerCase();
    // await setPromoCode(lowerCase);
    //  console.log("what is promoCodeLowerCase", promoCode);

    try {
      const response = await fetch(
        "https://us-central1-dopenotes-20ee4.cloudfunctions.net/addUserPromoCode",

        //"http://127.0.0.1:5001/dopenotes-20ee4/us-central1/addUserPromoCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ promoCode: lowerCase, userID: userId }),
        }
      );

      if (!response.ok) {
        //   console.log("did I fire");
        throw new Error("Network response was not ok");
      }

      const isValid = await response.json();
      //  setPromoCodeStatus(isValid ? 'Valid' : 'Invalid');
      // setIsValidPromoCode(isValid);
      //console.log("what is valid:", isValid);
      if (isValid) {
        // return true;
        await addPromoCode(lowerCase);
        setIsLoading(false);

        await toast(t("Promo Code Added"), {
          duration: 2000,
          position: "top-center",
          style: {
            backgroundColor: "lightgreen",
            border: "1px solid #713200",
            fontSize: "20px",
            padding: "10px",
            color: "black",
          },
        });
        setTimeout(() => {
          window.location.reload(true);
        }, 2500);

        // close();
      } else {
        //return false;
        setIsLoading(false);
        alert("Promo code not valid/expired.  Please try again.");
      }
    } catch (error) {
      //  console.error("There was a problem with the fetch operation:", error);
      //  console.log("Error checking promo code", error);
      // return false;
    }
  };

  const [unlockClose, setUnlockClose] = useState(false);

  useEffect(() => {
    var getAppVersion =
      window.isAppVersion != undefined ? parseFloat(window.isAppVersion) : 1;
    setAppVersion(getAppVersion);
    //alert("what is it?" + getAppVersion);
    CheckPlatform();
    // console.log("what is the user id", user.id);

    return () => {};
  }, []);

  // console.log("subDate", subscription)
  //  console.log("today", today)

  const closeUpdateNotice = () => {
    setIsUpdateNotice(false);
  };

  const [isLoginForm, setIsLoginForm] = useState(false);

  const BuyButtonYear = () => {
    if (isExpired) {
      setUnlockClose(true);
    }
    if (user.email === "" || undefined) {
      setIsLoginForm(true);
    } else {
      if (window.isNative) {
        // Post the message back to expo
        if (appVersion > 2.39) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "BuyButtonSelectedYear", userid: user.id })
          );
        } else {
          setIsUpdateNotice(true);
        }
      } else {
        //console.log("clicked")
        toast(t("buynow-modal-toast-warning"), {
          duration: 4000,
          position: "top-center",
          style: {
            backgroundColor: "yellow",
            border: "1px solid #713200",
            fontSize: "20px",
            padding: "10px",
            color: "black",
          },
        });
      }
    }
  };

  return (
    <div style={{ marginTop: 0 }}>
      <Modal isOpen={isBuyNow} style={{ overlay: { zIndex: 20 } }}>
        <Toaster containerStyle={{ marginTop: 225 }} />
        <Modal.Body>
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginTop: -50,
              marginRight: 10,
              paddingBottom: 30,
            }}
          >
            <FontAwesomeIcon
              icon={faClose}
              size="2x"
              onClick={() => close()}
              style={{ marginTop: 15, cursor: "pointer" }}
            />
          </div>
          <Modal.Header>
            {/*isExpired ? (
              <p
                style={{
                  fontSize: 24,
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  color: "red",
                  textAlign: "center",
                  marginTop: -10,
                  paddingBottom: 10,
                }}
              >
                {t("buynow-modal-warning")}
              </p>
              ) : null */}

            <p
              style={{
                fontSize: 24,
                //marginLeft: 20,
                fontFamily: "inherit",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: -10,
              }}
            >
              Spread the Aloha by Supporting Us
            </p>
          </Modal.Header>
          <div
            style={{
              textAlign: "center",
              color: "black",
              paddingBottom: 150,
              paddingRight: 20,
            }}
          >
            <>
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                  userSelect: "none",
                }}
              >
                {" "}
                {/*}  <br />
                <br />
                While there are alternative free services out there, it's
                essential to recognize that when a product is provided at no
                cost, you, as the user, often end up being the product. This
                isn't our approach. We opt for a more transparent and
              traditional business model.*/}
                Disable Nag Screen
                <br />
                Unlimited AI Access
                <br />
                Multi-device accessibility
                <br />
                Unlimited data storage
                <br />
                <br />
                Should you ever feel dissatisfied, you're welcome to cancel at
                any time.
                <br />
                <br />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: 0,
                }}
              >
                <div style={{ marginTop: 0, paddingLeft: 2 }}>
                  <ul style={{ textAlign: "center" }}>
                    <span>{t("buynow-modal-option1")} - $0.99 </span>
                    <br />
                    {/*}    <span>{t("buynow-modal-option2")} </span>
                              <br />
                              <span>
                                {t("buynow-modal-option3")} - $4.99USD{" "}
                              </span>
                              <br />*/}
                    <span>{t("buynow-modal-option4")} - $4.99</span>
                    <br />
                    or
                    <br />
                    <span> {t("buynow-modal-option5")} - $14.99</span>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 0,
                    marginBottom: 0,
                    flexDirection: "column",
                  }}
                >
                  <hr />
                  {isOnline ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      {isLoginForm ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            paddingBottom: 20,

                            // maxWidth: 500,
                            // width: "85%",
                          }}
                        >
                          <p
                            style={{ color: "red", padding: 10, fontSize: 18 }}
                          >
                            {t("buynow-guest-toast")}
                          </p>
                          <br />
                          <GuestUserRegister
                            close={() => setIsLoginForm(false)}
                          />
                          <br />
                        </div>
                      ) : isMobile ? (
                        <div>
                          <p
                            style={{
                              marginTop: -10,

                              fontSize: 20,
                              paddingLeft: 2,
                              fontWeight: "bold",
                              color: "firebrick",
                              textDecorationLine: "underline",
                            }}
                          >
                            Subscription Options
                          </p>

                          <div
                            onClick={BuyButtonYear}
                            // className="blinkingButton"
                            className={`paypal-option-button`}
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              backgroundColor: "blue",
                              padding: 20,
                              marginTop: 30,
                            }}
                          >
                            Buy Now
                          </div>
                        </div>
                      ) : (
                        //   <PayPal userId={user} />
                        <div>
                          <PayPalOptionButtons
                            userId={user.id}
                            userEmail={user.email}
                            userPayPal={
                              user.payPal != undefined ? user.payPal : "12"
                            }
                          />
                          <h3>
                            You may also purchase using our IOS or Android app
                          </h3>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingBottom: 20,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h3>{t("settings-app-downloads")}</h3>
                            <Link
                              to={{
                                pathname:
                                  "https://apps.apple.com/us/app/dope-notes-life-organizer/id1631683070",
                              }}
                              target="_blank"
                            >
                              {" "}
                              <div
                                style={{
                                  padding: 10,
                                  fontSize: 16,
                                  marginTop: 0,
                                  color: "white",
                                  borderRadius: 10,
                                  textAlign: "center",
                                  backgroundColor: "blue",
                                  fontWeight: "bold",
                                  width: 200,
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <DiApple /> Apple IOS{" "}
                              </div>
                            </Link>
                            <Link
                              to={{
                                pathname:
                                  "https://play.google.com/store/apps/details?id=com.holmgren.dopenotes",
                              }}
                              target="_blank"
                            >
                              {" "}
                              <div
                                style={{
                                  padding: 10,
                                  fontSize: 16,
                                  marginTop: 20,
                                  color: "white",
                                  borderRadius: 10,
                                  textAlign: "center",
                                  backgroundColor: "blue",
                                  fontWeight: "bold",
                                  width: 200,
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <DiAndroid /> Google Android
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      //  onClick={BuyButtonYear}
                      style={{
                        padding: 10,
                        marginTop: 10,
                        color: "white",
                        borderRadius: 10,
                        textAlign: "center",
                        backgroundColor: "blue",
                        width: 200,
                        cursor: "pointer",
                      }}
                    >
                      {t("buynow-modal-buypricing-warning")}
                    </div>
                  )}
                  {isPromoCode ? (
                    user.email != "" || undefined ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: 20,
                        }}
                      >
                        <label>Enter Promo Code</label>
                        <input
                          type="text"
                          placeholder=""
                          value={promoCode}
                          style={{
                            padding: 10,
                            fontSize: "inherit",
                            fontFamily: "inherit",
                          }}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <div style={{ paddingTop: 10 }} />
                        {!isLoading ? (
                          <button
                            onClick={handlePromoCode}
                            style={{
                              fontSize: 16,
                              color: "white",
                              backgroundColor: "blue",
                              borderColor: "transparent",
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 5,
                              paddingBottom: 5,
                              borderRadius: 5,
                            }}
                          >
                            {t("common-button-add")}
                          </button>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner />
                          </div>
                        )}
                        <div style={{ paddingTop: 10 }} />
                        <button
                          onClick={() => setIsPromoCode(false)}
                          style={{
                            fontSize: 16,
                            color: "blue",
                            backgroundColor: "transparent",
                            textDecoration: "underline",
                            borderColor: "transparent",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderRadius: 5,
                          }}
                        >
                          {t("common-button-close")}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <p style={{ color: "red", padding: 10, fontSize: 18 }}>
                          {t("buynow-guest-toast")}
                        </p>
                        <GuestUserRegister
                          close={() => setIsPromoCode(false)}
                        />
                      </div>
                    )
                  ) : !isMobile ? (
                    <p
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsPromoCode(true)}
                    >
                      Enter Promo Code
                    </p>
                  ) : null}
                  {isUpdateNotice ? (
                    <AppUpdateNoticeModal
                      isUpdateNotice={isUpdateNotice}
                      close={closeUpdateNotice}
                    />
                  ) : null}
                  <p>
                    {isIOSDevice ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link
                          to={{
                            pathname:
                              "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/",
                          }}
                          target="_blank"
                          style={{ color: "lightblue" }}
                        >
                          <button
                            style={{
                              fontSize: "inherit",
                              fontFamily: "inherit",
                              color: "lightblue",
                              backgroundColor: "black",
                              borderColor: "white",
                              padding: 10,
                              borderRadius: 10,
                              paddingRight: 15,
                            }}
                          >
                            <DiApple /> {t("buynow-modal-terms")}
                          </button>
                        </Link>
                        <Link
                          to={{
                            pathname:
                              "https://apps.apple.com/account/subscriptions",
                          }}
                          target="_blank"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            padding: 10,
                            font: 12,
                          }}
                        >
                          <span>{t("buynow-subscription-management")}</span>
                        </Link>
                      </div>
                    ) : null}

                    {isAndroid ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link
                          to={{
                            pathname:
                              "https://play.google.com/intl/en_us/about/play-terms/index.html",
                          }}
                          target="_blank"
                          style={{ color: "lightblue" }}
                        >
                          <button
                            style={{
                              fontSize: 14,
                              fontFamily: "inherit",
                              color: "lightblue",
                              backgroundColor: "black",
                              borderColor: "white",
                              padding: 10,
                              borderRadius: 10,
                              paddingRight: 15,
                            }}
                          >
                            <DiAndroid /> {t("buynow-modal-googleterms")}
                          </button>
                        </Link>
                        <Link
                          to={{
                            pathname:
                              "https://play.google.com/store/account/subscriptions",
                          }}
                          target="_blank"
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            padding: 10,
                            font: 12,
                          }}
                        >
                          <span>{t("buynow-subscription-management")}</span>
                        </Link>
                      </div>
                    ) : null}
                  </p>
                </div>
                {/*}   <p
                  style={{
                    fontSize: 20,
                    paddingLeft: 2,
                    fontWeight: "bold",
                    color: "firebrick",
                    //   textDecorationLine: "underline",
                  }}
                >
                  {t("buynow-lifetime-sale-2024")}
                </p>*/}
                <div
                  style={{ fontSize: 20, paddingTop: 30, cursor: "pointer" }}
                >
                  {t("buynow-modal-questions")}{" "}
                  <Link
                    to={{ pathname: "mailto:support@alohaplanner.app" }}
                    target="_blank"
                  >
                    <span
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      support@alohaplanner.app
                    </span>
                    .
                  </Link>
                </div>
              </div>
            </>

            <div style={{ paddingBottom: 20 }} />
            <button
              style={{
                marginTop: 20,
                borderRadius: 10,
                color: "blue",
                border: 1,
                borderStyle: "dashed",
                padding: 10,
                width: 175,
                textAlign: "center",
                cursor: "pointer",
                fontSize: 18,
                fontFamily: "inherit",
              }}
              onClick={() => close()}
            >
              {t("common-button-close")}
            </button>

            <div style={{ paddingBottom: 150 }} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
