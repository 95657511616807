import React, {
  useState,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
} from "react";
import {
  faEnvelope,
  faAdd,
  faBackwardStep,
  faClose,
  faPen,
  faPenToSquare,
  faPlus,
  faPlusCircle,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Modal } from "Common";
import { ItemModel } from "Lists";

import { ListItem } from "../../ListItem";
import { CategoryEditModal } from "../../CategoryModal/CategoryEditModal";
import classes from "./Category.module.css";
import { useItemSubscription } from "Lists";
import { SelectRef } from "../../AddItem";
import { AddItemCat } from "Lists/List/AddItem/AddItemCat";
//import { AddItemToDo } from "ListToDo/List/AddItem";
import { useTranslation } from "react-i18next";

export interface CategoryItems {
  items: ItemModel[];
  id?: string | undefined;
  name: string;
  colour: string;
  order: number;
}

export interface CategoryProps {
  category: CategoryItems;
  showAllCat: boolean;
  onClickAddCategoryItem: (id: string | undefined) => void;
  onClickItem: (item: ItemModel) => void;
  onClickItemCategory: (item: ItemModel) => void;
  isDarkMode: boolean;
  categories: any[];
}

export const Category = (props: CategoryProps) => {
  const {
    category,
    onClickAddCategoryItem,
    onClickItem,
    onClickItemCategory,
    isDarkMode,
    showAllCat,
    categories,
  } = props;
  const { id, colour, items, name } = category;
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [selectedCategory, setSelectedCategoryx] = useState(id);
  const [selectedCategoryName, setSelectedCategoryNamex] = useState(name);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [selectedCategoryxx, setSelectedCategory] = useState(id);
  const [selectedCategoryNamexx, setSelectedCategoryName] = useState(name);
  const [launchModal, setLaunchModal] = useState(false);
  const inputRef = useRef<SelectRef>(null);
  const { addedItems, allItems } = useItemSubscription();
  const [isAdding, setIsAdding] = useState(false);
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [isOpenItems, setIsOpenItems] = useState(
    localStorage.getItem(`iscattodo-${name}`) != undefined
      ? JSON.parse(localStorage.getItem(`iscattodo-${name}`))
      : true
  );
  const { t } = useTranslation();
  //android/ios check

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if ((window as any).isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const compileListSMS = (items, name) => {
    // Ensure the input is valid
    if (!Array.isArray(items) || items.length === 0) {
      console.error("Invalid or empty items list");
      return;
    }

    // Filter the items to include only those that are not completed
    const uncompletedItems = items.filter((item) => !item.completed);

    // Construct the SMS body
    const smsBody = `My list ${name}\n${uncompletedItems
      .map((item) => `- ${item.name}`)
      .join("\n")}`;

    // Generate the SMS URL
    var smsUrl = `sms:?body=${encodeURIComponent(smsBody)}`;

    if (isAndroid) {
      window.open(smsUrl, "_blank");
    } else {
      window.location.href = smsUrl;
    }
  };

  const compileListEmail = (items, name) => {
    // console.log("What is it", items);
    // console.log("map it", items.map((item) => `- ${item.name}`).join("%0A"));
    // Ensure the input is valid
    if (!Array.isArray(items) || items.length === 0) {
      console.error("Invalid or empty items list");
      return;
    }

    // Filter the items to include only those that are not completed
    const uncompletedItems = items.filter((item) => !item.completed);
    // Update the mailto link
    var url = `mailto:?subject=My%20List%20${name}&body=My%20list%20${name}%0A${uncompletedItems
      .map((item) => `- ${item.name}`)
      .join("%0A")}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    CheckPlatform();
    return () => {};
  }, []);

  //console.log("item count", items.length)

  const hanldeShowItems = () => {
    // console.log("click");
    if (isOpenItems == true) {
      setIsOpenItems(false);
      localStorage.setItem(`iscattodo-${name}`, "false");
    } else {
      setIsOpenItems(true);
      localStorage.setItem(`iscattodo-${name}`, "true");
    }
  };

  function adjustBrightness(color, amount) {
    if (color[0] === "#") {
      color = color.slice(1);
    }

    const num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00ff) + amount;
    let b = (num & 0x0000ff) + amount;

    r = Math.max(Math.min(255, r), 0);
    g = Math.max(Math.min(255, g), 0);
    b = Math.max(Math.min(255, b), 0);

    // Return an rgba color with the adjusted color and a fixed alpha value (40%)
    return `rgba(${r}, ${g}, ${b}, 0.4)`; // 0.4 for 40% opacity
  }

  return (
    <>
      {items.length >= 1 || showAllCat ? (
        <div
          className={classes.category}
          style={{ backgroundColor: `${colour}50` }}
        >
          <div style={{ paddingBottom: 10 }}>
            <div
              className={classes.categoryHeader}
              style={{ paddingBottom: 0 }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setLaunchModal(true)}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "dimgray",
                    paddingLeft: 5,
                  }}
                >
                  {name.toLocaleUpperCase()}{" "}
                </span>
              </div>
            </div>
          </div>
          {isAddingNewItem ? (
            <Modal
              isOpen={isAddingNewItem}
              onRequestClose={() => setIsAddingNewItem(false)}
              style={{ overlay: { zIndex: 100 } }}
              //background-color: rgba(255, 255, 255, 0.5); /* Set the desired overlay color and opacity */
            >
              <div style={{ filter: "invert(100%)" }}>
                <AddItemCat
                  addedItems={addedItems}
                  allItems={allItems}
                  category={selectedCategory}
                  categoryName={selectedCategoryName}
                  ref={inputRef}
                  setCategory={setSelectedCategory}
                  setCategoryName={setSelectedCategoryName}
                  isDarkMode={isDarkMode}
                  setIsAddingNewItemCompleted={() => setIsAddingNewItem(false)}
                  categories={categories}
                />
              </div>
            </Modal>
          ) : null}

          {items.length > 0 ? (
            <div
              onClick={hanldeShowItems}
              style={{
                fontSize: 16,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  paddingLeft: 5,
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                {!isOpenItems ? "Show" : "Hide"} {items.length} Item(s)
              </span>

              {/*  <span style={{ fontSize: 14, paddingRight: 5, paddingLeft: 5 }}>
              {" "}
              Total: {items.length}
            </span>*/}
              <span
                onClick={() => setIsAddingNewItem(true)}
                style={{
                  paddingLeft: 5,
                  paddingRight: 5,
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                  fontSize: 16,
                }}
              >
                {t("common-button-addnew")}
              </span>
            </div>
          ) : (
            <div
              onClick={() => setIsAddingNewItem(true)}
              style={{
                textAlign: "center",
                paddingLeft: 5,
                paddingRight: 5,
                textDecoration: "underline",
                fontWeight: "bold",
                color: "blue",
                cursor: "pointer",
                fontSize: 16,
              }}
            >
              Add Your First Item
            </div>
          )}

          {isOpenItems ? (
            <div style={{ marginTop: 5 }}>
              <div className={classes.items}>
                {items.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      borderStyle: "dashed !important",
                      border: 1,
                      borderColor: "rgba(128, 128, 128, 0.6)",
                      backgroundColor: colour
                        ? adjustBrightness(
                            colour,
                            index % 2 === 0 ? 10 * 15 : 20 * 15
                          )
                        : "#fffff", // Default to "white" if colour is undefined
                      padding: 10,
                    }}
                  >
                    <ListItem
                      key={item.id}
                      item={item}
                      category={category}
                      onClickCategory={() => onClickItemCategory(item)}
                      onClickItem={() => onClickItem(item)}
                    />
                  </div>
                ))}
              </div>
              {items.length > 0 ? (
                <div
                  onClick={hanldeShowItems}
                  style={{
                    textAlign: "center",
                    paddingTop: 10,

                    textDecoration: "underline",
                    userSelect: "none",
                    cursor: "pointer",
                    color: "blue",
                    fontSize: 16,
                  }}
                >
                  Hide {items.length} Item(s)
                </div>
              ) : null}
            </div>
          ) : null}
          <Modal
            isOpen={launchModal}
            onRequestClose={() => setLaunchModal(false)}
            style={{
              overlay: { zIndex: 99 },
            }}
          >
            <div style={{}}>
              <h2
                style={{
                  fontWeight: "bold",
                  // color: "white",
                  paddingLeft: 5,
                }}
              >
                {name.toLocaleUpperCase()}{" "}
              </h2>
              {items.length > 0 ? (
                <div className={classes.items}>
                  {items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        borderStyle: "dashed !important",
                        border: 1,
                        borderColor: "rgba(128, 128, 128, 0.6)",
                        backgroundColor: colour
                          ? adjustBrightness(
                              colour,
                              index % 2 === 0 ? 10 * 15 : 20 * 15
                            )
                          : "#fffff", // Default to "white" if colour is undefined
                        padding: 10,
                      }}
                    >
                      <ListItem
                        key={item.id}
                        item={item}
                        category={category}
                        onClickCategory={() => onClickItemCategory(item)}
                        onClickItem={() => onClickItem(item)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div style={{ textAlign: "center", color: "red" }}>
                  <h2>No Items Found</h2>
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  paddingBottom: 150,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => setIsAddingNewItem(true)}
                >
                  <FontAwesomeIcon icon={faAdd} />
                  <span style={{ paddingLeft: 5 }}> Add Item(s)</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => compileListEmail(items, name)}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span style={{ paddingLeft: 5 }}> Email List</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => compileListSMS(items, name)}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span style={{ paddingLeft: 5 }}> SMS List</span>
                </div>
                <div
                  className="metroButton"
                  style={{
                    borderRadius: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                  onClick={() => setLaunchModal(false)}
                >
                  Back
                </div>
              </div>
            </div>
          </Modal>
          {isCatEditModalOpen ? (
            <CategoryEditModal
              isOpen={isCatEditModalOpen}
              close={() => setIsCatEditModalOpen(false)}
              isDarkMode={isDarkMode}
              categories={categories}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};
