import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faEnvelope,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { singular } from "pluralize";

import { Button, FormGroup, Modal } from "Common";
import { ItemModel, useItems } from "Lists";
import { RecipeModel } from "Recipes";

import { Ingredient } from "./Ingredient";
import { Days } from "./Days";

import classes from "./ReadRecipe.module.css";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";

interface IngredientViewModel {
  name: string;
  measurement: string;
  added: boolean;
  toggle: () => Promise<void>;
}

interface ReadRecipeProps {
  recipe: RecipeModel;
  addedItems: ItemModel[];
  close: () => void;
  edit: () => void;
}

export const ReadRecipe = (props: ReadRecipeProps) => {
  const { recipe, addedItems, close, edit } = props;
  const { addItem, removeItem } = useItems();

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if ((window as any).isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  useEffect(() => {
    CheckPlatform();

    return () => {};
  }, []);

  const sanitisedIngredients = (recipe.ingredients ?? []).map((i) => ({
    sanitisedName: singular(i.name.toLowerCase()),
    name: i.name,
    measurement: i.measurement,
  }));
  const viewIngredients: IngredientViewModel[] = sanitisedIngredients.map(
    (i) => {
      const addedItem = addedItems.find(
        (ai) => ai.lowerName === i.sanitisedName
      );
      const added = !!addedItem;
      return {
        name: i.name,
        measurement: i.measurement,
        added,
        toggle: addedItem
          ? () => removeItem(addedItem.id)
          : () => addItem(i.name, i.measurement),
      };
    }
  );

  const removeTags = (str) => {
    if (typeof str === "string") {
      return str.replace(/<[^>]+>/g, "");
    }
    return str;
  };

  const emailRecipe = () => {
    var url = `mailto:?subject=My%20recipe%20for%20${
      recipe.name
    }&body=%20Recipe%20for%20${recipe.name}:%0A%0A${recipe.ingredients
      .map((i) => i.measurement + "%20" + i.name + "%20%0A")
      .join("")}%0A${removeTags(recipe.directions)}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const launchRecipeSite = () => {
    // window.location.href = recipe.recipeUrl

    if (isAndroid) {
      window.open(recipe.recipeUrl, "_blank");
    } else {
      window.location.href = recipe.recipeUrl;
    }
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Modal.Header></Modal.Header>
      </div>
      <div
        style={{
          marginTop: 20,
          paddingBottom: 0,
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            marginRight: 10,
            cursor: "pointer",
          }}
          title="Share"
          onClick={() => emailRecipe()}
          className={classes.editButton}
        >
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            cursor: "pointer",
          }}
          title="Edit"
          onClick={edit}
          className={classes.editButton}
        >
          <FontAwesomeIcon icon={faEdit} size="2x" />
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            cursor: "pointer",
          }}
          title="close"
          onClick={close}
          className={classes.editButton}
        >
          <FontAwesomeIcon
            icon={faClose}
            size="3x"
            style={{ paddingLeft: 15, cursor: "pointer" }}
          />
        </button>
      </div>
      <h2 style={{ fontSize: 30, color: "navy", paddingTop: 5 }}>
        {recipe.name}
      </h2>
      <label style={{ paddingBottom: 20, paddingTop: 0 }}>
        Select day(s) for meal planner
      </label>
      <Days id={recipe.id} recipeDays={recipe.days ?? []} />
      {recipe.recipeUrl ? (
        <FormGroup>
          <label style={{ paddingTop: 10, paddingBottom: 10 }}>
            Recipe URL
          </label>
          <p
            style={{ color: "blue", cursor: "pointer" }}
            onClick={launchRecipeSite}
          >
            Click here to visit
          </p>
        </FormGroup>
      ) : null}
      <FormGroup>
        <div style={{ paddingTop: 0, paddingBottom: 10 }}>
          <label style={{ paddingBottom: 5, paddingTop: 10 }}>
            Directions/Notes
          </label>
          <div
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              //     color: "navy",
              paddingTop: 20,
              paddingBottom: 20,
              backgroundColor: "transparent",
              width: "95%",
              marginTop: -3,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(DOMPurify.sanitize(recipe.directions)),
              }}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <label style={{ paddingBottom: 7 }}>Ingredients</label>
        {recipe.ingredients === undefined || recipe.ingredients.length === 0 ? (
          <span>No ingredients added</span>
        ) : (
          viewIngredients.map((i, index) => <Ingredient key={index} {...i} />)
        )}
      </FormGroup>
      {recipe.tags != undefined ? (
        <FormGroup>
          <label style={{ paddingTop: 10, paddingBottom: 10 }}>Tags</label>
          {recipe.tags.map((tag) => (
            <span
              style={{
                listStyle: "none",
                //     color: "navy",
                fontSize: 14,
                padding: 10,
                lineHeight: "40px",
                borderStyle: "dashed",
              }}
            >
              {tag.text}
            </span>
          ))}
        </FormGroup>
      ) : null}
      <div className={classes.actions}>
        <button
          style={{
            fontFamily: "inherit",
            fontSize: "inherit",
            // boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
            borderColor: "white",
            cursor: "pointer",
          }}
          onClick={close}
          title="Close"
        >
          Close
        </button>
      </div>
    </>
  );
};
