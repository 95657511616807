import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faEnvelope,
  faShare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { singular } from "pluralize";

import { Button, FormGroup, Modal } from "Common";
import { ItemModel, useItems } from "Lists";
import { RecipeModel } from "Recipes";
import { Ingredient } from "./Ingredient";
import { Days } from "./Days";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
import classes from "./ReadRecipe.module.css";
import DOMPurify from "dompurify";
import linkifyHtml from "linkify-html";
import { WriteRecipe } from "../Write";
import { useRecipes } from "Recipes";
import { useItemSubscription } from "Lists";
import { useTranslation } from "react-i18next";

export const ReadRecipeLanding = ({ recipe }) => {
  const { addItem, removeItem } = useItems();
  const { addedItems, allItems } = useItemSubscription();
  //console.log("what is addeditems", addedItems);
  const { deleteRecipe } = useRecipes();
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [isEditRecipe, setIsEditRecipe] = useState(false);
  const [isWeeklyRecipe, setIsWeeklyRecipe] = useState(false);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  useEffect(() => {
    CheckPlatform();

    return () => {};
  }, []);

  const sanitisedIngredients = (recipe.ingredients ?? []).map((i) => ({
    sanitisedName: singular(i.name.toLowerCase()),
    name: i.name,
    measurement: i.measurement,
  }));
  const viewIngredients = sanitisedIngredients.map((i) => {
    const addedItem = addedItems.find((ai) => ai.lowerName === i.sanitisedName);
    const added = !!addedItem;
    return {
      name: i.name,
      measurement: i.measurement,
      added,
      toggle: addedItem
        ? () => removeItem(addedItem.id)
        : () => addItem(i.name, "1"),
    };
  });

  const edit = () => {
    setIsEditRecipe(true);
  };

  const removeTags = (str) => {
    if (typeof str === "string") {
      return str.replace(/<[^>]+>/g, "");
    }
    return str;
  };

  const emailRecipe = () => {
    var url = `mailto:?subject=My%20recipe%20for%20${
      recipe.name
    }&body=%20Recipe%20for%20${recipe.name}:%0A%0A${recipe.ingredients
      .map((i) => i.measurement + "%20" + i.name + "%20%0A")
      .join("")}%0A${removeTags(recipe.directions)}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const launchRecipeSite = () => {
    if (isAndroid) {
      window.open(recipe.recipeUrl, "_blank");
    } else {
      window.location.href = recipe.recipeUrl;
    }
  };

  const deleteAccepted = () => {
    // console.log("accepted");
    deleteRecipe(recipe.id);
    setIsDeleteConfirmation(false);
    toast(t("readrecipelanding-toast-reciperemoved"), {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  };

  return (
    <>
      <div className={classes.headerContainer}></div>
      <div
        style={{
          marginTop: 20,
          paddingBottom: 0,
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            marginRight: 10,
            cursor: "pointer",
          }}
          title="Share"
          onClick={() => setIsDeleteConfirmation(true)}
          className={classes.editButton}
        >
          <FontAwesomeIcon icon={faTrashCan} size="2x" />
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            marginRight: 10,
            cursor: "pointer",
          }}
          title="Share"
          onClick={() => emailRecipe()}
          className={classes.editButton}
        >
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            borderStyle: "none",
            cursor: "pointer",
          }}
          title="Edit"
          onClick={edit}
          className={classes.editButton}
        >
          <FontAwesomeIcon icon={faEdit} size="2x" />
        </button>
      </div>
      <h2
        style={{
          fontSize: 30,
          //color: "navy",
          paddingTop: 5,
        }}
      >
        {recipe.name}
      </h2>
      {isWeeklyRecipe ? (
        <div>
          {" "}
          <label style={{ paddingBottom: 20, paddingTop: 0 }}>
            {t("readrecipelanding-selectdaysweekly-label")}
          </label>
          <Days id={recipe.id} recipeDays={recipe.days ?? []} />
          <p>{t("readrecipelanding-selectdaysweekly-warning")}</p>
          <p
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setIsWeeklyRecipe(false)}
          >
            {t("readrecipelanding-selectdaysweekly-hide")}
          </p>
        </div>
      ) : (
        <p
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => setIsWeeklyRecipe(true)}
        >
          {t("readrecipelanding-selectdaysweekly-show")}
        </p>
      )}

      {recipe.recipeUrl ? (
        <FormGroup>
          <label style={{ paddingTop: 10, paddingBottom: 10 }}>
            {t("readrecipelanding-recipeurl-label")}
          </label>
          <p
            style={{ color: "blue", cursor: "pointer" }}
            onClick={launchRecipeSite}
          >
            {t("readrecipelanding-recipeurl-link")}
          </p>
        </FormGroup>
      ) : null}
      <FormGroup>
        <div style={{ paddingTop: 0, paddingBottom: 10 }}>
          <label style={{ paddingBottom: 5, paddingTop: 10 }}>
            {t("readrecipelanding-form-directions")}
          </label>
          <div
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              //  color: "navy",
              paddingTop: 20,
              paddingBottom: 20,
              backgroundColor: "transparent",
              width: "95%",
              marginTop: -3,
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: linkifyHtml(DOMPurify.sanitize(recipe.directions)),
              }}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <label style={{ paddingBottom: 7 }}>
          {" "}
          {t("readrecipelanding-form-ingredients")}
        </label>
        {recipe.ingredients === undefined || recipe.ingredients.length === 0 ? (
          <span> {t("readrecipelanding-form-noingredients")}</span>
        ) : (
          viewIngredients.map((i, index) => <Ingredient key={index} {...i} />)
        )}
      </FormGroup>
      {recipe.tags != undefined ? (
        <FormGroup>
          <label style={{ paddingTop: 10, paddingBottom: 10 }}>
            {" "}
            {t("readrecipelanding-form-tags")}
          </label>
          <span style={{ display: "flex", flexFlow: "wrap" }}>
            {recipe.tags.map((tag, index) => (
              <span
                key={index}
                style={{
                  listStyle: "none",
                  //        color: "navy",
                  fontSize: 14,
                  padding: 10,
                  lineHeight: "40px",
                  borderStyle: "dashed",
                }}
              >
                {tag.text}
              </span>
            ))}
          </span>
        </FormGroup>
      ) : null}
      {isEditRecipe ? (
        <Modal
          isOpen={isEditRecipe}
          onRequestClose={() => setIsEditRecipe(false)}
          style={{ overlay: { zIndex: 100 } }}
        >
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            {" "}
            <WriteRecipe
              recipe={recipe}
              close={() => setIsEditRecipe(false)}
              onSave={() => setIsEditRecipe(false)}
            />
          </div>
          <div style={{ paddingBottom: 80 }} />
        </Modal>
      ) : null}
      <div style={{ paddingBottom: 40 }} />
      <ConfirmationModal
        isOpen={isDeleteConfirmation}
        text={t("readrecipelanding-form-removeconfirmation")}
        onClickCancel={() => setIsDeleteConfirmation(false)}
        onClickConfirm={deleteAccepted}
      />
    </>
  );
};
