import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;

  width: 100%;

  justify-content: space-between;

  border-radius: 5px;

  padding: 13px;
  margin-top: 10px;

  font-size: 0.8em;
  line-height: 16px;
  color: #666;
  user-select: none;
  background-color: lightyellow;

  // border-radius: 12px 8px 14px 15px/15px 9px 17px 12px;
  box-shadow: 1px 1px 1px 2px grey, 0 0 2px #fcfbf4 inset;
`;

const TextContainer = styled.div`
  text-align: left;
`;

const HabitName = styled.span`
  font-size: 18px;
  line-height: 25px;
  text-shadow: "1px 3px 3px lightgrey";
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const Text = styled.div`
  font-size: 13px;
  line-height: 16px;

  mark {
    all: unset;
    color: #8fc549;
  }
`;

const CheckBox = styled.button`
  box-sizing: border-box;
  margin-top: 5px;
  height: 100px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  border: ${(props) => (!props.done ? "1px solid #E7E7E7" : "none")};

  background-color: ${(props) => (!props.done ? "#EBEBEB" : "lightgreen")};
  border-style: solid;
  border-color: green;
  box-shadow: 2px 3px 3px grey, 0 0 5px #fcfbf4 inset;
`;

export { Container, HabitName, Text, CheckBox, TextContainer };
