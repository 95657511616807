import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";
import { App } from "App";
import { AppProviders } from "./AppProviders";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { reportWebVitals } from "reportWebVitals";
//import Tracker from "@openreplay/tracker";
import "index.css";
import "./i18n";

Modal.setAppElement("#root");

if (
  !(window as any).isTrackingIOSDisable &&
  process.env.NODE_ENV != "development"
) {
  /*

  const tracker = new Tracker({
    projectKey: "Pm6J8vmno8N5ziEuLUlI",

    //captureIFrames: false,
    obscureInputDates: true,
    obscureTextEmails: true,
    obscureInputNumbers: true,
    obscureInputEmails: true,
    obscureTextNumbers: true,
  });
  try {
    if (tracker.isActive() === false) {
     // tracker.start();
    }
  } catch (error) {}
  */

  Sentry.init({
    dsn: "https://524d63cbb0af454e87693fccd151835b@o4504165936201728.ingest.sentry.io/4504165938561025",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    //  tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error

    //  replaysSessionSampleRate: 0.2,
    //replaysOnErrorSampleRate: 1,
  });
}

ReactDOM.render(
  //@ts-ignore
  <Sentry.ErrorBoundary fallback="An error has occurred.">
    <AppProviders>
      <Router>
        <App />
      </Router>
    </AppProviders>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.NODE_ENV === "development") {
  serviceWorkerRegistration.unregister();
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
