import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import emailjs from "@emailjs/browser";

import {
  Timestamp,
  collection,
  doc,
  CollectionReference,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  DocumentData,
  writeBatch,
} from "firebase/firestore";

//import firebase from "firebase/compat/app";
//import "firebase/compat/firestore";

import { FullPageSpinner } from "Common";
import { db } from "Config";

import { useAuth } from "./AuthContext";
import { AuthUser, DatabaseUser, User, UserGroup, UserState } from "./models";

interface UserContextType {
  user: User | undefined;
  setUserGroup: (group: UserGroup) => void;
  setNickName: (name, isSet, deviceOS, app) => void;
  initialGrocerySetup: () => void;
  addPromoCode: (promocode) => void;
  payPalSubscription: (subscriptionID, planID) => void;
  payPalLifeTimePurchase: (orderID) => void;
  refreshUser: () => Promise<void>;
  deleteGroupUsers: (memberid) => void;
  getGroupUsersCollection: () => CollectionReference<DocumentData>;
  getDefaultItemsCollection: () => CollectionReference<DocumentData>;
  getDefaultItemsCategoryCollection: () => CollectionReference<DocumentData>;
  getRecipesCollection: () => CollectionReference<DocumentData>;
  getCategoriesForGroup: () => CollectionReference<DocumentData>;
  updateGroceryCategoryColor: (id, colour) => void;
  updateGroceryCategoryName: (id, name) => void;
  deleteGroceryCategory: (id) => void;
  addGroceryCategory: (newCategory) => void;
  checkGroupAccess: () => void;

  //scoreboard
  addNewGame: (name, type, isHighestScore) => void;
  getScoreCollection: () => CollectionReference<DocumentData>;
  editPlayers: (id, players) => void;
  deleteGame: (id) => void;

  getNotesCollection: () => CollectionReference<DocumentData>;
  addNewNote: (text, tags, color) => void;
  deleteNote: (id) => void;
  editNote: (id, text, tags, color) => void;
  editNoteColor: (id, color) => void;

  editCalendarColor: (id, color) => void;

  removeGroupFromMemberByOwner: (userID) => void;
  getPrivateToDoItems: () => CollectionReference<DocumentData>;
  getPrivateToDoCategories: () => CollectionReference<DocumentData>;
  //ToDoItemsUpdate: (item, completed, date, userName) => void;
  ToDoDueDateUpdate: (item, dueDate, dueTime) => void;
  updateToDoCategoryColor: (id, colour) => void;
  updateToDoCategoryName: (id, name) => void;
  deleteToDoCategory: (id) => void;
  addToDoCategory: (newCategory) => void;
  // setWhichToDoList: (whichToDoList) => void;
  getEventCollection: () => CollectionReference<DocumentData>;
  addNewEvent: (title, start, end) => void;
  updateEventCal: (id, title?, start?, end?, allDay?) => void;
  deleteEventCal: (id) => void;
  updateEventCalModal: (id, update) => void;
  addNewEventModal: (
    title,
    description,
    start,
    end,
    allDay,
    imageURL,
    imageCaption,
    color
  ) => void;
  addNewPlannerEvent: (
    name,
    note,
    start,
    end,
    time,
    isTask,
    isRepeats,
    days,
    duration,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue,
    color
  ) => void;
  batchUploadPlannerEvents: (tempEvents) => void;
  editPlannerEvent: (
    id,
    name,
    note,
    start,
    end,
    time,
    isTask,
    isRepeats,
    days,
    duration,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue,
    color
  ) => void;
  deletePlannerEvent: (id) => void;
  addNewMealEvent: (
    recipeLinkId,
    name,
    note,
    type,
    time,
    startDate,
    endDate,
    added
  ) => void;
  editMealEvent: (
    id,
    recipeLinkId,
    name,
    note,
    type,
    time,
    startDate,
    endDate,
    added
  ) => void;
  deleteMealEvent: (id) => void;
  getPlannerCollection: () => CollectionReference<DocumentData>;
  getMealPlannerCollection: () => CollectionReference<DocumentData>;
  getHabitsCollection: () => CollectionReference<DocumentData>;
  getHabitsStatusCollection: (habitID) => CollectionReference<DocumentData>;
  addNewHabit: (
    name,
    days,
    isTrackingReps,
    RepsLabel,
    date,
    totalReps,
    time,
    duration,
    durationMinutes,
    isPlanner,
    lastDay
  ) => void;
  deleteHabit: (id) => void;
  editHabit: (
    id,
    name,
    days,
    isTrackingReps,
    RepsLabel,
    isPlanner,
    time,
    duration,
    durationMinutes,
    lastDay
  ) => void;
  completeDailyHabit: (
    id,
    date,
    done,
    statusID,
    number,
    statusLog,
    notes,
    myReps,
    totalReps
  ) => void;
  completeDailyHabitCalendar: (
    id,
    date,
    number,
    statusLogID,
    notes,
    myReps,
    totalReps
  ) => void;
  completeDailyHabitRemove: (id, statusId, number, done, totalReps) => void;
  updateHabitSummaryWidget: (habit) => void;
  deleteUser: (id) => void;
  updateDBCloseUpdateNotice: () => void;
  updateSortableLists: (list) => void;
  changeUserName: (name) => void;
  linkEmailToGuest: (email) => void;
  UpdateWidgetOrder: (value) => void;
  UpdateThemeFont: (font) => void;
  UpdateThemeColor: (color) => void;
  getReminderCollection: () => CollectionReference<DocumentData>;
  addNewReminder: (
    dateCreated,
    messageID,
    title,
    body,
    reminderOnDate,
    atTime,
    isDaily,
    icsURL,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue
  ) => void;
  deleteReminder: (id) => void;
}

var NewUserTrial30 = new Date();
//changed to 1 days
NewUserTrial30.setDate(NewUserTrial30.getDate() + 7);

const UserContext = createContext<UserContextType>({
  user: undefined,
  setUserGroup: () => null,
  setNickName: () => null,
  initialGrocerySetup: () => null,
  addPromoCode: () => null as any,
  payPalLifeTimePurchase: () => null as any,
  payPalSubscription: () => null as any,
  refreshUser: () => Promise.resolve(),
  getGroupUsersCollection: () => null as any,
  deleteGroupUsers: () => null as any,
  getDefaultItemsCollection: () => null as any,
  getDefaultItemsCategoryCollection: () => null as any,
  checkGroupAccess: () => null as any,
  getRecipesCollection: () => null as any,
  getCategoriesForGroup: () => null as any,
  updateGroceryCategoryColor: () => null as any,
  updateGroceryCategoryName: () => null as any,
  deleteGroceryCategory: () => null as any,
  addGroceryCategory: () => null as any,
  getScoreCollection: () => null as any,
  addNewGame: () => null as any,
  deleteGame: () => null as any,
  editPlayers: () => null as any,
  getNotesCollection: () => null as any,
  addNewNote: () => null as any,
  deleteNote: () => null as any,
  editNote: () => null as any,
  editNoteColor: () => null as any,
  editCalendarColor: () => null as any,
  removeGroupFromMemberByOwner: () => null as any,
  getPrivateToDoItems: () => null as any,
  //ToDoItemsUpdate: () => null as any,
  ToDoDueDateUpdate: () => null as any,
  updateToDoCategoryColor: () => null as any,
  updateToDoCategoryName: () => null as any,
  deleteToDoCategory: () => null as any,
  addToDoCategory: () => null as any,
  getPrivateToDoCategories: () => null as any,
  //setWhichToDoList: () => null as any,
  getEventCollection: () => null as any,
  addNewEvent: () => null as any,
  updateEventCal: () => null as any,
  deleteEventCal: () => null as any,
  updateEventCalModal: () => null as any,
  addNewEventModal: () => null as any,
  getPlannerCollection: () => null as any,
  addNewPlannerEvent: () => null as any,
  batchUploadPlannerEvents: () => null as any,
  editPlannerEvent: () => null as any,
  deletePlannerEvent: () => null as any,
  addNewMealEvent: () => null as any,
  editMealEvent: () => null as any,
  deleteMealEvent: () => null as any,
  getMealPlannerCollection: () => null as any,
  getHabitsCollection: () => null as any,
  getHabitsStatusCollection: () => null as any,
  addNewHabit: () => null as any,
  deleteHabit: () => null as any,
  editHabit: () => null as any,
  completeDailyHabit: () => null as any,
  completeDailyHabitCalendar: () => null as any,
  completeDailyHabitRemove: () => null as any,
  updateHabitSummaryWidget: () => null as any,
  deleteUser: () => null as any,
  updateDBCloseUpdateNotice: () => null as any,
  updateSortableLists: () => null as any,
  changeUserName: () => null as any,
  linkEmailToGuest: () => null as any,
  UpdateWidgetOrder: () => null as any,
  UpdateThemeFont: () => null as any,
  UpdateThemeColor: () => null as any,
  getReminderCollection: () => null as any,
  addNewReminder: () => null as any,
  deleteReminder: () => null as any,
});

interface UserProviderProps {
  children?: ReactNode;
}

const UserProvider = (props: UserProviderProps) => {
  const { user: authUser, initialised } = useAuth();
  const [user, setUser] = useState<User>();
  //const [whichToDo, setwhichToDoList] = useState<User>();

  /** Retrieves the user, or if it is their first login then create a new user object. */
  async function getOrCreateUser(authUser: AuthUser): Promise<DatabaseUser> {
    const docRef = doc(collection(db, "users"), authUser.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data({ serverTimestamps: "estimate" }) as DatabaseUser;
    }

    const newUser: DatabaseUser = {
      name: authUser.name || authUser.email || authUser.phone || "",
      added: Timestamp.now() as Timestamp,
      email: authUser.email,
      phone: authUser.phone,
      state: UserState.Unregistered,
      isNickNameSet: false,
      userId: authUser.id,
      subscriptionEndDate: NewUserTrial30,
      updateNotice106: true,
    };

    const batch = writeBatch(db);
    batch.set(docRef, newUser);

    if (authUser.email) {
      //sendWelcomeEmail();
    }

    await batch.commit();

    return newUser;
  }

  const sendWelcomeEmail = () => {
    var templateParams = {
      email: authUser.email,
    };
    try {
      emailjs
        .send(
          "service_irbu2n6",
          "template_pkden9m",
          templateParams,
          "IbqQ9coZSkM9kJluH"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (error) {
      console.log(error);
    }
  };

  const setNickName = async (name, isSet, deviceOS, app) => {
    //  const db = getFirestore();
    const userDocRef = doc(collection(db, "users"), authUser.id);

    await updateDoc(userDocRef, {
      name: name,
      isNickNameSet: isSet,
      themeFont: "Kalam",
      themeColor: "#673ab7",
      deviceOS: deviceOS,
      app: app,
    });

    setUser(name);
  };

  const addPromoCode = async (promocode) => {
    //  const db = getFirestore();
    const userDocRef = doc(collection(db, "users"), authUser.id);

    await updateDoc(userDocRef, {
      promocode: promocode,
      promocode_dateadded: new Date(),
    });
  };

  const payPalLifeTimePurchase = async (orderID) => {
    //  const db = getFirestore();
    const userDocRef = doc(collection(db, "users"), authUser.id);

    await updateDoc(userDocRef, {
      payPal: { lifetime: true, orderDate: new Date(), orderID: orderID },
    });
  };

  const payPalSubscription = async (subscriptionID, planID) => {
    //  const db = getFirestore();
    const userDocRef = doc(collection(db, "users"), authUser.id);

    await updateDoc(userDocRef, {
      payPal: {
        lifetime: false,
        subscription: "active",
        orderDate: new Date(),
        subscriptionID: subscriptionID,
        planID: planID,
      },
    });
  };

  const initialGrocerySetup = async () => {
    //  const db = getFirestore();
    const userDocRef = doc(collection(db, "users"), authUser.id);

    await updateDoc(userDocRef, {
      isInitialGrocerySetup: true,
    });
  };

  const refreshUser = useCallback(async () => {
    if (authUser) {
      // TODO handle error case
      const user = await getOrCreateUser(authUser);
      setUser({
        ...user,
        id: authUser.id,
        name: user.name,
      });
    } else {
      setUser(undefined);
      //window.location.reload();
    }
  }, [authUser]);

  useEffect(() => {
    try {
      refreshUser();
    } catch (error) {}
  }, [refreshUser]);

  /** Used to update the user once they have registered */
  const setUserGroup = (group: UserGroup) => {
    if (!user) {
      throw new Error(
        "Should not try to set user group when user is undefined"
      );
    }

    setUser({ ...user, state: UserState.Registered, group });
  };

  const [isShopping, setIsShopping] = useState("shopping");

  /*const setWhichToDoList = async (whichtodolist) => {
    setIsShopping(whichtodolist);
    await db
      .collection("users")
      .doc(authUser.id)
      .update({ whichToDoList: whichtodolist });
  };*/

  const checkGroupAccess = async (): Promise<boolean> => {
    // const db = getFirestore();
    const membersRef = collection(db, `groups/${user?.group?.id}/members`);
    const querySnapshot = await getDocs(membersRef);

    let hasUserABC = false;
    for (const doc of querySnapshot.docs) {
      const member = doc.data();
      if (member.userID || member.id === user?.id) {
        hasUserABC = true;
        break;
      }
    }

    return hasUserABC;
  };

  //const getGroup = () => db.collection("groups").doc(user?.group?.id);

  const deleteUser = async () => {
    //TODO Need to make this automatic

    const userDocRef = doc(collection(db, "users"), user?.id);

    await updateDoc(userDocRef, {
      deleteAccount: true,
    });
  };

  //used on the subscription checker for major app update notice
  const updateDBCloseUpdateNotice = async () => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      updateNotice106: true,
    });
  };

  //set user's summary list
  const updateSortableLists = async (list) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      sortableLists: list,
    });
  };

  const UpdateWidgetOrder = async (value) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      dashboardWidgetOrder: value,
    });
  };

  const UpdateThemeFont = async (font) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      themeFont: font,
    });
  };

  const UpdateThemeColor = async (color) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      themeColor: color,
    });
  };

  const changeUserName = async (name) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      name: name,
    });
  };

  const linkEmailToGuest = async (email) => {
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      email: email,
    });
  };

  //used for setting profile (household)
  const getGroupUsersCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "groups"), user?.group?.id);
    return collection(userRef, "members");
  };

  //used for user switching groups or getting blocked

  const deleteGroupUsers = (memberId) => {
    const userRef = doc(collection(db, "groups"), user?.group?.id);
    const memberRef = doc(collection(userRef, "members"), memberId);
    deleteDoc(memberRef);
  };

  //user for getting blocked, removes the owners groups from the member's acccount.  Notes and nickname are still set.  User will need to create a new group.
  const removeGroupFromMemberByOwner = async () => {
    const userRef = doc(db, "users", authUser.id);
    await updateDoc(userRef, {
      state: "Unregistered",
    });
    //console.log("fired");
  };

  //Planner / Calendar

  const getPlannerCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "planner");
  };

  const addNewPlannerEvent = async (
    name,
    note,
    start,
    end,
    time,
    isTask,
    isRepeats,
    days,
    duration,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue,
    color
  ) => {
    const userRef = doc(db, "users", authUser.id);
    const plannerRef = collection(userRef, "planner");

    await addDoc(plannerRef, {
      created: new Date().toString(),
      name,
      note,
      startDate: start,
      endDate: end,
      time,
      isTask,
      isRepeats,
      days,
      duration,
      repeatValue,
      repeatMonthlyValue,
      repeatWeeklyIntervalValue,
      color,
    });
  };

  const batchUploadPlannerEvents = async (tempEvents) => {
    // Start a new batch
    // const batch = writeBatch(db);

    // Reference to the user's document
    const userRef = doc(db, "users", authUser.id);

    // Iterate over tempEvents to create batched writes
    const processBatch = async (batchEvents) => {
      const batch = writeBatch(db);

      batchEvents.forEach((event) => {
        const plannerRef = collection(userRef, "planner");
        const newEventRef = doc(plannerRef);

        batch.set(newEventRef, {
          created: new Date().toString(),
          name: event.name,
          note: event.note,
          startDate: event.start,
          endDate: event.end,
          time: event.time,
          isTask: event.isTask,
          isRepeats: event.isRepeats,
          days: event.days,
          duration: event.duration,
          repeatValue: event.repeatValue,
          repeatMonthlyValue: event.repeatMonthlyValue,
          repeatWeeklyIntervalValue: event.repeatWeeklyIntervalValue,
          color: event.color,
        });
      });

      // Commit the batch
      await batch.commit();
    };
    // Split tempEvents into chunks of 100
    for (let i = 0; i < tempEvents.length; i += 100) {
      const batchEvents = tempEvents.slice(i, i + 100);
      await processBatch(batchEvents);
    }
  };

  const editPlannerEvent = async (
    id,
    name,
    note,
    start,
    end,
    time,
    isTask,
    isRepeats,
    days,
    duration,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue,
    color
  ) => {
    const userRef = doc(db, "users", authUser.id);
    const plannerRef = collection(userRef, "planner");
    const eventRef = doc(plannerRef, id);

    await updateDoc(eventRef, {
      name,
      note,
      startDate: start,
      endDate: end,
      time,
      isTask,
      isRepeats,
      days,
      duration,
      repeatValue,
      repeatMonthlyValue,
      repeatWeeklyIntervalValue,
      color,
    });
  };

  const deletePlannerEvent = async (id) => {
    const userRef = doc(db, "users", authUser.id);
    const plannerRef = collection(userRef, "planner");
    const eventRef = doc(plannerRef, id);

    await deleteDoc(eventRef);
  };

  //Meal Planner Events

  const getMealPlannerCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "groups"), user?.group?.id);
    return collection(userRef, "MealPlanner");
  };

  const addNewMealEvent = async (
    recipeLinkId,
    name,
    note,
    type,
    time,
    startDate,
    endDate,
    added
  ) => {
    const userRef = doc(db, "groups", user?.group?.id);
    const plannerRef = collection(userRef, "MealPlanner");

    await addDoc(plannerRef, {
      created: new Date().toString(),
      recipeLinkId,
      name,
      note,
      type,
      time,
      startDate,
      endDate,
      added,
    });
  };

  const editMealEvent = async (
    id,
    recipeLinkId,
    name,
    note,
    type,
    time,
    startDate,
    endDate,
    added
  ) => {
    const userRef = doc(db, "groups", user?.group?.id);
    const plannerRef = collection(userRef, "MealPlanner");
    const eventRef = doc(plannerRef, id);

    await updateDoc(eventRef, {
      recipeLinkId,
      name,
      note,
      type,
      time,
      startDate,
      endDate,
      added,
    });
  };

  const deleteMealEvent = async (id) => {
    const userRef = doc(db, "groups", user?.group?.id);
    const plannerRef = collection(userRef, "MealPlanner");
    const eventRef = doc(plannerRef, id);

    await deleteDoc(eventRef);
  };

  //Habit Tracker

  const updateHabitSummaryWidget = async (habit) => {
    // const db = getFirestore();
    const userRef = doc(db, "users", user?.id);
    await updateDoc(userRef, {
      habitSummary: habit,
    });
  };

  const getHabitsCollection = () => {
    // const db = getFirestore();
    return collection(db, `users/${authUser.id}/habits`);
  };

  const getHabitsStatusCollection = (habitId) => {
    // const db = getFirestore();
    // const habitRef = doc(db, `users/${authUser.id}/habits`, habitId);
    return collection(db, `users/${authUser.id}/habits/${habitId}/statuslog`);
  };

  const addNewHabit = async (
    name,
    days,
    isTrackingReps,
    RepsLabel,
    date,
    totalReps,
    time,
    duration,
    durationMinutes,
    isPlanner,
    lastDay
  ) => {
    // const db = getFirestore();
    const habitsCollection = collection(db, `users/${authUser.id}/habits`);
    const habitData = {
      addedDate: new Date().toString(),
      name: name,
      days: days,
      done: false,
      dateAdded: date,
      completionNumber: 0,
      isTrackingReps: isTrackingReps,
      RepsLabel: RepsLabel,
      userId: authUser.id,
      totalReps: totalReps,
      time: time,
      duration: duration,
      isPlanner: isPlanner,
      durationMinutes: durationMinutes,
      lastDay: lastDay,
    };
    await addDoc(habitsCollection, habitData);
  };

  const deleteHabit = async (id) => {
    // const db = getFirestore();
    const habitRef = doc(db, `users/${authUser.id}/habits`, id);
    await deleteDoc(habitRef);
  };

  const editHabit = async (
    id,
    name,
    days,
    isTrackingReps,
    RepsLabel,
    isPlanner,
    time,
    duration,
    durationMinutes,
    lastDay
  ) => {
    // const db = getFirestore();
    const habitRef = doc(db, `users/${authUser.id}/habits`, id);
    await updateDoc(habitRef, {
      name: name,
      days: days,
      isTrackingReps: isTrackingReps,
      RepsLabel: RepsLabel,
      isPlanner: isPlanner,
      time: time,
      duration: duration,
      durationMinutes: durationMinutes,
      lastDay: lastDay,
    });
  };

  const completeDailyHabitCalendar = async (
    id,
    date,
    number,
    statusLogID,
    notes,
    myReps,
    totalReps
  ) => {
    // const db = getFirestore();
    const habitRef = doc(db, `users/${authUser.id}/habits`, id);

    // Update the habit document with the current status
    await updateDoc(habitRef, {
      completionNumber: number,
      statusLog: statusLogID,
      todayReps: myReps,
      totalReps: totalReps,
    });

    // Add a new status log entry with a random ID
    const statusLogRef = doc(
      db,
      `users/${authUser.id}/habits/${id}/statuslog`,
      statusLogID
    );
    //  const randomId = Math.floor(Math.random() * 1677721500).toString();

    await setDoc(statusLogRef, {
      id: statusLogID,
      date: date,
      notes: notes,
      myReps: myReps,
    });
  };

  const completeDailyHabit = async (
    id,
    date,
    done,
    statusID,
    number,
    statusLog,
    notes,
    myReps,
    totalReps
  ) => {
    // const db = getFirestore();
    const habitRef = doc(db, `users/${authUser.id}/habits`, id);

    // Update the habit document with the current status
    await updateDoc(habitRef, {
      dateLastUpdated: statusID,
      done: done,
      completionNumber: number,
      statusLog: statusLog,
      todayReps: myReps,
      totalReps: totalReps,
    });

    // Add a new status log entry with a random ID
    const statusLogRef = doc(
      db,
      `users/${authUser.id}/habits/${id}/statuslog`,
      statusID
    );
    //  const randomId = Math.floor(Math.random() * 1677721500).toString();

    await setDoc(statusLogRef, {
      id: statusID,
      date: date,
      notes: notes,
      myReps: myReps,
    });
  };

  const completeDailyHabitRemove = async (
    id,
    statusId,
    number,
    done,
    totalReps
  ) => {
    // const db = getFirestore();
    const habitRef = doc(db, `users/${authUser.id}/habits`, id);

    // Update the habit document with the updated completion and total reps
    await updateDoc(habitRef, {
      completionNumber: number,
      done: done,
      totalReps: totalReps,
    });

    // Delete the specified status log entry
    const statusLogRef = doc(habitRef, "statuslog", statusId);
    try {
      // console.log("what is the id", statusId);
      await deleteDoc(statusLogRef);
    } catch (error) {
      // console.log("what is the error", error);
    }
  };

  //habits end

  const getPrivateToDoItems = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "items");
  };

  const ToDoDueDateUpdate = async (item, dueDate, dueTime) => {
    const itemRef = doc(db, `users/${authUser.id}/items`, item);

    // Update the habit document with the updated completion and total reps
    await updateDoc(itemRef, {
      dueDate,
      dueTime,
    });
  };

  const updateToDoCategoryColor = async (id, color) => {
    const categoriesRef = await getPrivateToDoCategories();
    const categoryRef = doc(categoriesRef, id);
    await updateDoc(categoryRef, {
      colour: color,
    });
  };

  const updateToDoCategoryName = async (id, name) => {
    const categoriesRef = await getPrivateToDoCategories();
    const categoryRef = doc(categoriesRef, id);
    await updateDoc(categoryRef, {
      name,
    });
  };

  const deleteToDoCategory = async (id) => {
    const categoriesRef = await getPrivateToDoCategories();
    const categoryRef = doc(categoriesRef, id);
    await deleteDoc(categoryRef);
  };

  const addToDoCategory = async (newCategory) => {
    const categoriesRef = await getPrivateToDoCategories();
    await addDoc(categoriesRef, newCategory);
  };

  //Grocery List

  const getDefaultItemsCollection = () => {
    const userGroupRef = doc(db, "groups", user?.group?.id);
    const userDefaultListRef = doc(
      collection(userGroupRef, "lists"),
      user?.group?.defaultList
    );
    const itemsRef = collection(userDefaultListRef, "items");
    return itemsRef;
  };

  const getDefaultItemsCategoryCollection = () => {
    const userGroupRef = doc(db, "groups", user?.group?.id);
    const userDefaultListRef = doc(
      collection(userGroupRef, "lists"),
      user?.group?.defaultList
    );
    const categoryRef = collection(userDefaultListRef, "categories");
    return categoryRef;
  };

  //used for the Journal

  const getEventCollection = () => {
    // const db = getFirestore();
    // const authUser = firebase.auth().currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "events");
  };

  const addNewEvent = async (title, start, end) => {
    // const db = getFirestore();
    //  const authUser = firebase.auth().currentUser;
    const eventRef = collection(db, `users/${authUser.id}/events`);
    await addDoc(eventRef, {
      id: Math.random().toString(),
      title: title,
      start: new Date(start),
      end: new Date(end),
      allDay: false,
    });
  };

  const addNewEventModal = async (
    title,
    description,
    start,
    end,
    allDay,
    imageURL,
    imageCaption,
    color
  ) => {
    // const db = getFirestore();
    //const authUser = firebase.auth().currentUser;
    const eventRef = collection(db, `users/${authUser.id}/events`);
    await addDoc(eventRef, {
      id: Math.random().toString(),
      title: title,
      description: description,
      start: new Date(start),
      end: new Date(end),
      allDay: allDay,
      imageURL: imageURL,
      imageCaption: imageCaption,
      color: color,
    });
  };

  const updateEventCal = async (id, start, end, allDay) => {
    // const db = getFirestore();
    // const authUser = firebase.auth().currentUser;
    const eventRef = doc(db, `users/${authUser.id}/events`, id);

    await updateDoc(eventRef, {
      start: new Date(start),
      end: new Date(end),
      allDay: allDay,
    });
  };

  const updateEventCalModal = async (id, update) => {
    // const db = getFirestore();
    const eventRef = doc(db, `users/${authUser.id}/events`, id);
    await updateDoc(eventRef, update);
  };

  const deleteEventCal = async (id) => {
    // const db = getFirestore();
    const eventRef = doc(db, `users/${authUser.id}/events`, id);
    await deleteDoc(eventRef);
  };

  //Following code for private TO DO list.  Not shareble.

  const getPrivateToDoCategories = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "categories");
  };

  //will create new subscription to listen for an udpate.

  const getRecipesCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "groups"), user?.group?.id);
    return collection(userRef, "recipes");
  };

  const getCategoriesForGroup = () => {
    const userGroupRef = doc(collection(db, "groups"), user?.group?.id);
    const userDefaultListRef = doc(
      collection(userGroupRef, "lists"),
      user?.group?.defaultList
    );
    const categoriesRef = collection(userDefaultListRef, "categories");
    return categoriesRef;
  };

  const updateGroceryCategoryColor = async (id, color) => {
    const categoriesRef = await getCategoriesForGroup();
    const categoryRef = doc(categoriesRef, id);
    await updateDoc(categoryRef, {
      colour: color,
    });
  };

  const updateGroceryCategoryName = async (id, name) => {
    const categoriesRef = await getCategoriesForGroup();
    const categoryRef = doc(categoriesRef, id);
    await updateDoc(categoryRef, {
      name,
    });
  };

  const deleteGroceryCategory = async (id) => {
    const categoriesRef = await getCategoriesForGroup();
    const categoryRef = doc(categoriesRef, id);
    await deleteDoc(categoryRef);
  };

  const addGroceryCategory = async (newCategory) => {
    const categoriesRef = await getCategoriesForGroup();
    await addDoc(categoriesRef, newCategory);
  };

  //notification reminder history

  const getReminderCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "reminders");
  };

  const addNewReminder = async (
    dateCreated,
    messageID,
    title,
    body,
    reminderOnDate,
    atTime,
    isDaily,
    icsURL,
    repeatValue,
    repeatMonthlyValue,
    repeatWeeklyIntervalValue
  ) => {
    // const authUser = auth.currentUser;
    const userRemindersRef = collection(
      doc(collection(db, "users"), authUser.id),
      "reminders"
    );

    const newReminder = {
      dateCreated: dateCreated,
      messageID: messageID,
      title: title,
      body: body,
      reminderOnDate: reminderOnDate,
      atTime: atTime,
      isDaily: isDaily,
      userId: authUser.id,
      icsURL: icsURL,
      repeatValue: repeatValue,
      repeatMonthlyValue: repeatMonthlyValue,
      repeatWeeklyIntervalValue: repeatWeeklyIntervalValue,
    };

    await addDoc(userRemindersRef, newReminder);
  };

  const deleteReminder = async (id) => {
    //  const authUser = auth.currentUser;
    const reminderRef = doc(
      doc(collection(db, "users"), authUser.id),
      "reminders",
      id
    );

    await deleteDoc(reminderRef);
  };

  // scores

  const getScoreCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "scorepad");
  };

  const addNewGame = async (name, type, isHighestScore) => {
    // const authUser = auth.currentUser;
    const userScorepadRef = collection(
      doc(collection(db, "users"), authUser.id),
      "scorepad"
    );

    const newGame = {
      date: new Date(),
      name: name,
      type: type,
      isHighestScore: isHighestScore,
      players: [],
    };

    await addDoc(userScorepadRef, newGame);
  };

  const editPlayers = async (id, players) => {
    // const authUser = auth.currentUser;
    const gameRef = doc(
      doc(collection(db, "users"), authUser.id),
      "scorepad",
      id
    );

    await updateDoc(gameRef, { players: players });
  };

  const deleteGame = async (id) => {
    // const authUser = auth.currentUser;
    const gameRef = doc(
      doc(collection(db, "users"), authUser.id),
      "scorepad",
      id
    );

    await deleteDoc(gameRef);
  };

  //notes

  const getNotesCollection = () => {
    // const authUser = auth.currentUser;
    const userRef = doc(collection(db, "users"), authUser.id);
    return collection(userRef, "notes");
  };

  const addNewNote = async (text, tags, color) => {
    //const authUser = auth.currentUser;
    const userNotesRef = collection(
      doc(collection(db, "users"), authUser.id),
      "notes"
    );

    const newNote = {
      date: new Date().toString(),
      text: text,
      tags: tags,
      color: color,
      userId: authUser.id,
    };

    await addDoc(userNotesRef, newNote);
  };

  const deleteNote = async (id) => {
    // const authUser = auth.currentUser;
    const noteRef = doc(doc(collection(db, "users"), authUser.id), "notes", id);

    await deleteDoc(noteRef);
  };

  const editNote = async (id, text, tags, color) => {
    // const authUser = auth.currentUser;
    const noteRef = doc(doc(collection(db, "users"), authUser.id), "notes", id);

    await updateDoc(noteRef, {
      text: text,
      tags: tags,
      color: color,
    });
  };

  const editNoteColor = async (id, color) => {
    //  const authUser = auth.currentUser;
    const noteRef = doc(doc(collection(db, "users"), authUser.id), "notes", id);

    await updateDoc(noteRef, {
      color: color,
    });
  };

  const editCalendarColor = async (id, color) => {
    // const authUser = auth.currentUser;
    const eventRef = doc(
      doc(collection(db, "users"), authUser.id),
      "events",
      id
    );

    await updateDoc(eventRef, {
      color: color,
    });
  };

  const fetching = authUser && !user;

  if (!initialised || fetching) {
    return <FullPageSpinner />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUserGroup,
        setNickName,
        initialGrocerySetup,
        addPromoCode,
        payPalLifeTimePurchase,
        payPalSubscription,
        refreshUser,
        deleteGroupUsers,
        getGroupUsersCollection,
        getDefaultItemsCollection,
        getDefaultItemsCategoryCollection,
        checkGroupAccess,
        getRecipesCollection,
        getScoreCollection,
        addNewGame,
        editPlayers,
        deleteGame,
        getNotesCollection,
        addNewNote,
        deleteNote,
        editNote,
        editNoteColor,
        editCalendarColor,
        getCategoriesForGroup,
        updateGroceryCategoryColor,
        updateGroceryCategoryName,
        deleteGroceryCategory,
        addGroceryCategory,
        removeGroupFromMemberByOwner,
        getPrivateToDoItems,
        getPrivateToDoCategories,
        // ToDoItemsUpdate,
        ToDoDueDateUpdate,
        updateToDoCategoryColor,
        updateToDoCategoryName,
        deleteToDoCategory,
        addToDoCategory,
        //setWhichToDoList,
        getEventCollection,
        addNewEvent,
        updateEventCal,
        deleteEventCal,
        updateEventCalModal,
        addNewEventModal,
        addNewPlannerEvent,
        batchUploadPlannerEvents,
        editPlannerEvent,
        deletePlannerEvent,
        getMealPlannerCollection,
        getPlannerCollection,
        addNewMealEvent,
        editMealEvent,
        deleteMealEvent,
        getHabitsCollection,
        getHabitsStatusCollection,
        addNewHabit,
        deleteHabit,
        editHabit,
        completeDailyHabit,
        completeDailyHabitCalendar,
        completeDailyHabitRemove,
        updateHabitSummaryWidget,
        deleteUser,
        updateDBCloseUpdateNotice,
        updateSortableLists,
        changeUserName,
        linkEmailToGuest,
        UpdateWidgetOrder,
        UpdateThemeFont,
        UpdateThemeColor,
        getReminderCollection,
        addNewReminder,
        deleteReminder,
      }}
      {...props}
    />
  );
};

const useUserContext = () => useContext(UserContext);
const useUser = () => useUserContext().user;
const useGroup = () => useUser()?.group;
const useIsRegistered = () => useUser()?.state === UserState.Registered;

export {
  UserContext,
  UserProvider,
  useUserContext,
  useUser,
  useGroup,
  useIsRegistered,
};
