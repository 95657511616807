import React, { useState } from "react";
import { RemoteButton } from "./RemoteButton";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RemoteControl = () => {
  const { t } = useTranslation();
  const [ai, setAI] = useState(false);
  const history = useHistory();

  const routeChange = (path) => {
    history.push(path);
  };

  return (
    <div className="remote--control-container">
      <div className="metro-grid">
        <RemoteButton
          label={t("header-menu-tasks")}
          desc="Create checklists for anything"
          color="blue"
          link="todo"
        />{" "}
        {/* Bright Blue */}
        <RemoteButton
          label={t("header-menu-notes")}
          desc="Save ideas, links, notes, ..."
          color="blue"
          link="notes"
        />{" "}
        {/* Bright Pink */}
        <RemoteButton
          label={t("header-menu-grocery")}
          desc="Grocery list Manager"
          color="blue"
          link="shopping"
        />{" "}
        {/* Purple */}
        <RemoteButton
          label={t("header-menu-journal")}
          desc="Personal journal for reflection"
          color="blue"
          link="journal"
        />{" "}
        {/* Teal */}
        <RemoteButton
          label={t("header-menu-habit")}
          desc="Track habits & chores"
          color="blue"
          link="habits"
        />{" "}
        {/* Light Green */}
        <RemoteButton
          label={t("header-menu-planner")}
          desc="Build a simple calendar"
          color="blue"
          link="planner"
        />{" "}
        {/* Orange */}
        <RemoteButton
          label="Sortable Lists"
          desc="Create simple draggable lists"
          color="blue"
          link={"sortablelists"}
        />{" "}
        <RemoteButton
          label={t("header-menu-meal")}
          desc="Your Recipes & Meal Planner"
          color="blue"
          link="recipes"
        />{" "}
        {/* Red */}
        {/* Dark Blue */}
      </div>
      <div
        className="metroButton"
        style={{
          borderRadius: 10,
          marginTop: 5,
          width: "100%",
          marginTop: 20,
          color: "white",
        }}
        onClick={() => setAI(true)}
      >
        <span> AI Helper</span>
      </div>
      <div
        className="metroButton"
        style={{
          borderRadius: 10,
          marginTop: 5,
          width: "100%",
          marginTop: 20,
          color: "white",
        }}
        onClick={() => routeChange("/cipher")}
      >
        <span> Send Secret Messages (Beta)</span>
      </div>
      {ai ? (
        <div className="game-modal-overlay" style={{ zIndex: 100 }}>
          <div className="game-modal" style={{ width: "95%" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                paddingBottom: 10,
              }}
            >
              How can AI best assist you? Are you working on a list, a note, or
              a recipe?
            </p>
            <div className="metro-grid">
              <RemoteButton
                label="New Recipe"
                color="purple"
                link={"recipesearch"}
              />
              <RemoteButton label="List" color="purple" link={"listmagic"} />{" "}
              <RemoteButton label="Note" color="purple" link={"ideas"} />
            </div>
            <div style={{ paddingTop: 20 }}>
              <button
                onClick={() => setAI(false)}
                className="game-close-button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
