import { useEffect, useState } from "react";
import { Modal } from "Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ConfirmationModal } from "Common";
import toast, { Toaster } from "react-hot-toast";
import { useItems } from "Lists/useItems";
import { Spinner } from "Common";
import { useTranslation } from "react-i18next";

export const EditDefaultItemsModal = ({ isOpen, close }) => {
  const [confirmModalOpen, setConfirmationOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmationAction, setConfirmationAction] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const {
    addCommonGroceryItems,
    AddCommonCategories,
    deleteAllGroceryItems,
    deleteAllGroceryCategories,
  } = useItems();

  const closeModal = () => {
    close();
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
    setConfirmationText("");
    setConfirmationAction("");
  };
  const { t } = useTranslation();

  const confirmationLaunch = (text) => {
    if (text === "AddEverything") {
      setConfirmationAction("AddEverything");
      setConfirmationText(t("grocery-settings-addeverything-confirmation"));
      setConfirmationOpen(true);
    }
    if (text === "AddItems") {
      setConfirmationAction("AddItems");
      setConfirmationText(t("grocery-settings-additems-confirmation"));
      setConfirmationOpen(true);
    }
    if (text === "AddCategories") {
      setConfirmationAction("AddCategories");
      setConfirmationText(t("grocery-settings-addcat-confirmation"));
      setConfirmationOpen(true);
    }
    if (text === "RemoveItems") {
      setConfirmationAction("RemoveItems");
      setConfirmationText(t("grocery-settings-removeitems-confirmation"));
      setConfirmationOpen(true);
    }
    if (text === "RemoveCategories") {
      setConfirmationAction("RemoveCategories");
      setConfirmationText(t("grocery-settings-removecat-confirmation"));
      setConfirmationOpen(true);
    }
  };

  const confirmationAccepted = async () => {
    if (confirmationAction === "AddEverything") {
      //  console.log("add items");
      closeConfirmation();
      setIsSpinner(true);
      toast(t("grocery-settings-addeverything-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
      await AddCommonCategories();
      await addCommonGroceryItems();
      setIsSpinner(false);
      close();
    }
    if (confirmationAction === "AddItems") {
      //  console.log("add items");
      addCommonGroceryItems();
      closeConfirmation();
      toast(t("grocery-settings-additems-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
    if (confirmationAction === "AddCategories") {
      //console.log("add category");
      AddCommonCategories();
      closeConfirmation();
      toast(t("grocery-settings-addcat-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
    if (confirmationAction === "RemoveItems") {
      //     console.log("remove items");
      deleteAllGroceryItems();
      closeConfirmation();
      toast(t("grocery-settings-removeitems-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
    if (confirmationAction === "RemoveCategories") {
      //  console.log("remove category");
      deleteAllGroceryCategories();
      closeConfirmation();
      toast(t("grocery-settings-removecat-toast"), {
        duration: 2000,
        position: "top-center",
        style: {
          backgroundColor: "pink",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    }
  };

  useEffect(() => {
    return () => {};
    //@ts-ignore
  }, []);

  const styles = {
    button: {
      fontSize: 16,
      // backgroundColor: "#3e98c7",
      backgroundColor: "yellow",
      borderRadius: 5,
      width: "90%",
      paddingTop: 15,
      paddingBottom: 15,
      fontFamily: "inherit",
      color: "black",
    },
    buttonClose: {
      fontSize: 16,
      backgroundColor: "lightgray",
      borderRadius: 5,
      width: "90%",
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "inherit",
      color: "black",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={{ overlay: { zIndex: 100 } }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          marginTop: -30,
          marginRight: 40,
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          size="1x"
          onClick={close}
          style={{ marginTop: 15, cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3 style={{ textAlign: "center" }}>{t("grocery-settings-title")}</h3>
        <button
          style={styles.button}
          onClick={() => confirmationLaunch("AddEverything")}
        >
          {t("grocery-settings-addeverything")}
          <br />({t("grocery-settings-recommended")})
        </button>

        {isSpinner ? (
          <div>
            <br />
            <Spinner />
            <p style={{ fontSize: 14 }}>{t("grocery-settings-loading")} ...</p>
          </div>
        ) : (
          <>
            {" "}
            <p style={{ textAlign: "center", fontSize: 18 }}>
              {t("grocery-settings-advanced")}
            </p>
            <button
              style={styles.button}
              onClick={() => confirmationLaunch("AddItems")}
            >
              {t("grocery-settings-additems")}
              <br /> ({t("grocery-settings-additems-info")})
            </button>
            <div style={{ paddingTop: 5 }} />
            <button
              style={styles.button}
              onClick={() => confirmationLaunch("AddCategories")}
            >
              {t("grocery-settings-addcat")} <br /> (
              {t("grocery-settings-addcat-info")})
            </button>
            <div style={{ paddingTop: 5 }} />
            <button
              style={styles.button}
              onClick={() => confirmationLaunch("RemoveItems")}
            >
              {t("grocery-settings-removeitems")}
            </button>
            <div style={{ paddingTop: 5 }} />
            <button
              style={styles.button}
              onClick={() => confirmationLaunch("RemoveCategories")}
            >
              {t("grocery-settings-removecat")}
            </button>
            <div style={{ paddingTop: 5 }} />
          </>
        )}
        <br />
        <button onClick={close} style={styles.buttonClose}>
          {t("common-button-close")}
        </button>
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        text={confirmationText}
        onClickCancel={closeConfirmation}
        onClickConfirm={confirmationAccepted}
      />
    </Modal>
  );
};
