import { useState, useEffect } from "react";

import { useAuth } from "Authentication";
//import { Link } from "react-router-dom";
//import { DiApple, DiAndroid } from "react-icons/di";
//import { Form, Button, Card, Alert } from "react-bootstrap"
//import { GoogleLoginButton } from "./GoogleLoginButton"
//import { FacebookLoginButton } from "./FacebookLoginButton"
//import { TwitterLoginButton } from "./TwitterLoginButton"
//import { EmailLinkLoginButton } from "./EmailLinkLoginButton"
//import { PhoneLoginButton } from "./PhoneLoginButton"
import { GuestLoginButton } from "./GuestLoginButton";
//import { Features } from "./Features"
//import logo from "../../logo512.png";
//import videoScreenshot from "../../Assets/VideoScreenShot.png";
//@ts-ignore
//import promovideo from "../../Assets/dopenotes_promo_780.mp4";
//@ts-ignore
//import gettingstartedvideo from "../../Assets/dopenotes_gettingstarted.mp4"
//import classes from "./AnonymousSplash.module.css";
import "./ImageSlider.css";
import { ConfirmationModal } from "Common";
//import Fade from "react-reveal/Fade";
//import FadeIn from "react-fade-in/lib/FadeIn";
//import LightSpeed from 'react-reveal/LightSpeed'
//import ImageGallery from 'react-image-gallery';
import { Button, Modal } from "Common";
import classes from "./AnonymousSplash.module.css";
//import ReactPlayer from "react-player";
//import { YoutubeEmbed } from "Splash/YoutubeEmbed"
//import "react-image-gallery/styles/css/image-gallery.css";

export const NativeSplash = () => {
  const {
    loginApple,
    loginFB,
    loginGO,
    loginTW,
    loginLink,
    loginPhone,
    loginEmailPassword,
    createEmailPassword,
    resetEmailPassword,
    loginGuest,
  } = useAuth();
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  // const [errorMessages, setErrorMessages] = useState({});
  //const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("returningUser");
  const [isCreateNewUserOpen, setIsCreateNewUserOpen] = useState(false);
  const [emailPasswordReset, setEmailPasswordReset] = useState("");
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmationGuest, setConfirmationGuest] = useState(false);
  const [isNative, setIsNative] = useState(true);
  const [isIOS, setIsIOS] = useState(false);
  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();

    if (!isCreateNewUserOpen) {
      loginEmailPassword(email, password);
    } else {
      createEmailPassword(email, password);
    }
    //setIsCreateNewUserOpen(false)
  };

  const images = [
    {
      originalTitle: "",
      original: "./img/3-phones-dn.png",
      //   description: 'Habit Tracker',
    },
    {
      originalTitle: "Build Good Habits",
      original: "./img/habittracker.png",
      // description: 'Start building good habits',
    },
    {
      original: "./img/journal_dn.png",
      //  description: 'Quickly record your day and review it later',
    },

    {
      original: "./img/shopping.png",
      // description: 'Create and share shopping list',
    },
  ];

  const lostPasswordReset = (e) => {
    e.preventDefault();
    resetEmailPassword(emailPasswordReset);
    setIsPasswordReset(false);
    setEmailPasswordReset("");
  };

  const CloseModal = () => {
    setIsCreateNewUserOpen(false);
  };

  const showVideo = () => {
    if ((window as any).isNative) {
      // Post the message back to expo

      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "ExternalRecipeURL",
          url: "https://youtu.be/d9xfv4nMBDQ",
        })
      );
    } else {
      // window.location.href = recipe.recipeUrl
      window.open("https://youtu.be/d9xfv4nMBDQ", "_blank");
    }
  };

  useEffect(() => {
    if ((window as any).isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    if ((window as any).isIOS) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }

    return () => {};
  }, []);

  return (
    <article
      style={{
        fontFamily: "Kalam",
        lineHeight: 1.5,
        boxSizing: "inherit",
      }}
    >
      <div style={{ marginTop: "0px" }}>
        {/* <h1 style={{textAlign: "center", marginBottom: -15}}>Welcome to DopeNotes.io BETA</h1>
      <h4 style={{textAlign: "center", paddingBottom: 10}}>Share To Dos, Notes, and Recipes</h4>*/}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflowY: "hidden",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: 28,
              textAlign: "center",
              color: "black",
            }}
          >
            Stay Motivated, Stay Organized{" "}
          </p>
          <p
            style={{
              fontSize: 22,
              textAlign: "center",
              color: "black",
              marginTop: -20,
            }}
          >
            Grocery List, Meal Planner, Journal, Habit Tracker and Much More!
          </p>

          {/*}    <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "blue",
              cursor: "pointer",
              fontSize: 22,
              fontWeight: "bold",
            }}
            onClick={() => setConfirmModalOpen(true)}
          >
            Change Language? Click here.
          </div>*/}

          <div style={{ paddingBottom: 0, marginTop: 0 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
                border: 1,
                borderColor: "black",
                borderRadius: 20,
                paddingLeft: 70,
                paddingRight: 70,
                paddingTop: 0,
                paddingBottom: 0,
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: "bold",
                  marginTop: 10,
                }}
              >
                {!isPasswordReset ? "Sign In" : "Password Reset"}
              </h2>

              {!isPasswordReset ? (
                <>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <label>Email </label>
                      <input
                        style={{ width: 275 }}
                        type="email"
                        name="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div style={{ paddingTop: 15 }}>
                      <label>Password </label>
                      <input
                        style={{ width: 275 }}
                        type="password"
                        name="pass"
                        required
                        minLength={6}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      {userType === "newUser" ? (
                        <input type="submit" value="Register" />
                      ) : null}
                      {userType === "returningUser" ? (
                        <input
                          type="submit"
                          value="Sign In"
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 30,
                            fontFamily: "inherit",
                          }}
                        />
                      ) : null}
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "blue",
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsCreateNewUserOpen(true)}
                    >
                      Create New Account
                    </p>

                    <p
                      style={{
                        textAlign: "center",
                        color: "blue",
                        fontSize: 16,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsPasswordReset(true)}
                    >
                      Reset Password
                    </p>
                  </form>

                  <div style={{ paddingTop: 10 }} />
                  <button
                    onClick={() => setConfirmationGuest(true)}
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      borderRadius: 30,
                      backgroundColor: "violet",
                      width: 200,
                      color: "white",

                      fontSize: 16,
                      fontFamily: "inherit",
                      border: 0,
                      cursor: "pointer",
                    }}
                  >
                    Guest Login
                  </button>
                </>
              ) : null}
              {isPasswordReset ? (
                <form onSubmit={lostPasswordReset}>
                  <input
                    style={{ width: "95%" }}
                    placeholder="name@email.com"
                    type="email"
                    name="email"
                    value={emailPasswordReset}
                    required
                    onChange={(e) => setEmailPasswordReset(e.target.value)}
                  />
                  <input type="submit" value="Reset" />
                  <p
                    onClick={() => setIsPasswordReset(false)}
                    style={{
                      textAlign: "center",
                      color: "blue",
                      fontSize: 16,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Back
                  </p>
                </form>
              ) : null}
            </div>
          </div>

          <section>{/*   <Features />*/}</section>
        </div>
        <Modal isOpen={isCreateNewUserOpen} onRequestClose={CloseModal}>
          <Modal.Body>
            <div
              style={{
                padding: 40,
                fontFamily: "Kalam",
                fontSize: "inherit",
              }}
            >
              <Modal.Header>
                <h2>Create Account</h2>
              </Modal.Header>
              <div>
                <form onSubmit={handleSubmit}>
                  <div style={{ paddingBottom: 20 }}></div>

                  <div>
                    <label>Email </label>
                    <input
                      type="email"
                      name="email"
                      style={{ width: "98%" }}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div style={{ paddingTop: 10 }}>
                    <label>
                      Password{" "}
                      <span style={{ fontSize: 16, fontWeight: "normal" }}>
                        {" "}
                        (6+ characters required)
                      </span>
                    </label>
                    <input
                      type="password"
                      name="pass"
                      style={{ width: "98%" }}
                      required
                      minLength={6}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <button
                      type="submit"
                      value="Register"
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        borderRadius: 30,
                        backgroundColor: "limegreen",
                        fontWeight: "bold",
                        color: "black",
                        width: "100%",
                        fontSize: 20,
                        fontFamily: "inherit",
                        border: 0,
                        cursor: "pointer",
                      }}
                    >
                      Register
                    </button>
                  </div>
                </form>
                <br />
                <hr />

                <div style={{ paddingTop: 10 }} />
                <button
                  onClick={() => setConfirmationGuest(true)}
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    borderRadius: 30,
                    backgroundColor: "violet",
                    color: "black",
                    width: "100%",
                    fontSize: 20,
                    fontFamily: "inherit",
                    border: 0,
                    cursor: "pointer",
                  }}
                >
                  Guest Login
                </button>

                <div style={{ paddingTop: 10 }} />
                <button
                  onClick={CloseModal}
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    borderRadius: 30,
                    backgroundColor: "coral",
                    color: "black",
                    width: "100%",
                    fontSize: 20,
                    fontFamily: "inherit",
                    border: 0,
                    cursor: "pointer",
                  }}
                >
                  Login
                </button>

                <p style={{ marginTop: 0, paddingTop: 20 }}>
                  *30 day free trial
                  <br />
                  *No credit card required for sign up
                  <br />
                  *Accounts may be shared with family and friends
                  <br />
                  *We will never sell your data
                  <br />
                  *No banner or pop-up ads ever
                  <br />
                  *Subscriptions are processed via Apple or Google Play Stores
                  <br />
                  *You can cancel subscriptions at any time
                </p>

                <h3>
                  We advise families and households to create a universal login
                  (e.g. smithfamily@gmail.com) to stay synchronized across all
                  devices when sharing. There's no extra fee for using a shared
                  login.
                </h3>
                <hr />
                <h3>Terms and Conditions</h3>

                <p>
                  Terms: <br />
                  <a href="https://alohaplanner.app/terms">
                    https://alohaplanner.app/terms
                  </a>
                </p>

                <p>
                  Privacy: <br />
                  <a href="https://alohaplanner.app/privacy">
                    https://alohaplanner.app/privacy
                  </a>
                </p>
                {isNative ? (
                  <div>
                    {isIOS ? (
                      <p>
                        Apple/IOS EULA: <br />
                        <a
                          href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                          target="_blank"
                        >
                          https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
                        </a>
                      </p>
                    ) : (
                      <>
                        <p>
                          Apple/IOS EULA: <br />
                          <a
                            href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                            target="_blank"
                          >
                            https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
                          </a>
                        </p>
                        <p>
                          Google/Android:
                          <br />
                          <a
                            href="https://play.google.com/intl/en_us/about/play-terms/index.html"
                            target="_blank"
                          >
                            https://play.google.com/intl/en_us/about/play-terms/index.html
                          </a>
                        </p>
                      </>
                    )}
                  </div>
                ) : null}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 0,
                  }}
                >
                  {" "}
                  <button
                    style={{
                      width: 150,
                      backgroundColor: "transparent",
                      border: 0,
                    }}
                    onClick={CloseModal}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        color: "blue",
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    >
                      Back
                    </h4>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ConfirmationModal
          isOpen={confirmModalOpen}
          text="Would you like to switch to another language? By clicking 'Confirm', we will activate Google Translate to translate the content on all pages. Please note that we cannot guarantee the accuracy of the translation or ensure proper formatting. To proceed, select your preferred language and then minimize the translation bar to view our header properly."
          onClickCancel={() => setConfirmModalOpen(false)}
          onClickConfirm={() =>
            window.open(
              "https://dopenotes-io.translate.goog/?_x_tr_sch=http&_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp"
            )
          }
        />
        <ConfirmationModal
          isOpen={confirmationGuest}
          text="Aloha! Before you proceed, we would like to remind you of a few important points. The Guest login feature is designed to be anonymous and can only be used on a single device. It's essential to keep in mind that logging out will result in the loss of your notes since we don't have any login credentials associated with your account.

          If you plan to use our service on multiple devices or are interested in purchasing a subscription, we strongly recommend linking your account with an email address through the settings menu. This will ensure a seamless experience and provide added security for your data. Thank you for choosing our platform!"
          onClickCancel={() => setConfirmationGuest(false)}
          onClickConfirm={() => {
            loginGuest();
            setIsButtonSelected(true);
          }}
        />
      </div>
    </article>
  );
};
