import { useEffect } from "react";
import {
  faBolt,
  faCheck,
  faEnvelope,
  faEye,
  faEyeSlash,
  faListCheck,
  faInbox,
  faTrash,
  faListSquares,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, SetStateAction, useState } from "react";
import { CategoryEditModalToDo } from "../CategoryModal/CategoryEditModalToDo";
import { Button } from "Common";
import { ItemModel, useItemsToDo } from "../../index";
import { ToggleSwitch } from "Common/ToggleSwitch/ToggleSwitch";
import { QuickAddModal } from "./QuickAddModal";
import { ConfirmationModal } from "Common";
import classes from "./ListActions.module.css";
import { useTranslation } from "react-i18next";

interface ListActionsBarProps {
  // disableClearCompleted: boolean;
  items: ItemModel[];
  showCompleted: boolean;
  showAllCat: boolean;
  clearCompleted: () => void;
  emailList: () => void;
  exportSMS: () => void;
  setShowCompleted: Dispatch<SetStateAction<boolean>>;
  setShowAllCat: Dispatch<SetStateAction<boolean>>;
  isDarkMode: boolean;

  //new
  isAddingNewItem: boolean;
  setIsAddingNewItem: () => void;
  isTabClosed: boolean;
  LengthCheckedItems: number;
  setIsTabClosed: Dispatch<SetStateAction<boolean>>;
}

export const ListActionsBar = (props: ListActionsBarProps) => {
  const {
    //  disableClearCompleted,
    items,
    showCompleted,
    showAllCat,
    clearCompleted,
    emailList,
    exportSMS,
    setShowCompleted,
    setShowAllCat,
    isDarkMode,
    isAddingNewItem,
    setIsAddingNewItem,
    isTabClosed,
    setIsTabClosed,
    LengthCheckedItems,
  } = props;
  useItemsToDo();
  const [modalOpen, setModalOpen] = useState(false);
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  //const [isTabClosed, setIsTabClosed] = useState(false);
  const [isNative, setIsNative] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const { t } = useTranslation();
  const hideItCompleted = () => {
    // setShowCompleted((old) => !old);
    setShowCompleted((prevState) => !prevState);
    // console.log("What is it here", showCompleted)
    localStorage.setItem("ShowCompletedToDo", JSON.stringify(!showCompleted));
    //quick fix below to update the state.  Need to get this fixed.
    setShowAllCat((old) => !old);
  };

  const hideItCategories = () => {
    setShowAllCat((old) => !old);
    // console.log("What is it here", showAllCat)
    localStorage.setItem("ShowAllCatToDo", JSON.stringify(!showAllCat));
  };

  const updateTabStatus = () => {
    setIsTabClosed((old) => !old);
    // console.log("What is it here", showAllCat)
    localStorage.setItem("tabstatus", JSON.stringify(!showAllCat));
  };

  useEffect(() => {
    if ((window as any).isNative) {
      // Post the message back to expo
      setIsNative(true);
    }
    return () => {};
  }, [showCompleted, showAllCat]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Allow wrapping on smaller screens
          gap: "10px",
          marginBottom: "20px",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}
      >
        {/* Toggle Completed Items Button */}
        <button
          onClick={hideItCompleted}
          style={{
            width: "100px", // Ensure uniform button width
            height: "50px",
            padding: "10px", // Consistent padding
            fontSize: "14px",
            textAlign: "center", // Center text
            backgroundColor: showCompleted ? "#007bff" : "#6c757d",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center", // Center icon and text horizontally
            alignItems: "center",
            gap: "8px",
          }}
        >
          {showCompleted ? "Showing ✅" : "Hiding ✅"}
        </button>

        {/* Toggle Empty Lists Button */}
        <button
          onClick={hideItCategories}
          style={{
            width: "100px", // Ensure uniform button width

            height: "50px",
            padding: "10px", // Consistent padding
            fontSize: "14px",
            textAlign: "center", // Center text
            backgroundColor: showAllCat ? "#007bff" : "#6c757d",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center", // Center icon and text horizontally
            alignItems: "center",
            gap: "8px",
          }}
        >
          {showAllCat ? "Showing Empty" : "Hiding Empty"}
        </button>

        {/* Compile Email Button */}
        <button
          onClick={() => setShareModal(true)}
          style={{
            width: "100px", // Ensure uniform button width
            height: "50px", // Set consistent button height
            fontSize: "14px",
            textAlign: "center", // Center text

            color: "white",
            backgroundColor: "#007bff",
            border: "none", // Add border for consistent sizing
            borderRadius: "5px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center", // Center icon horizontally
            alignItems: "center",
          }}
        >
          {" "}
          Share
        </button>
        <button
          onClick={() => setIsCatEditModalOpen(true)}
          style={{
            width: 100,
            // Ensure uniform button width
            height: "50px", // Set consistent button height
            fontSize: "14px",
            textAlign: "center", // Center text

            color: "white",
            backgroundColor: "#007bff",
            border: "none", // Add border for consistent sizing
            borderRadius: "5px",
            cursor: "pointer",
            //  display: "flex",
            // justifyContent: "center", // Center icon horizontally
            // alignItems: "center",
          }}
        >
          List Manager
        </button>
      </div>
      <div
        style={{ textAlign: "center", fontStyle: "italic", paddingBottom: 20 }}
      >
        Tap any list name below to view its items
      </div>
      {shareModal ? (
        <div className="game-modal-overlay" style={{ zIndex: 100 }}>
          <div className="game-modal" style={{ width: "75%" }}>
            <h2>Share</h2>
            <p>How would you like to share your uncompleted items:</p>
            <button onClick={emailList}>Email</button>
            <button onClick={exportSMS}>SMS</button>

            <button
              onClick={() => setShareModal(false)}
              className="game-close-button"
            >
              Close
            </button>
          </div>
        </div>
      ) : null}
      {isCatEditModalOpen ? (
        <CategoryEditModalToDo
          isOpen={isCatEditModalOpen}
          close={() => setIsCatEditModalOpen(false)}
          isDarkMode={isDarkMode}
        />
      ) : null}
    </>
  );
};
