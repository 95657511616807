import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import classes from "../notes.module.css";
import { useTranslation } from "react-i18next";

export const Search = ({ handleSearchNote }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const onSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    if (value.length >= 3) {
      handleSearchNote(value);
    } else {
      // Optionally, handle the case when input is less than 3 characters
      // For example, you can clear the search results or show a message
      handleSearchNote(""); // This assumes that passing an empty string clears the search
    }
  };

  return (
    <div className={classes.search}>
      <MdSearch
        className="search-icons"
        size="1.3em"
        style={{ paddingLeft: 10 }}
      />
      <input
        value={searchText}
        onChange={onSearchChange}
        style={{ fontSize: "inherit", fontFamily: "inherit" }}
        type="text"
        placeholder={t("common-placeholder-search")}
      />
    </div>
  );
};
