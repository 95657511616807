import {
  faBell,
  faClose,
  faExclamationCircle,
  faInfoCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserContext } from "Authentication";
import { CategoryModel, ItemModel } from "Lists";
import { useState, useEffect } from "react";
import { Item } from "../Common/Item";
//import { useAuth } from "../../../Authentication/AuthContext";
import classes from "./ListItem.module.css";
import { SortableHandle } from "react-sortable-hoc";
//import TextareaAutosize from "react-textarea-autosize";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CustomNotificationMyList } from "Reminders/CustomerNotificationMyList";
import { ConfirmationModal } from "Common";
import { Button, Modal } from "Common";
import moment from "moment";
import { useItemsToDo } from "ListToDo/useItemsToDo";
import { doc, updateDoc } from "firebase/firestore";
import { CategoryButton } from "Lists/List/Common";

export interface ListItemProps {
  item: ItemModel;
  category: CategoryModel | undefined;
  onClickCategory: () => void;

  onClickItem: () => void;
}

export const ListItemToDo = (props: ListItemProps) => {
  const { item, category, onClickCategory, onClickItem } = props;
  const { getPrivateToDoItems } = useUserContext();
  const user = useUserContext();
  const [isDone, setIsDone] = useState(item.completed);
  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const DragHandle = SortableHandle(() => (
    <span style={{ cursor: "pointer" }}>::::</span>
  ));
  const { removeItem, setSortIndex } = useItemsToDo();
  const history = useHistory();

  const handleChange = async () => {
    // ToDoItemsUpdate(item.id, !isDone, Date(), user.user.name);
    setIsDone(!isDone);
    toast("Item Status Updated", {
      duration: 2000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
    const itemsRef = await getPrivateToDoItems();
    const itemRef = doc(itemsRef, item.id);
    await updateDoc(itemRef, {
      completed: !isDone,
      lastCompleted: Date(),
      completedBy: user.user.name,
    });
  };

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  const truncateNotes = (text) => {
    const MAX_LENGTH = 120;
    // Trim the text to the first 120 characters
    const displayText =
      text.length > MAX_LENGTH ? text.substring(0, MAX_LENGTH) + "..." : text;
    return displayText;
  };

  const convertDate = (date) => {
    var update;
    var today = new Date();
    var color;

    if (date !== undefined) {
      update = new Date(date.seconds * 1000);
      update = moment(new Date(update)).format("MMMM DD, YYYY");
      var updateCompare = moment(new Date(update)).format("YYYYMMDD");
      var todayCompare = moment(new Date(today)).format("YYYYMMDD");
      color = updateCompare > todayCompare ? "green" : "firebrick";

      return (
        <span
          style={{
            fontSize: 10,
            textDecorationLine: isDone ? "line-through" : "",
          }}
        >
          Due: <span style={{ color: color }}>{update}</span> @{item.dueTime}
        </span>
      );
    } else {
      return (
        <span
          style={{
            fontSize: 10,
            textDecorationLine: isDone ? "line-through" : "",
          }}
        >
          Due On: Set w/Alarm icon
        </span>
      );
    }
  };

  const DeleteItemConfirmation = () => {
    removeItem(item.id);
  };

  useEffect(() => {
    setIsDone(item.completed);

    return () => {};
  }, [item]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          paddingBottom: 10,
        }}
      >
        <FontAwesomeIcon
          onClick={() => setConfirmModalOpen(true)}
          style={{
            marginBottom: 1,

            color: "darkblue",
            paddingRight: 10,
            cursor: "pointer",
            alignSelf: "center",
          }}
          icon={faTrashCan}
          size="sm"
        />

        <FontAwesomeIcon
          style={{
            marginBottom: 1,

            color: "darkblue",
            paddingRight: 5,
            cursor: "pointer",
            alignSelf: "center",
          }}
          icon={faBell}
          size="sm"
          onClick={() => setIsSchedulingReminder(true)}
        />
        <CategoryButton
          buttonColour={category?.colour}
          onClick={onClickCategory}
        />
        <Link to={"/todo/" + item.id}>
          <FontAwesomeIcon
            style={{
              //marginBottom: 1,

              color: "darkblue",
              paddingRight: 10,
              paddingLeft: 5,
              cursor: "pointer",
              alignSelf: "center",
            }}
            icon={faInfoCircle}
            size="sm"
            // onClick={() => setIsSchedulingReminder(true)}
          />
        </Link>
        <DragHandle />
      </div>

      <div className={classes.content}>
        <input
          type="checkbox"
          checked={isDone}
          style={{ marginTop: 0 }}
          onChange={() => {
            handleChange();
          }}
          //  className={classes.details}
        />
        {item.flagged ? (
          <div style={{ alignSelf: "center", marginLeft: 5 }}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={"var(--colour-danger)"}
              className={classes.flag}
            />
          </div>
        ) : null}
        <Link className={classes.details} to={"/todo/" + item.id}>
          <div
            className={classes.details}
            // onClick={() => handleChange()}
            style={{ userSelect: "none", paddingLeft: 7 }}
          >
            <>
              {isDone ? (
                <div>
                  <div
                    style={{
                      // width: "95%",
                      fontSize: "1.10em",
                      backgroundColor: "transparent",
                      lineHeight: 1.2,
                      //     color: "navy",
                      paddingLeft: 3,
                      paddingRight: 2,
                      border: 0,
                      verticalAlign: "center",
                      userSelect: "none",
                      // fontWeight: "bold",
                      textDecorationLine: "line-through",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      // width: "95%",
                      fontSize: "1.10em",
                      backgroundColor: "transparent",
                      userSelect: "none",
                      lineHeight: 1.2,
                      //   color: "navy",
                      paddingLeft: 3,
                      paddingRight: 2,
                      border: 0,
                      verticalAlign: "center",
                      // fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </div>
                </div>
              )}
            </>
            {item.notes != null ? (
              isDone ? (
                <span
                  style={{
                    textDecorationLine: "line-through",
                    textDecorationStyle: "solid",
                    paddingLeft: 3,
                    backgroundColor: "transparent",
                    paddingRight: 2,
                    paddingBottom: 10,
                    lineHeight: 1.2,
                    paddingTop: 10,
                    //  color: "navy",
                    // marginTop: -12,
                    fontSize: 12,
                    fontFamily: "inherit",
                  }}
                >
                  {truncateNotes(item.notes)}
                </span>
              ) : (
                <span
                  style={{
                    paddingLeft: 3,
                    paddingRight: 2,
                    lineHeight: 1.2,
                    textDecorationLine: "",
                    textDecorationStyle: "solid",
                    // backgroundColor: "lightblue",
                    //color: "grey",
                    // marginTop: -2,
                    paddingBottom: 10,
                    paddingTop: 10,
                    color: "black",
                    fontSize: 12,
                    fontFamily: "inherit",
                  }}
                >
                  {item.notes}
                </span>
              )
            ) : null}
            {isDone ? (
              <div
                style={{
                  //  textDecorationLine: "line-through",
                  textDecorationStyle: "solid",
                  paddingLeft: 3,
                  paddingRight: 2,
                  //   color: "navy",
                  marginTop: -2,

                  //  fontSize: 16,
                  fontFamily: "inherit",
                }}
              >
                {convertDate(item.dueDate)}
              </div>
            ) : (
              <small
                style={{
                  paddingLeft: 3,
                  paddingRight: 2,
                  textDecorationLine: "",
                  textDecorationStyle: "solid",
                  fontFamily: "inherit",
                  //  fontSize: 16,
                  marginTop: -2,
                  //  color: "navy",
                }}
              >
                {convertDate(item.dueDate)}
              </small>
            )}
          </div>
        </Link>
      </div>
      {/*   {!item.completed ? (
      <div style={{fontSize:10, marginLeft: 42, paddingTop: 5, fontStyle:"italic"}}>Added: {item.addedDate}</div>) : 
      <div style={{fontSize:10, marginLeft: 42,}}>Completed</div> }
     */}

      <Modal isOpen={isSchedulingReminder} style={{ overlay: { zIndex: 100 } }}>
        <div style={{ paddingTop: 5, paddingBottom: 20, paddingLeft: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "right",
              paddingLeft: 20,
              marginTop: -40,
              marginRight: 10,
              paddingBottom: 10,
              paddingTop: 30,
            }}
          >
            <button
              onClick={CloseMenu}
              style={{
                backgroundColor: "transparent",
                border: 0,
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faClose} size="1x" />
            </button>
          </div>

          <CustomNotificationMyList
            title={item.name}
            item={item}
            CloseMenu={CloseMenu}
            setDate={item.dueDate}
          />
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                fontSize: "inherit",
                fontFamily: "inherit",
                color: "white",
                backgroundColor: "#1e79c3",
                borderColor: "white",
                padding: 10,
                borderRadius: 10,
                paddingRight: 30,
                paddingLeft: 30,
                width: 200,
                marginTop: 15,
              }}
              onClick={CloseMenu}
            >
              Close Window
            </button>
          </div>
          <div style={{ paddingBottom: 150 }} />
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        //@ts-ignore
        text={"Are you sure you want to remove " + item.name + "?"}
        onClickCancel={() => setConfirmModalOpen(false)}
        onClickConfirm={DeleteItemConfirmation}
      />
    </>
  );
};
