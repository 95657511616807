import React, {
  createRef,
  FormEvent,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import CreatableSelect from "react-select/async-creatable";

//import { useCategories } from "../../CategoriesContext"
//import { useCategorySubscription } from "../../../Lists/useCatgorySubscription";
import { ItemModel } from "../../models";

import { Item } from "../Common/Item";
//import { CategoryModal } from "../CategoryModal";
//import { CategoryEditModal } from "../CategoryModal/CategoryEditModal";
//import { CategoryAddItemModal } from "../CategoryModal/CategoryAddItemModal";
import { useItemInput } from "./useItemInput";
import { LoadOptionsCallback, SelectRef } from "./types";
import { useTranslation } from "react-i18next";

import classes from "./AddItem.module.css";

export interface AddItemsProps {
  addedItems: ItemModel[];
  allItems: ItemModel[];
  category: string;
  categoryName: string;
  isDarkMode: boolean;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setCategoryName: React.Dispatch<React.SetStateAction<string>>;
  setIsAddingNewItemCompleted: () => void;
  disableCategorySelect?: boolean;
  categories: any[];
}

export const AddItemCat = forwardRef<SelectRef, AddItemsProps>((props, ref) => {
  const {
    addedItems,
    category,
    allItems,
    setCategory,
    isDarkMode,
    categoryName,
    setCategoryName,
    setIsAddingNewItemCompleted,
    disableCategorySelect,
    categories,
  } = props;
  const { alreadyAdded, isValid, previouslyAdded, value, addItem, setValue } =
    useItemInput(addedItems, category, setCategory, allItems);
  const inputRef = createRef<SelectRef>();
  useImperativeHandle(ref, () => inputRef.current as SelectRef);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
  const [isCatAddItemModalOpen, setIsCatAddItemModalOpen] = useState(false);
  //const categories = useCategories()
  //const { categories } = useCategorySubscription();
  const [addIT, setAddIT] = useState(false);
  const [quantity, setQuantity] = useState("1");
  const [newList, setNewList] = useState([]);
  const { t } = useTranslation();

  var quickAddLock = 0;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.data.label === "Create" ? "bold" : "bold",
      color: "blue",
      fontSize: "1.2em",
      textDecoration: "underline",
    }),
  };

  const formatCreateLabel = (inputValue) => {
    return (
      <span
        style={{
          fontWeight: "bold",
          backgroundColor: "yellow",
          padding: 5,
          borderRadius: 5,
        }}
      >
        {t("grocery-list-create-item")}: "<em>{inputValue}</em>"
      </span>
    );
  };

  const handleCreateOption = (inputValue) => {
    // console.log("Creating option:", inputValue);
    const selectedCategory = categories.find((c) => c.id === category);
    const newCatName = selectedCategory ? selectedCategory.name : "";
    setNewList([
      ...newList,
      { name: inputValue, list: newCatName, quantity: quantity },
    ]);
    addItem(inputValue, quantity, category);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    //   console.log("what is category on submit", category)
    //  console.log("what is the item name", value)
    event.preventDefault();
    addItem(value, quantity, category);

    setNewList([
      ...newList,
      { name: value, list: category, quantity: quantity },
    ]);
    setQuantity("1");
    //setIsAddingNewItemCompleted();
    inputRef.current?.focus();
  };

  const closeWindow = () => {
    setNewList([]);
    //  setCategoryName("");
    setIsAddingNewItemCompleted();
  };

  const options = useMemo(
    () =>
      allItems
        .map((i) => ({ value: i.name, label: i.name }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        ),
    [allItems]
  );

  const handleLoadOptions = (
    inputValue: string,
    callback: LoadOptionsCallback
  ) =>
    callback(
      options.filter((o) =>
        o.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );

  function quickAdd() {
    setAddIT(false);
    // console.log ("start", quickAddLock)
    if (value != "") {
      quickAddLock = quickAddLock + 1;

      if (quickAddLock == 1) {
        setTimeout(() => {
          quickAddLock = quickAddLock + 1;
          if (previouslyAdded) {
            addItem(value, quantity, category);

            //   console.log("re-add",value)
          } else {
            addItem(value, quantity, category);
            //  setIsCatAddItemModalOpen(true)
          }
          setAddIT(false);
        }, 300);
        //  setIsAddingNewItemCompleted();
        const selectedCategory = categories.find((c) => c.id === category);
        setNewList([
          ...newList,
          { name: value, list: selectedCategory, quantity: quantity },
        ]);
        // console.log("what is category", category);
        quickAddLock = quickAddLock + 1;
      } else {
        // console.log("end", quickAddLock);
      }
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={classes.form}
        style={{ backgroundColor: "lavender" }}
      >
        <h2 style={{ textAlign: "center" }}>
          {/* {t("generic-list-additem-addtext")}{" "} */}
          Add to{" "}
          <span
            style={{ color: "black", backgroundColor: "yellow", padding: 5 }}
          >
            {categoryName}
          </span>{" "}
        </h2>

        <div className={classes.actions}>
          <label style={{ paddingTop: 10, paddingBottom: 10 }}></label>
          {addIT && { isValid } ? quickAdd() : null}
        </div>

        <input
          type="text"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          style={{
            padding: 10,
            fontSize: "1em",
            width: 50,
            border: 1,
            borderRadius: 5,
            borderColor: "lightgrey",
            borderStyle: "inherit",
          }}
        />
        <span style={{ fontSize: ".8em", paddingLeft: 0, paddingRight: 5 }}>
          {" "}
          {t("grocery-list-how-many")}
        </span>
        <div style={{ paddingTop: 2 }} />
        <Item
          onClickCategory={() => setIsModalOpen(true)}
          categoryButton={false}
          buttonColour={
            categories.find((c) => c.id === category)?.colour ||
            "var(--colour-uncategorised)"
          }
        >
          <CreatableSelect
            placeholder={t("grocery-list-add-placeholder")}
            value={{ label: "", value }}
            onChange={(newValue) => {
              setValue(newValue?.value ?? "");
              setAddIT(true);
            }}
            loadOptions={handleLoadOptions}
            className={classes.myform}
            noOptionsMessage={() => null}
            onCreateOption={handleCreateOption}
            isClearable
            ref={inputRef}
            blurInputOnSelect={true}
            styles={customStyles}
            formatCreateLabel={formatCreateLabel}
          />
        </Item>
      </form>
      {newList.length >= 1 ? (
        <div
          style={{
            padding: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <label
            style={{
              color: "turquoise",
              textAlign: "center",
              paddingBottom: 10,
            }}
          >
            {t("generic-list-additem-newlyadded")}
          </label>
          {newList.map((i) => (
            <div
              key={Math.random()}
              style={{
                color: "snow",
                textAlign: "left",
                paddingLeft: 10,
                paddingRight: 10,
                lineHeight: 1,
                paddingBottom: 10,
              }}
            >
              {i.name}{" "}
              <span style={{ paddingLeft: 5, fontSize: 12 }}>
                {i.quantity != undefined ? "(" + i.quantity + ")" : "(1X)"}
              </span>
            </div>
          ))}
        </div>
      ) : null}
      <div
        style={{
          paddingTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          onClick={closeWindow}
          //  disabled={!isValid}
          //  className={classes.blinkingButton}
          style={{
            width: "100%",
            fontFamily: "inherit",
            fontSize: 18,
            color: "black",
            padding: 10,
            borderRadius: 5,
            backgroundColor: "lightgrey",
            fontWeight: "bold",
            maxWidth: 1000,
          }}
        >
          {newList.length >= 1
            ? t("common-button-close")
            : t("common-button-cancel")}
        </button>
      </div>
      {newList.length >= 1 ? (
        <p
          style={{
            textAlign: "center",
            padding: 10,
            color: "white",
            marginTop: -5,
            fontSize: 14,
          }}
        >
          {t("generic-list-additem-instruction")}
        </p>
      ) : null}
    </>
  );
});
