import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faTrashCan,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useFieldArray, useForm } from "react-hook-form";

import { Button, FormGroup, Modal } from "Common";
import { RecipeModel, useRecipes } from "Recipes";
import TextareaAutosize from "react-textarea-autosize";
import classes from "./WriteRecipe.module.css";
import { TagCreator } from "Common/TagGenerator/TagCreator";
import {
  BtnBold,
  BtnItalic,
  Editor,
  BtnBulletList,
  HtmlButton,
  BtnNumberedList,
  BtnUnderline,
  BtnStrikeThrough,
  EditorProvider,
  BtnClearFormatting,
  Toolbar,
} from "react-simple-wysiwyg";
import { useTranslation } from "react-i18next";

interface RecipeFormModel {
  name: string;
  ingredients: Array<Ingredient>;
  directions: string;
  recipeUrl?: string;
  tags?: Tags[];
}

interface Ingredient {
  name: string;
  measurement?: string;
}

interface Tags {
  text: string;
  color?: string;
}

interface WriteRecipeProps {
  recipe: RecipeModel;
  close: () => void;
  onSave: (recipe: RecipeModel) => void;
}

export const WriteRecipe = (props: WriteRecipeProps) => {
  const { recipe, close, onSave } = props;
  const [recipeDirections, setRecipeDirections] = useState(
    recipe.directions != undefined ? recipe.directions : ""
  );
  const [recipeUrl, setRecipeUrl] = useState(
    recipe.recipeUrl != undefined ? recipe.recipeUrl : ""
  );
  const [recipeTags, setRecipeTags] = useState(
    recipe.tags != undefined ? recipe.tags : []
  );
  const { control, formState, handleSubmit, register } =
    useForm<RecipeFormModel>({
      mode: "onChange",
      defaultValues: {
        ...recipe,
        tags: recipeTags,
        recipeUrl: recipeUrl,
        directions: recipeDirections,
        ingredients:
          recipe.ingredients?.map((i) => ({
            name: i.name,
            measurement: i.measurement,
          })) ?? [],
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "ingredients",
  });
  const { addRecipe, updateRecipe } = useRecipes();
  // const [directions, setDirections] = useState(recipe.directions)
  const { errors } = formState;
  const adding = !recipe.id;
  const { t } = useTranslation();
  const ingredientInput = useRef(null);
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const saveRecipe = async (data: RecipeFormModel) => {
    const modifiedRecipe: RecipeModel = {
      ...recipe,
      ...data,
      tags: recipeTags,
      directions: recipeDirections,
      recipeUrl: recipeUrl,
      ingredients:
        data.ingredients?.filter((i) => i.name !== "").map((i) => i) ?? [],
    };

    if (adding) {
      await addRecipe(modifiedRecipe);
      close();
    } else {
      await updateRecipe(modifiedRecipe);
      onSave(modifiedRecipe);
    }
  };

  const NewIngredient = () => {
    append({ name: "", measurement: "" });
  };

  const newIngredientField = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // console.log('do validate');
      NewIngredient();
    }
  };

  const tags = (pushTags) => {
    setRecipeTags(pushTags);
    //console.log("recipeTags", recipeTags);
  };

  const toolbar = {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], // add color and background tools
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["clean"],
    ],
  };

  const modules = {
    toolbar: toolbar,
  };

  useEffect(() => {
    return () => {};
  }, [recipeTags]);

  const [isTagsOpen, setIsTagsOpen] = useState(false);

  const openTags = (e) => {
    e.preventDefault();
    //@ts-ignore
    setIsTagsOpen(true);
  };

  const closeTags = (e) => {
    e.preventDefault();
    //@ts-ignore
    setIsTagsOpen(false);
  };

  return (
    <>
      <Modal.Header>
        {/* <h4>{adding ? "ADD NEW RECIPE" : recipe.name}</h4>*/}
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            margin: 0,
          }}
        >
          {" "}
          <button
            style={{
              backgroundColor: "transparent",
              borderStyle: "none",
              cursor: "pointer",
              marginTop: -30,
              marginRight: -10,
            }}
            onClick={close}
          >
            <FontAwesomeIcon icon={faClose} size="3x" />
          </button>
        </div>
      </Modal.Header>
      <form onSubmit={handleSubmit(saveRecipe)}>
        <FormGroup>
          <label
            htmlFor="name"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              color: "darkred",
              userSelect: "none",
            }}
          >
            {t("writerecipe-form-recipename")}
          </label>
          <input
            id="name"
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              //  color: "navy",
              height: 35,
              padding: 10,
              backgroundColor: "lightyellow",
              width: "95%",
            }}
            placeholder={t("writerecipe-form-name-placeholder")}
            {...register("name", { required: "required" })}
          />
          {errors.name ? (
            <small className={classes.invalid}>{errors.name.message}</small>
          ) : null}
        </FormGroup>
        <FormGroup>
          <label
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              color: "darkred",
              userSelect: "none",
            }}
          >
            {t("readrecipelanding-form-tags")}
          </label>
          <span style={{ display: "flex", flexFlow: "wrap" }}>
            {recipeTags.map((tag, index) => (
              <span
                key={index}
                style={{
                  listStyle: "none",
                  //   color: "navy",
                  fontSize: 14,
                  padding: 10,
                  borderStyle: "dashed",
                }}
              >
                {tag.text}
              </span>
            ))}
          </span>
          <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            <button onClick={openTags}>{t("writerecipe-form-addtags")}</button>
          </div>
        </FormGroup>

        <FormGroup>
          <label
            style={{
              paddingBottom: 10,
              paddingTop: 10,
              color: "darkred",
              userSelect: "none",
            }}
          >
            {t("readrecipelanding-form-directions")}
          </label>
          <EditorProvider>
            <Editor
              value={recipeDirections}
              placeholder={t("writerecipe-form-direction-placeholder")}
              containerProps={{
                style: {
                  backgroundColor: "snow",
                  color: "black",
                  resize: "both",
                  overflow: "auto",
                },
              }}
              onChange={(e) => setRecipeDirections(e.target.value)}
            >
              {" "}
              <Toolbar>
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnBulletList />
                <BtnNumberedList />
                <BtnStrikeThrough />
                <HtmlButton />
              </Toolbar>
            </Editor>
          </EditorProvider>
        </FormGroup>
        <FormGroup>
          <label
            htmlFor="recipeUrl"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              color: "darkred",
              userSelect: "none",
            }}
          >
            {t("readrecipelanding-recipeurl-label")}
          </label>
          <input
            id="recipeUrl"
            type="url"
            value={recipeUrl}
            onChange={(e) => setRecipeUrl(e.target.value)}
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              height: 35,
              border: 1,
              color: "black",
              backgroundColor: "lightyellow",
              padding: 10,
              width: "95%",
            }}
            placeholder="https://myfavoriterecipe.com"
            name="recipeUrl"
            className={classes.url}
          />
          <p style={{ fontSize: 12, marginTop: -5, userSelect: "none" }}>
            {t("writerecipe-form-html")}
          </p>
        </FormGroup>
        <FormGroup>
          <label
            style={{ paddingBottom: 10, paddingTop: 10, color: "darkred" }}
          >
            {t("readrecipelanding-form-ingredients")}
            <button
              type="button"
              onClick={() => append({ name: "" })}
              style={{
                fontFamily: "inherit",
                fontSize: "inherit",
                backgroundColor: "transparent",
                borderStyle: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" color="blue" />
            </button>
          </label>

          {fields.map((field, index) => (
            <div key={field.id} className={classes.ingredient}>
              <div style={{ paddingRight: 20 }}>
                {" "}
                <input
                  style={{
                    fontFamily: "inherit",
                    fontSize: 16,
                    //   color: "navy",
                    height: 35,
                    paddingLeft: 10,
                    backgroundColor: "lightyellow",
                  }}
                  {...register(`ingredients.${index}.measurement` as const)}
                  defaultValue={field.measurement}
                  //   onChange={(event) => CheckForEnterKey(event.target.value)}
                  placeholder={`${t(
                    "writerecipe-form-measurement-placeholder"
                  )} ${index + 1}`}
                />
              </div>
              <input
                onKeyDown={(e) => newIngredientField(e)}
                style={{
                  fontFamily: "inherit",
                  fontSize: 16,
                  //  color: "navy",
                  height: 35,
                  paddingLeft: 10,
                  backgroundColor: "lightyellow",
                }}
                {...register(`ingredients.${index}.name` as const)}
                defaultValue={field.name}
                //   onChange={(event) => CheckForEnterKey(event.target.value)}
                placeholder={` ${t(
                  "writerecipe-form-ingredient-placeholder"
                )} ${index + 1}`}
              />
              <Button type="button" onClick={() => remove(index)}>
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            </div>
          ))}
          {fields.length === 0 ? (
            <span style={{ userSelect: "none" }}>
              {" "}
              {t("readrecipelanding-form-noingredients")}
            </span>
          ) : null}
        </FormGroup>
        <div className={classes.actions}>
          <button
            onClick={close}
            type="button"
            style={{
              cursor: "pointer",
              fontFamily: "inherit",
              fontSize: "inherit",
              borderColor: "white",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            {t("common-button-close")}
          </button>
          <button
            type="submit"
            style={{
              cursor: "pointer",
              borderColor: "white",
              fontFamily: "inherit",
              fontSize: "inherit",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            {t("common-button-save")}
          </button>
        </div>
      </form>
      <Modal
        isOpen={isTagsOpen}
        onRequestClose={closeTags}
        style={{ overlay: { zIndex: 100 } }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            marginTop: -20,
            marginRight: 30,
          }}
        >
          <FontAwesomeIcon
            icon={faClose}
            size="2x"
            onClick={close}
            style={{ marginTop: 15, cursor: "pointer" }}
          />
        </div>
        <TagCreator pushTags={tags} data={recipeTags} />
        <div className={classes.actions}>
          <button
            style={{
              fontFamily: "inherit",
              fontSize: "inherit",
              // boxShadow: "2px 3px 3px grey, 0 0 5px #FCFBF4 inset",
              borderColor: "white",
              cursor: "pointer",
            }}
            onClick={closeTags}
            title="Close"
          >
            {t("common-button-close")}
          </button>
        </div>
      </Modal>
      <div style={{ paddingBottom: 150 }} />
    </>
  );
};
