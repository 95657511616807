import { useEffect, useState } from "react";
import { Content, Footer, Header } from "Layout";
import "./Fonts/Caveat/Caveat-VariableFont_wght.ttf";
import "./Fonts/Indie_Flower/IndieFlower-Regular.ttf";
import "./Fonts/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf";
import "./Fonts/Patrick_Hand/PatrickHand-Regular.ttf";
import "./Fonts/Cabin_Sketch/CabinSketch-Regular.ttf";
import "./Fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf";
import "./Fonts/Fondamento/Fondamento-Regular.ttf";
import "./Fonts/Homemade_Apple/HomemadeApple-Regular.ttf";
import "./Fonts/Josefin_Slab/JosefinSlab-VariableFont_wght.ttf";
import "./Fonts/Kalam/Kalam-Regular.ttf";
import "./Fonts/Oleo_Script/OleoScript-Regular.ttf";
import "./Fonts/Orbitron/Orbitron-VariableFont_wght.ttf";
import "./Fonts/Source_Code_Pro/SourceCodePro-VariableFont_wght.ttf";
import "./Fonts/Space_Grotesk/SpaceGrotesk-VariableFont_wght.ttf";
import "./Fonts/Ubuntu/Ubuntu-Regular.ttf";
import "./Fonts/Montserrat/Montserrat-VariableFont_wght.ttf";
import "./Fonts/VT323/VT323-Regular.ttf";
import "./Fonts/Xanh_Mono/XanhMono-Regular.ttf";
import classes from "./App.module.css";
//import { useNetworkState } from "react-use";
import { Breadcrumb } from "Layout/Header/Breadcrumbs";
import { disableNetwork, enableNetwork } from "firebase/firestore";
import { db, reconnectFirestore } from "Config";
import { usePageVisibility } from "Common/services/UsePageVisibility";

export const App = () => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  var getDarkMode = JSON.parse(localStorage.getItem("isdarkmode"));
  const [isDarkMode, setIsDarkMode] = useState(
    getDarkMode != undefined ? getDarkMode : false
  );

  const ChangeDarkMode = () => {
    // isDarkMode == true ? setIsDarkMode(false) : setIsDarkMode(false);
    localStorage.setItem("isdarkmode", JSON.stringify(isDarkMode));
  };

  // console.log("what is network state", useNetworkState());
  // console.log("what is the online status", navigator.onLine);

  const handleOnline = async () => {
    setIsOnline(true);
    // await enableNetwork(db);
    // console.log("Network enabled!");
  };

  const handleOffline = async () => {
    setIsOnline(false);
    // await disableNetwork(db);
    // console.log("Network disabled!");
  };

  const [lastHiddenTime, setLastHiddenTime] = useState(null);
  const reconnectTimeout = 5 * 60 * 1000;
  const isVisible = usePageVisibility();

  useEffect(() => {
    setIsOnline(navigator.onLine);
    const handleVisibilityChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Add event listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    getDarkMode = isDarkMode;
    ChangeDarkMode();

    if (
      !(window as any).isTrackingIOSDisable &&
      process.env.NODE_ENV != "development"
    ) {
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    //listen for background

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isColorPickerOpen, isDarkMode]);

  useEffect(() => {
    if ((window as any).isNative) {
      if (isVisible) {
        const currentTime = new Date().getTime();
        reconnectFirestore();
        /*  if (lastHiddenTime && currentTime - lastHiddenTime > reconnectTimeout) {
          // Reconnect Firestore if the app is native and has been in the background for more than 5 minutes
          reconnectFirestore();
        }*/
      } else {
        // setLastHiddenTime(new Date().getTime());
      }
    }
  }, [isVisible, lastHiddenTime]);

  return (
    <div className={classes.app} data-openreplay-obscured>
      <Header
        isColorPickerOpen={isColorPickerOpen}
        setIsColorPickerOpen={setIsColorPickerOpen}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        isOnline={isOnline}
        setIsOnline={setIsOnline}
      />
      <Breadcrumb color="snow" />

      <Content
        isColorPickerOpen={isColorPickerOpen}
        setIsColorPickerOpen={setIsColorPickerOpen}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        isOnline={isOnline}
        // stepCount={stepCount}
      />
      {/*  <Footer />*/}
    </div>
  );
};
