import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "./react-big-calendar.css";
//import "./react-big-calendar-addon-styles.css";
import { useUserContext } from "Authentication";
import { useEventSubscription } from "./useEventSubscription";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
//import { EventsModal } from "./modals/EventsModal";

import { EventsModalAddNew } from "./modals/EventsModalAddNew";
import { EventModel } from "./models/events";
import { DailyReminder } from "Reminders/DailyReminder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //faAdd,
  faBell,
  //faCalendarDay,
  //faClose,
  faListAlt,
  faPlusCircle,
  //faTrash,
} from "@fortawesome/free-solid-svg-icons";
//import { SubscriptionChecker } from "Authentication/SubscriptionChecker";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
import { CSVLink } from "react-csv";
import { Link, Route, useHistory } from "react-router-dom";
import { JournalLanding } from "./modals/JournalLanding";
import { useTranslation } from "react-i18next";

const localizer = momentLocalizer(moment);

var selectSlotStart = new Date();

export const MyJournal = ({ oldevents, isExpired, isDarkMode, isOnline }) => {
  const DnDCalendar = withDragAndDrop(Calendar);
  const { events } = useEventSubscription();
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
  const [isEventsModalEditOpen, setIsEventsModalEditOpen] = useState(false);

  const [isEventsModalAddNewOpen, setIsEventsModalAddNewOpen] = useState(false);
  const { addNewEvent, updateEventCal, addNewReminder } = useUserContext();
  const [selectedEvent, setSelectedEvent] = useState<EventModel>();
  const [searchText, setSearchText] = useState("");
  const [isNative, setIsNative] = useState(false);
  //const [icsURL, seticsURL] = useState("");
  //reminder and uploader
  const { t } = useTranslation();

  //from Calendar Setting, it'll change the start day of the week.
  const calendarStartDay =
    localStorage.getItem("calendarStartWeekDay") != undefined
      ? parseInt(localStorage.getItem("calendarStartWeekDay"))
      : 0;

  //console.log("what is day", calendarStartDay);

  moment.updateLocale("en", {
    week: {
      dow: calendarStartDay, // 1 = Monday is the first day of the week
    },
  });

  const calendarMessages = {
    today: t("common-calendar-today"),
    next: t("common-calendar-next"),
    previous: t("common-calendar-back"),
    month: t("common-calendar-month"),
    day: t("common-calendar-day"),
    agenda: t("common-calendar-agenda"),
  };

  const csvReport = {
    data: events,
    //headers: headers,
    filename: "DN-Export-Journals.csv",
  };

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };

  const handleSelectSlot = useCallback(({ start, end, id }) => {
    //  const title = window.prompt('What made you happy today?')

    selectSlotStart = start;
    //   console.log("what is the date", selectSlotStart)
    setIsEventsModalAddNewOpen(true);
  }, []);

  const handleClickItem = (event: EventModel) => {
    setSelectedEvent(event);
    history.push(`../journal/${event.id}`);
    //  setIsEventsModalOpen(true);
    /* setTimeout(() => {
      setIsEventsModalAddNewOpen(false);
    }, 100);*/
  };

  const handleClickItemMore = (event) => {
    setSelectedEvent(event);
    setIsEventsModalOpen(true);
    setTimeout(() => {
      setIsEventsModalAddNewOpen(false);
    }, 100);
    //console.log("what is event", event);
  };

  const mapToRBCFormat = (e) =>
    Object.assign({}, e, {
      title: RemoveHTMLTitle(e.title),
      start: e.start.toDate(),
      end: e.end.toDate(),
    });

  const customSlotPropGetter = () => {
    return {
      className: "slot",
      label: "All Day",
      style: {
        minHeight: "1vh",
      },
    };
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    //   console.log(event);
    // var backgroundColor = "#FBEF05";
    // console.log("what is the event", event.color);

    var backgroundColor = event.color != "" ? event.color : "#FBEF05";

    var style = {
      backgroundColor: backgroundColor,

      color: "black",
    };
    return {
      style: style,
    };
  };

  const convertDate = (date) => {
    const update = new Date(date.seconds * 1000);
    const updateFormatted = moment(update).format("MMMM DD, YYYY");
    //  const update = date.toDate()
    return updateFormatted;
  };

  const SearchResults = events.filter((item) => {
    return (
      item.title.toLowerCase().includes(searchText.toLowerCase()) ||
      item.description.includes(searchText.toLowerCase())
    );
  });

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if ((window as any).isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }

    return () => {};
  }, []);

  const [isSchedulingReminder, setIsSchedulingReminder] = useState(false);

  const CloseMenu = () => {
    setIsSchedulingReminder(false);
  };

  //new reminder start.  This is a work in progree, I need to update the APP for remove habit language first.

  const removeTags = (str) => {
    if (typeof str === "string") {
      return str.replace(/<[^>]+>/g, "");
    }
    return str;
  };

  const RemoveHTMLTitle = (data) => {
    const title = removeTags(data);
    const truncatedTitle = title.substring(0, 30);

    return truncatedTitle;
  };

  return (
    <Fragment>
      <FirebaseAnalytics />

      {!isNative ? (
        <div
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            marginRight: 20,
          }}
        >
          <CSVLink {...csvReport}>{t("journal-log-exportcsv")}</CSVLink>
        </div>
      ) : (
        <span></span>
      )}
      <div className="height600">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 30,
            flexDirection: "column",
            userSelect: "none",
          }}
        >
          <h2 className="h2SlideIn">{t("journal-log-title")} &#9997;</h2>

          <div style={{ alignContent: "center" }}>
            <button
              style={{
                // width: 60,
                borderRadius: 5,
                borderColor: "white",
                textAlign: "center",
                fontFamily: "inherit",
                fontSize: "inherit",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                color: "white",
                fontWeight: "bold",
                backgroundColor: "#3e98c7",
                cursor: "pointer",
              }}
              onClick={() => setIsEventsModalAddNewOpen(true)}
            >
              <FontAwesomeIcon
                style={{ marginLeft: 6 }}
                icon={faPlusCircle}
                size="lg"
              />
              <div
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  marginTop: 5,
                  color: "white",
                }}
              >
                {t("common-button-addnew")}
              </div>
            </button>

            <Link to={`../journal/listview`}>
              <button
                style={{
                  //  width: 60,
                  borderRadius: 5,
                  borderColor: "white",
                  textAlign: "center",
                  //   fontFamily: "ariel",
                  fontSize: "inherit",
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 10,
                  paddingBottom: 10,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#3e98c7",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ marginLeft: 0 }}
                  icon={faListAlt}
                  size="lg"
                />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                    marginTop: 5,
                    color: "white",
                  }}
                >
                  {t("journal-log-listview")}
                </div>
              </button>
            </Link>

            <button
              style={{
                // width: 60,
                borderRadius: 5,
                borderColor: "white",
                textAlign: "center",
                // fontFamily: "ariel",
                fontSize: "inherit",
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 10,
                paddingBottom: 10,
                color: "white",
                fontWeight: "bold",
                backgroundColor: "#3e98c7",
                cursor: "pointer",
              }}
              onClick={() => setIsSchedulingReminder(true)}
            >
              <FontAwesomeIcon
                style={{ marginLeft: 0 }}
                icon={faBell}
                size="lg"
              />
              <div
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  marginTop: 5,
                  color: "white",
                }}
              >
                {t("common-button-reminder")}
              </div>
            </button>
          </div>
        </div>

        <div style={{ paddingBottom: 200 }}>
          <DnDCalendar
            defaultDate={defaultDate}
            style={{ height: 800 }}
            defaultView={Views.MONTH}
            events={events.map(mapToRBCFormat)}
            localizer={localizer}
            onSelectEvent={handleClickItem}
            //     onDoubleClickEvent={handleClickItem}
            //   onSelectSlot={()=> setIsEventsModalAddNewOpen(true)}
            onSelectSlot={handleSelectSlot}
            slotPropGetter={customSlotPropGetter}
            eventPropGetter={eventStyleGetter}
            dayLayoutAlgorithm="no-overlap"
            messages={calendarMessages}
            // showMoreButton={true}

            /*  components={
                {
                  //  timeGutterHeader: () => <div style={{textAlign: "center", marginTop: 25, paddingRight: 20, paddingLeft:20}}>What made you smile?</div>,
                  // event: EventAgenda,
                }
              }
              //    showMultiDayTimes
*/
            // toolbar={true}
            startAccessor="start"
            endAccessor="end"
            selectable
            // onEventDrop={moveEvent}
            // onEventResize={resizeEvent}
            popup
            views={[
              "month",

              //  'agenda',
            ]}
            //  resizable
            //    scrollToTime={scrollToTime}
            //  draggableAccessor={(event) => true}
          />
        </div>

        {/*  <EventsModal
          isOpen={isEventsModalOpen}
          event={selectedEvent}
          close={() => setIsEventsModalOpen(false)}
          edit={() => {
            setIsEventsModalOpen(false);
            setIsEventsModalEditOpen(true);
          }}
        />*/}

        {isSchedulingReminder ? (
          <DailyReminder
            isOpen={isSchedulingReminder}
            close={CloseMenu}
            title={t("journal-log-remindertitle")}
            description={t("journal-log-remindertext")}
          />
        ) : null}
        {isEventsModalAddNewOpen ? (
          <EventsModalAddNew
            isOpen={isEventsModalAddNewOpen}
            event={selectedEvent}
            isDarkMode={isDarkMode}
            selectedStartTime={selectSlotStart}
            isOnline={isOnline}
            close={() => setIsEventsModalAddNewOpen(false)}
          />
        ) : null}

        <div style={{ paddingBottom: 150 }} />
      </div>

      <Route path={`../journal/:productId`}>
        <JournalLanding
          data={events}
          isExpired={isExpired}
          isDarkMode={isDarkMode}
          isOnline={isOnline}
        />
      </Route>
    </Fragment>
  );
};

MyJournal.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
};
