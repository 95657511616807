import { useState, useEffect, useRef } from "react";
import classes from "../notes.module.css";

//import TextareaAutosize from "react-textarea-autosize";
import { Modal } from "Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faClose,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import { TagCreator } from "Common/TagGenerator/TagCreator";
import { CirclePicker, GithubPicker } from "react-color";
//import Editor from "react-simple-wysiwyg";
import {
  BtnBold,
  BtnItalic,
  Editor,
  BtnBulletList,
  HtmlButton,
  BtnNumberedList,
  BtnUnderline,
  BtnStrikeThrough,
  EditorProvider,
  BtnClearFormatting,
  Toolbar,
} from "react-simple-wysiwyg";
import { useTranslation } from "react-i18next";
import { CountdownTimer } from "Common/Timer/CountdownTimer";

export const AddNoteHeader = ({
  handleAddNote,

  setIsAdding,
  isAdding,
  value,
  isDarkMode,
  isNew,
}) => {
  const [noteText, setNoteText] = useState(value.text);
  const characterLimit = 10000;
  const [isAddTags, setIsAddTags] = useState(false);
  const { t } = useTranslation();
  var isAutoSave = false;

  const handleChange = (event) => {
    if (characterLimit - event.target.value.length >= 0) {
      setNoteText(event.target.value);
    }
  };

  const [noteTags, setNoteTags] = useState(
    value.tags != undefined ? value.tags : []
  );

  //console.log("what is noteTag", noteTags);

  const tags = (pushTags) => {
    setNoteTags(pushTags);
  };

  const handleSaveClick = async () => {
    // console.log("what is notetag then value.tags", noteTags, value.tags);
    if (noteText.trim().length > 0 || noteTags != value.tags) {
      var newNote = {
        text: noteText,
        tags: noteTags,
        color: noteColor,
      };
      await handleAddNote(newNote);

      // setIsAddTags(false);

      if (!isAutoSave) {
        if (isNew) {
          setNoteText("");
        }
        setIsAdding(true);
      }
    }
  };

  const [noteColor, setNoteColor] = useState(
    (value.color = !undefined ? value.color : "#fef68a")
  );

  const Newcolors = [
    "#EB9694",
    "pink",
    "#FAD0C3",
    "#fef68a",
    "#C1E1C5",
    "#BEDADC",
    "#C4DEF6",
    "#BED3F3",
    "#D4C4FB",
    "plum",
    "azure",
    "floralwhite",
  ];

  const handleColorChangeComplete = (color, event) => {
    //console.log("which color", color.hex);
    //  editNoteColor(id, color.hex);
    // setIsColorPickerOpen(false);
    setNoteColor(color.hex);
  };

  const inputRef = useRef(null);

  const [withHTMLStyle, setWithHTMLStyle] = useState({
    type: "text/plain",
    isAllow: false,
  });

  const toolbar = {
    container: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], // add color and background tools
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["clean"],
    ],
  };

  //Auto Save after 10 seconds
  const [count, setCount] = useState(10);
  const [autoSaveTimer, setAutoSaveTimer] = useState(0);

  // Function to handle auto-saving
  const handleAutoSave = () => {
    // Clear any existing auto-save timer
    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }
    setCount(10);
    // Start a new auto-save timer
    const timer = setTimeout(() => {
      // Perform the auto-save action
      isAutoSave = true;
      handleSaveClick();
    }, 10000); // 30 seconds

    // Set the new timer
    setAutoSaveTimer(timer);
  };

  useEffect(() => {
    if (!isNew) {
      handleAutoSave();
    }
    // Cleanup function to clear the auto-save timer on unmount or when title/description changes
    return () => {
      if (autoSaveTimer) {
        clearTimeout(autoSaveTimer);
      }
    };
  }, [noteText]);

  return (
    <div
      style={
        {
          // overflowX: "hidden",
          // filter: !isDarkMode ? "invert(100%)" : "",
        }
      }
      data-openreplay-obscured
    >
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          paddingRight: 10,
          fontFamily: "inherit",
          fontSize: 14,
        }}
      >
        {/* !withHTMLStyle.isAllow ? (
            <div>
              <span style={{ paddingRight: 10 }}>Paste: Text</span>
              <button onClick={toggleHTMLStyle}>Allow HTML</button>
            </div>
          ) : (
            <div>
              <span style={{ paddingRight: 10 }}>Paste: HTML</span>
              <button onClick={toggleHTMLStyle}>Turn Off HTML</button>
            </div>
          ) 
          */}
      </div>
      <div
        className={classes.noteFooter}
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          flexDirection: "column",
          padding: 10,
          // filter: "invert(100%)",
        }}
      >
        <div style={{ width: "95%" }}>
          <EditorProvider>
            <Editor
              value={noteText}
              placeholder={t("notes-addnew-placeholder")}
              containerProps={{
                style: {
                  backgroundColor: noteColor,
                  resize: "both",
                  color: "black",
                },
              }}
              onChange={(e) => setNoteText(e.target.value)}
            >
              <Toolbar>
                <BtnBold />
                <BtnItalic />
                <BtnUnderline />
                <BtnBulletList />
                <BtnNumberedList />
                <BtnStrikeThrough />
                <HtmlButton />
              </Toolbar>
            </Editor>
          </EditorProvider>
          <div style={{ paddingTop: 5, paddingBottom: 10 }}>
            <GithubPicker
              onChangeComplete={handleColorChangeComplete}
              colors={Newcolors}
            />
            {/*}   {value.text != noteText ? (
              <p
                // className="blinkingButton"
                style={{
                  textAlign: "center",
                  // color: "white",
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 14,
                  // marginTop: -10,
                }}
              >
                {" "}
                {!isNew ? (
                  <span>
                    Automatic saving will occur in{" "}
                    <CountdownTimer count={count} setCount={setCount} />{" "}
                    seconds.
                  </span>
                ) : (
                  "Select Save when ready."
                )}
              </p>
            ) : null} */}
          </div>
        </div>

        <div>
          <button
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              zIndex: 10,
              marginRight: 0,
              backgroundColor: "transparent",
              color: "blue",
              cursor: "pointer",
            }}
            className={classes.save}
            onClick={() => setNoteText("")}
          >
            Clear
          </button>
          <button
            style={{
              fontSize: "inherit",
              fontFamily: "inherit",
              margin: 10,
              padding: 10,
              paddingRight: 20,
              paddingLeft: 20,
              backgroundColor: "blue",
              color: "white",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            className={classes.save}
            onClick={handleSaveClick}
          >
            {t("common-button-save")}
          </button>
        </div>

        <div style={{ paddingBottom: 20 }} />
      </div>
    </div>
  );
};
