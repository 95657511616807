import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const WhatsNewWidget = () => {
  const [sorted, setSorted] = useState([]);
  const { t } = useTranslation();

  //to update or add an item, just add a new item to the array below, then update i18n translatation files in the locales folder.

  const WhatisNew = [
    {
      id: 97,
      item: t("whatsnew-widget-item-8"),
    },

    {
      id: 500,
      item: t("whatsnew-widget-item-5"),
    },
    {
      id: 400,
      item: t("whatsnew-widget-item-4"),
    },
    {
      id: 300,
      item: t("whatsnew-widget-item-3"),
    },

    {
      id: 200,
      item: t("whatsnew-widget-item-2"),
    },
    {
      id: 100,
      item: t("whatsnew-widget-item-1"),
    },
  ];

  useEffect(() => {
    const sorted = [...WhatisNew].sort((b, a) => b.id - a.id);
    setSorted(sorted);
    return () => {};
    //@ts-ignore
  }, []);

  return (
    <div
      style={{
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        marginTop: -10,
      }}
    >
      <ol style={{ lineHeight: 1.5 }}>
        {sorted.map((a) => (
          <li key={a.id}>
            {a.item}
            <br />
          </li>
        ))}
      </ol>
    </div>
  );
};
