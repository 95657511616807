import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";

export const ResetUserLogout = () => {
  const { logout } = useAuth();
  //android/ios check

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;

  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  const LogOutUser = async () => {
    await logout();

    const url = "https://alohaplanner.app";
    if (isAndroid) {
      window.open(url, "_self");
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    //  logout();
    CheckPlatform();

    return () => {};
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
      }}
    >
      <div
        className="metroButton"
        style={{
          borderRadius: 10,
          marginTop: 5,
          color: "white",
        }}
        onClick={() => LogOutUser()}
      >
        Logout
      </div>
    </div>
  );
};
