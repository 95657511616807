// src/components/ChoreChart/Chore.jsx

import React from "react";
import "./Chore.css";

export const Chore = ({ chore, onToggle, onDelete }) => {
  const { id, name, assignedTo, frequency, completed } = chore;

  return (
    <div className={`chore ${completed ? "completed" : ""}`}>
      <div className="chore-info">
        <span className="chore-name">{name}</span>
        <span className="chore-assigned">👤 {assignedTo}</span>
        <span className="chore-frequency">📅 {frequency}</span>
      </div>
      <div className="chore-actions">
        <button className="complete-button" onClick={() => onToggle(id)}>
          {completed ? "✅" : "⬜"}
        </button>
        <button className="delete-button" onClick={() => onDelete(id)}>
          🗑️
        </button>
      </div>
    </div>
  );
};
