import { useState, useEffect, memo } from "react";
import { useUserContext } from "Authentication";
import { nanoid } from "nanoid";
import { NotesList } from "./components/NotesList";
import { Search } from "./components/Search";
import { Link, useHistory } from "react-router-dom";
//import { Header } from "./components/Header";
import classes from "./notes.module.css";
//import Fade from "react-reveal/Fade";
//import LightSpeed from "react-reveal/LightSpeed";
import { useTranslation } from "react-i18next";
import { AddNote } from "./components/AddNote";
//import { SubscriptionChecker } from "Authentication/SubscriptionChecker";
import { FirebaseAnalytics } from "Config/FirebaseAnalytics";
//import RMModal from "react-modal";
import { CSVLink } from "react-csv";
//import toast, { Toaster } from "react-hot-toast";
import { NotesModel } from "./models/notes";
import { AddNoteHeader } from "./components/AddNoteHeader";
//import { QuickNotes } from "./components/QuickNotes";

interface NotesProps {
  notes: NotesModel[];
  isExpired: boolean;
  isDarkMode: boolean;
}

export const NotesHome = memo((props: NotesProps) => {
  const { notes, isExpired, isDarkMode } = props;
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const [localNotes, setLocalNotes] = useState([
    {
      id: "001",
      text: "This is my first note!",
      tags: "need to add",
      //date: '15/04/2021',
      color: "#fef68a",
    },
    {
      id: "002",
      text: "This is my second note!",
      tags: "need to add",
      //date: '21/04/2021',
      color: "#fef68a",
    },
    {
      id: "003",
      text: "This is my third note!",
      tags: "need to add",
      //date: '28/04/2021',
      color: "#fef68a",
    },
    {
      id: "004",
      text: "This is my new note!",
      tags: "need to add",
      //	date: '30/04/2021',
      color: "#fef68a",
    },
  ]);

  //console.log("did this work?", notes.notes)

  const [searchText, setSearchText] = useState("");
  const [addNoteEditor, setAddNoteEditor] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const { getNotesCollection, addNewNote, deleteNote, editNote } =
    useUserContext();
  const [isNative, setIsNative] = useState(false);
  const dbNotes = getNotesCollection();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("scroll", handleScroll);
    if ((window as any).isNative) {
      setIsNative(true);
    } else {
      setIsNative(false);
    }
    const savedNotes = JSON.parse(localStorage.getItem("react-notes-app-data"));

    if (savedNotes) {
      setLocalNotes(savedNotes);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [notes]);

  {
    /*	useEffect(() => {
		localStorage.setItem(
			'react-notes-app-data',
			JSON.stringify(notes)
		);
	}, [notes]); */
  }

  //console.log("notes home", isExpired);

  var blankNote = {
    text: "",
    tags: [],
    color: "#fef68a",
  };

  const addNote = (note) => {
    //	const date = new Date();
    const newNote = {
      id: nanoid(),
      text: note.text,
      tags: note.tags,
      color: note.color,
    };
    const newNotes = [...localNotes, newNote];
    setLocalNotes(newNotes);
    //push to server
    addNewNote(note.text, note.tags, note.color);
    setAddNoteEditor(false);
    //console.log("did this work?")
  };

  const CloseEditor = () => setAddNoteEditor(false);

  const csvReport = {
    data: notes,
    //headers: headers,
    filename: "DN-Export-Notes.csv",
  };

  const filteredNotes = notes.filter((item) => {
    const lowercaseSearchText = searchText.toLowerCase();

    if (typeof item.tags === "string" || typeof item.tags === "undefined") {
      return item.text.toLowerCase().includes(lowercaseSearchText);
    } else {
      return (
        item.text.toLowerCase().includes(lowercaseSearchText) ||
        item.tags.some(
          (tag) =>
            //@ts-ignore
            typeof tag.text === "string" &&
            //@ts-ignore
            tag.text.toLowerCase().includes(lowercaseSearchText)
        )
      );
    }
  });

  return (
    <div className={`${darkMode && "classes.darkMode"}`}>
      {/* <LightSpeed left><h2 style={{textAlign: "center", marginTop: -5, }}>Notes</h2></LightSpeed> */}

      <div className={classes.container}>
        <FirebaseAnalytics />
        {!isNative ? (
          <div
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginRight: 20,
              color: "blue",
              textDecoration: "underline",
            }}
          >
            <CSVLink {...csvReport}>{t("notes-home-exportcsv")}</CSVLink>
          </div>
        ) : (
          <span></span>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 15,
            padding: 20,
          }}
        >
          {/*showButton && (
            <button
              //  className={classes.blinkingButton}
              onClick={() => setAddNoteEditor(true)}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: "inherit",
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 20,
                border: 0,
                color: "white",
                backgroundColor: "purple",
                cursor: "pointer",
                fontFamily: "inherit",
              }}
            >
              +{t("common-button-addnew")}
            </button>
          )*/}

          <Link to="/ideas">
            <button
              //  className={classes.blinkingButton}
              //   onClick={() => setAddNoteEditor(true)}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                fontSize: "inherit",
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: 20,
                width: 200,
                border: 0,
                color: "black",
                backgroundColor: "lime",
                cursor: "pointer",
                fontFamily: "inherit",
              }}
            >
              AI Helper
            </button>
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
            //  paddingTop: 10,
            paddingBottom: 20,
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          <h2>{t("notes-home-title")}</h2>
        </div>
        <p
          style={{
            paddingBottom: 10,
            fontSize: 14,
            lineHeight: 1.2,
            fontWeight: "normal",
            marginTop: -25,
            textAlign: "center",
          }}
        >
          {t("notes-home-subtitle")}
        </p>

        <div style={{ marginTop: 0, paddingBottom: 0 }}></div>

        {/*}	<div style={{height: "85vh", margin: 0, overflowY:"scroll"}}>*/}
        <AddNote
          handleAddNote={addNote}
          setIsAdding={CloseEditor}
          isAdding={addNoteEditor}
          value={blankNote}
          isDarkMode={isDarkMode}
          isNew={true}
        />

        <AddNoteHeader
          handleAddNote={addNote}
          setIsAdding={CloseEditor}
          isAdding={addNoteEditor}
          value={blankNote}
          isDarkMode={isDarkMode}
          isNew={true}
        />

        <Search handleSearchNote={setSearchText} />

        <NotesList
          notes={filteredNotes}
          //notes={notes.notes}
          handleAddNote={addNote}
          handleDeleteNote={deleteNote}
          handleEditNote={editNote}
          isExpired={{ isExpired }}
        />

        {/*</div>*/}
      </div>
      <div style={{ paddingBottom: 150 }} />
    </div>
  );
});
