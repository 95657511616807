import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
//import firebase from "firebase/app";
//import "firebase/auth";
//import "firebase/firestore";

import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  //EmailAuthProvider,
  //linkWithCredential,
  sendPasswordResetEmail,
  signOut,
  //signInWithPopup,
  //browserSessionPersistence,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";

//import { GoogleAuthProvider } from 'firebase/auth/google';

//import firebase from "firebase/compat/app";
//import "firebase/compat/auth";
//import "firebase/compat/firestore";

import { AuthUser } from "./models";

import { useTranslation } from "react-i18next";

//const googleProvider = new firebase.auth.GoogleAuthProvider();
//const facebookProvider = new firebase.auth.FacebookAuthProvider();
//const twitterProvider = new firebase.auth.TwitterAuthProvider();
//var provider = new firebase.auth.OAuthProvider("apple.com");

interface AuthContextType {
  loginFB: () => Promise<void>;
  loginApple: () => Promise<void>;
  loginGO: () => Promise<void>;
  loginTW: () => Promise<void>;
  loginLink: () => Promise<void>;
  loginPhone: () => Promise<void>;
  loginEmailPassword: (email, password) => Promise<void>;
  linkGuestEmailPassword: (email, password) => Promise<void>;
  createEmailPassword: (email, password) => Promise<void>;
  resetEmailPassword: (email) => Promise<void>;
  loginGuest: () => Promise<void>;
  emailAuthLink: () => Promise<void>;

  logout: () => Promise<void>;
  user: AuthUser | undefined;
  initialised: boolean;
}

const AuthContext = createContext<Partial<AuthContextType>>({});

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthProvider = (props: AuthProviderProps) => {
  const [initialised, setFbInitialised] = useState(false);
  const [user, setUser] = useState<AuthUser>();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState("");
  const { t } = useTranslation();

  //Anonymous

  const AnonymousSignIn = async () => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInAnonymously(auth);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  //Email with Password SignIn

  const signInWithEmailPassword = async (email, password) => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        window.alert(t("register-login-invalid-info"));
      });
    //  const user = auth.currentUser;
  };

  //SignUp Email and Password

  const signUpWithEmailPassword = async (email, password) => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return createUserWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        // Handle Errors here.
        window.alert(t("link-user-account-error"));
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  // Link Guest to an Email Password

  const sendPasswordReset = async (email) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      // Password reset email sent!
      window.alert(t("register-lost-password-info"));
      // ..
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      window.alert(errorMessage);
      // ..
    }
  };
  // [END auth_send_password_reset]

  var NewUserTrial30 = new Date();
  NewUserTrial30.setDate(NewUserTrial30.getDate() + 30);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setUser({
          id: user.uid,
          name: user.displayName || "Anonymous" + user.uid.slice(23),
          email: user.email || "",
          phone: user.phoneNumber || "",
          isNickNameSet: false,
          userId: user.uid,
          subscriptionEndDate: NewUserTrial30,
          subscriptionStatus: "trial",
          theme: "VT323",
        });
        // Cache the authentication token
        if (
          "serviceWorker" in navigator &&
          navigator.serviceWorker.controller
        ) {
          const token = user.getIdToken();
          token.then((idToken) => {
            navigator.serviceWorker.controller.postMessage({
              type: "CACHE_AUTH_TOKEN",
              token: idToken,
            });
          });
        }
      } else {
        setUser(undefined);
      }

      setFbInitialised(true);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  /*

 TODO: This is the updated code, googleauthprodiver is missing.  I'll do the other later
  const loginGO = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  }; */

  async function loginEmailPassword(email, password) {
    await signInWithEmailPassword(email, password);
    /* if ((window as any).isNative) {
      // Post the message back to expo
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "LoginDataTransfer",
          email: email,
          password: password,
        })
      );
    } else {
      // Your old login method
      // console.log("clicked")
    }*/
  }

  async function createEmailPassword(email, password) {
    try {
      await signUpWithEmailPassword(email, password);
    } catch (error) {
      alert("Connection Error.  Please try again.");
      //@ts-ignore
      window.location.reload(true);
    }

    /* 
    Removing APP signin
    if ((window as any).isNative) {
      // Post the message back to expo
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "CreateDataTransfer",
          email: email,
          password: password,
        })
      );
    } else {
      // Your old login method
      //  console.log("clicked")
    }
    */
  }

  async function resetEmailPassword(email) {
    await sendPasswordReset(email);
  }

  async function loginGuest() {
    try {
      AnonymousSignIn();
    } catch (error) {
      alert("Connection Error.  Please try again.");
      //@ts-ignore
      window.location.reload(true);
    }
  }

  /* async function loginLink() {
    await generateLink();
    //   await firebase.auth().signInWithEmailLink("timholmgren@gmal.com", window.location.href)
  }*/

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        logout,
        //loginPhone,
        loginEmailPassword,
        resetEmailPassword,
        createEmailPassword,
        // emailAuthLink,
        loginGuest,
        user,
        initialised,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);
const useIsAuthenticated = () => Boolean(useContext(AuthContext).user);

export { AuthContext, AuthProvider, useAuth, useIsAuthenticated };
