//Add Habit

import { useState, useCallback, useRef, useEffect } from "react";
import { useUserContext } from "Authentication";
import { Title } from "../generic/Title";
import { Container, ListOfHabits, Percentage, TitleContainer } from "./style";
import { CenterLoader } from "../HabitsPage/style";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { UserHabits } from "../UserTodayHabit/UserHabits.js";

import moment from "moment";
import ReactCanvasConfetti from "react-canvas-confetti";

import { FirebaseAnalytics } from "../../../Config/FirebaseAnalytics";

import { useTranslation } from "react-i18next";
import { HabitForm } from "../generic/HabitForm";
import { BannerAd } from "GoogleAdSense/BannerAd";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 10,
  left: 0,
};

export const HabitSectionPage = ({ habits, stepCount, journal, isExpired }) => {
  const [isLoading, setLoading] = useState(false);
  const [reset, setReset] = useState([]);
  const [todayHabitData, setTodayHabitData] = useState([]);
  const [isAllHabits, setIsAllHabits] = useState(false);
  const [habitData, setHabitData] = useState(habits);
  const [isIdeas, setIdeas] = useState(false);
  const user = useUserContext();
  const { t } = useTranslation();
  //console.log("what are the habits", habits);
  //console.log("journal todaypage", journal);
  // const [successRemoval, setSuccessRemoval] = useState(false);

  //console.log("habits iseexpired", isExpired.toString());

  const ideas = [
    "Be Useful",
    t("sections-habits-example1"),
    t("sections-habits-example2"),
    t("sections-habits-example3"),
    t("sections-habits-example4"),
    t("sections-habits-example5"),
    t("sections-habits-example6"),
    t("sections-habits-example7"),
    //t("sections-habits-example8"),
    t("sections-habits-example9"),
    t("sections-habits-example10"),
    t("sections-habits-example11"),
    t("sections-habits-example12"),
    t("sections-habits-example13"),
    //  t("sections-habits-example14"),
    t("sections-habits-example15"),
  ];

  const [createHabit, setCreateHabit] = useState(false);

  const [newHabit, setNewHabit] = useState({
    name: "",
    days: [0, 1, 2, 3, 4, 5, 6],
    isTrackingReps: false,
    RepsLabel: "",
    isPlanner: true,
  });

  const addNewIdea = (name) => {
    setNewHabit({
      name: name,
      days: [0, 1, 2, 3, 4, 5, 6],
      isTrackingReps: false,
      RepsLabel: "",
      isPlanner: true,
    });
    openCreateHabit();
  };

  //confetti

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const NewHabitDate = moment(new Date()).format("MM/DD/YYYY");

  // Add and delete habits

  const { deleteHabit } = useUserContext();

  const addTracking = () => {
    if (newHabit.isTrackingReps) {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isTrackingReps"]: true });
    }
  };

  const addPlanner = () => {
    if (newHabit.isPlanner) {
      setNewHabit({ ...newHabit, ["isPlanner"]: false });
    } else {
      setNewHabit({ ...newHabit, ["isPlanner"]: true });
    }
  };

  function removeHabit(id) {
    const areYouSureAboutThat = window.confirm("Are you sure?");

    if (areYouSureAboutThat === true) {
      deleteHabit(id);
    }
  }

  const openCreateHabit = () => {
    setCreateHabit(true);
  };

  //filter the habits data for today only
  const filterHabitsByDay = habits.filter((item) =>
    item.days.includes(dayjs().day())
  );
  //Show habits for today
  var completedHabits = habits.filter(
    (i) => i.dateLastUpdated == moment(new Date()).format("YYYYMMDD") && i.done
  ).length;

  //calculate the percentage done for the day
  var percentage =
    completedHabits >= 1
      ? parseInt((completedHabits / filterHabitsByDay.length) * 100)
      : 0;

  const SeeAllHabits = () => {
    setIsAllHabits(true);
  };

  const SeeTodayHabits = () => {
    setIsAllHabits(false);
  };

  const FireIfCompleted = () => {
    fire();
    // updateWidget();
  };

  const [isAlohaPlanner, setIsAlohaPlanner] = useState(false);

  useEffect(() => {
    //checkThemeSettings();
    // window.scrollTo({ top: 0, behavior: "smooth" });
    //console.log("test home");

    if (window.isAloha) {
      setIsAlohaPlanner(true);
    }
    // window.scrollTo({ top: 0, behavior: "smooth" });
    // updateWidget();

    return () => {};
  }, [percentage]);

  const ToggleIdeas = () => {
    isIdeas ? setIdeas(false) : setIdeas(true);
  };

  return (
    <Container>
      <FirebaseAnalytics />
      <div style={{ textAlign: "center" }}>
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
            paddingTop: 10,
            paddingBottom: 20,
            fontSize: 24,
            fontWeight: "bold",
            userSelect: "none",
          }}
        >
          {" "}
          <h2 className="h2SlideIn">{t("sections-habits-title")}</h2>
        </div>
        <p
          style={{
            paddingBottom: 10,
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: "normal",
            marginTop: -20,
            userSelect: "none",
          }}
        >
          {t("sections-habits-intro-text")}! 😀{" "}
          {!isIdeas ? (
            <button
              style={{
                backgroundColor: "#3e98c7",
                color: "white",
                padding: 5,
                paddingLeft: 15,
                paddingRight: 15,
                borderColor: "white",
                borderRadius: 5,
                borderRadius: 40,
                marginTop: -20,
              }}
              onClick={ToggleIdeas}
            >
              {t("sections-habits-suggestions-button")}
            </button>
          ) : (
            <span />
          )}
        </p>
        {isIdeas ? (
          <div
            style={{
              padding: 10,
              border: 1,
              //backgroundColor: "lightyellow"
            }}
          >
            <div
              style={{
                paddingBottom: 10,
                fontSize: 18,
                fontFamily: "inherit",
                //  lineHeight: 1.2,
                fontWeight: "bold",
                marginTop: -5,
                lineHeight: 1.5,
                textAlign: "center",
              }}
            >
              <h3 style={{ textDecoration: "underline", marginTop: -5 }}>
                {t("sections-habits-suggestions-title")}
              </h3>
              <div style={{ textAlign: "left", padding: 20 }}>
                {ideas.map((idea) => {
                  const isIdeaInHabits = habits.some(
                    (habit) => habit.name === idea
                  );

                  return !isIdeaInHabits ? (
                    <div key={idea}>
                      <button
                        onClick={() => addNewIdea(idea)}
                        style={{
                          padding: 5,
                          borderRadius: 5,
                          border: 0,
                          color: "white",
                          backgroundColor: "purple",
                          fontFamily: "inherit",
                        }}
                      >
                        +
                      </button>{" "}
                      {idea}{" "}
                    </div>
                  ) : null;
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={{
                  backgroundColor: "#3e98c7",
                  color: "white",
                  padding: 5,
                  paddingLeft: 10,
                  paddingRight: 10,
                  borderRadius: 40,
                  border: 0,
                }}
                onClick={ToggleIdeas}
              >
                {t("common-button-close")}
              </button>
            </div>
          </div>
        ) : (
          <span />
        )}
      </div>
      {stepCount > 10 ? (
        <p style={{ textAlign: "center" }}> Today's Step Count {stepCount}</p>
      ) : null}
      <TitleContainer>
        {/*} <Title style={{fontWeight: "bolder"}}>{today}</Title>*/}

        <Percentage>
          {percentage === 0 ? (
            <h1 style={{ lineHeight: 1, userSelect: "none" }}>0% Completed</h1>
          ) : (
            <h1 style={{ lineHeight: 1, userSelect: "none" }}>
              {percentage}% {t("sections-habits-completed-label")}
            </h1>
          )}
        </Percentage>
      </TitleContainer>

      <>
        <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      </>

      {isLoading && todayHabitData.length === 0 && (
        <CenterLoader></CenterLoader>
      )}

      <div style={{ paddingBottom: 20, display: "flex", flexWrap: "wrap" }}>
        {!createHabit ? (
          <div style={{ paddingLeft: 5, paddingTop: 10 }}>
            <button
              onClick={openCreateHabit}
              style={{
                padding: 15,
                width: 140,
                marginLeft: 10,
                fontFamily: "inherit",
                fontSize: "inherit",
                //  borderRadius: 5,
                backgroundColor: "#3e98c7",
                color: "white",
                cursor: "pointer",
                borderColor: "white",
                borderRadius: 40,
                border: 0,
              }}
            >
              {t("common-button-addnew")}
            </button>
          </div>
        ) : null}
        {!isAllHabits && habits.length != 0 ? (
          <div style={{ paddingLeft: 5, paddingTop: 10 }}>
            <button
              onClick={SeeAllHabits}
              style={{
                padding: 15,
                width: 140,
                marginLeft: 10,
                fontFamily: "inherit",
                fontSize: "inherit",
                // borderRadius: 5,
                backgroundColor: "#3e98c7",
                color: "white",
                cursor: "pointer",
                borderColor: "white",
                borderRadius: 40,
                border: 0,
              }}
            >
              {t("sections-habits-button-seeweek")}
            </button>
          </div>
        ) : null}
        {isAllHabits ? (
          <div style={{ paddingLeft: 5, paddingTop: 10 }}>
            <button
              onClick={SeeTodayHabits}
              style={{
                padding: 15,
                width: 140,
                marginLeft: 10,
                fontFamily: "inherit",
                fontSize: "inherit",
                //    borderRadius: 5,
                backgroundColor: "#3e98c7",
                color: "white",
                cursor: "pointer",
                borderRadius: 40,
                border: 0,
              }}
            >
              {t("sections-habits-button-todayonly")}
            </button>
          </div>
        ) : null}
      </div>

      {/*<div style={{height: "85vh", margin: 0,  overflowY:"scroll"}}>*/}

      <ListOfHabits>
        {isAllHabits
          ? habits.map((element, index) => (
              <UserHabits
                key={index}
                data={element}
                reset={setReset}
                confetti={FireIfCompleted}
                // stepCount={stepCount}
                // journal={journal}
                isDetailView={false}
              />
            ))
          : filterHabitsByDay.map((element, index) => (
              <div style={{ width: "100%" }} key={index}>
                <UserHabits
                  key={index}
                  data={element}
                  reset={setReset}
                  confetti={FireIfCompleted}
                  // stepCount={stepCount}
                  // journal={journal}
                  isDetailView={false}
                />
              </div>
            ))}
      </ListOfHabits>
      <div style={{ paddingTop: 20 }} />

      <div style={{ paddingBottom: 150 }} />
      {/*}  </div>*/}
      {/*  <Menu /> */}
      <HabitForm
        isOpen={createHabit}
        newHabit={newHabit}
        addTracking={addTracking}
        addPlanner={addPlanner}
        setNewHabit={setNewHabit}
        close={() => setCreateHabit(false)}
        user={user}
        isEdit={false}
      />
    </Container>
  );
};
