import React, { useState, useEffect } from "react";
import { useUserContext } from "Authentication";
import toast, { Toaster } from "react-hot-toast";
/**
 * Standard Pig Latin for a single lowercase word:
 * 1) If the first letter is vowel => word + "ay"
 * 2) Otherwise, move leading consonant cluster before the first vowel -> end, then add "ay"
 * 3) If no vowel at all, just append "ay"
 *
 *
 */
const pigPenMap = {
  A: "🌺", // Hibiscus (Hawaii's state flower)
  B: "🌴", // Palm tree
  C: "🍍", // Pineapple
  D: "🏄", // Surfer
  E: "🌊", // Wave
  F: "🏝️", // Island
  G: "🌋", // Volcano
  H: "🐬", // Dolphin
  I: "🐢", // Turtle (Honu)
  J: "🐠", // Tropical fish
  K: "🐳", // Spouting whale
  L: "🏖️", // Beach
  M: "🥥", // Coconut
  N: "🐚", // Seashell
  O: "🐙", // Octopus
  P: "🤙", // Shaka sign
  Q: "🌕", // Full moon
  R: "🍹", // Tropical drink
  S: "🍧", // Shave ice
  T: "🐔", // Chicken (many wild chickens on Kauai)
  U: "🦀", // Crab
  V: "🌈", // Rainbow
  W: "🦎", // Gecko lizard
  X: "🐡", // Puffer fish
  Y: "🏔️", // Snow-capped mountain (Mauna Kea sometimes!)
  Z: "🗿", // Moai statue (not strictly Hawaii but Polynesian vibe)
};

// 2) Create the reverse map for decryption: symbol → letter
//    We'll invert pigPenMap so we can look up letters by their shapes.
const pigPenReverseMap = {};
for (const [letter, symbol] of Object.entries(pigPenMap)) {
  pigPenReverseMap[symbol] = letter;
}

{
  /* function pigLatinOneWord(word) {
  const lower = word.toLowerCase();
  const firstVowelIndex = lower.search(/[aeiou]/);

  if (firstVowelIndex === 0) {
    // starts with vowel
    return lower + "ay";
  } else if (firstVowelIndex > 0) {
    // leading consonant cluster => move it to the end + "ay"
    const cluster = lower.slice(0, firstVowelIndex);
    const remainder = lower.slice(firstVowelIndex);
    return remainder + cluster + "ay";
  } else {
    // no vowel => just word + "ay"
    return lower + "ay";
  }
} */
}

const ciphers = {
  atbash: (text) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const reversed = alphabet.split("").reverse().join("");
    return text
      .toUpperCase()
      .split("")
      .map((char) => {
        const index = alphabet.indexOf(char);
        return index >= 0 ? reversed[index] : char;
      })
      .join("");
  },
  caesar: (text, shift = 3) => {
    // Convert shift to number, with fallback
    const numericShift = parseInt(shift, 10);
    if (isNaN(numericShift)) return text; // fallback: return unchanged

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return text
      .toUpperCase()
      .split("")
      .map((char) => {
        const index = alphabet.indexOf(char);
        // if letter is found, shift it; otherwise, just return char
        return index >= 0 ? alphabet[(index + numericShift) % 26] : char;
      })
      .join("");
  },
  caesarDecrypt: (text, shift = 3) => {
    const numericShift = parseInt(shift, 10);
    if (isNaN(numericShift)) return text;

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return text
      .toUpperCase()
      .split("")
      .map((char) => {
        const index = alphabet.indexOf(char);
        return index >= 0 ? alphabet[(index - numericShift + 26) % 26] : char;
      })
      .join("");
  },
  scytale: (text) => {
    const columns = 3; // Example: Fixed column count
    const rows = Math.ceil(text.length / columns);
    const grid = Array(columns)
      .fill("")
      .map(() => []);

    text.split("").forEach((char, i) => {
      grid[i % columns].push(char);
    });

    return grid.flat().join("");
  },
  scytaleDecrypt: (text) => {
    const columns = 3; // Example: Fixed column count
    const rows = Math.ceil(text.length / columns);
    const grid = Array(rows)
      .fill("")
      .map(() => []);

    const numFullColumns = text.length % columns;
    const shortColumnLength = Math.floor(text.length / columns);
    const fullColumnLength = shortColumnLength + 1;

    let index = 0;

    for (let col = 0; col < columns; col++) {
      const columnLength =
        col < numFullColumns ? fullColumnLength : shortColumnLength;
      for (let row = 0; row < columnLength; row++) {
        grid[row].push(text[index]);
        index++;
      }
    }

    return grid.flat().join("");
  },
  kamasutra: (text) => {
    const map = {
      A: "N",
      B: "O",
      C: "P",
      D: "Q",
      E: "R",
      F: "S",
      G: "T",
      H: "U",
      I: "V",
      J: "W",
      K: "X",
      L: "Y",
      M: "Z",
      N: "A",
      O: "B",
      P: "C",
      Q: "D",
      R: "E",
      S: "F",
      T: "G",
      U: "H",
      V: "I",
      W: "J",
      X: "K",
      Y: "L",
      Z: "M",
    };
    return text
      .toUpperCase()
      .split("")
      .map((char) => map[char] || char)
      .join("");
  },
  bacon: (text) => {
    const baconMap = {
      A: "AAAAA",
      B: "AAAAB",
      C: "AAABA",
      D: "AAABB",
      E: "AABAA",
      F: "AABAB",
      G: "AABBA",
      H: "AABBB",
      I: "ABAAA",
      J: "ABAAB",
      K: "ABABA",
      L: "ABABB",
      M: "ABBAA",
      N: "ABBAB",
      O: "ABBBA",
      P: "ABBBB",
      Q: "BAAAA",
      R: "BAAAB",
      S: "BAABA",
      T: "BAABB",
      U: "BABAA",
      V: "BABAB",
      W: "BABBA",
      X: "BABBB",
      Y: "BBAAA",
      Z: "BBAAB",
    };
    return text
      .toUpperCase()
      .split("")
      .map((char) => baconMap[char] || char)
      .join(" ");
  },
  baconDecrypt: (text) => {
    const reverseBaconMap = {
      AAAAA: "A",
      AAAAB: "B",
      AAABA: "C",
      AAABB: "D",
      AABAA: "E",
      AABAB: "F",
      AABBA: "G",
      AABBB: "H",
      ABAAA: "I",
      ABAAB: "J",
      ABABA: "K",
      ABABB: "L",
      ABBAA: "M",
      ABBAB: "N",
      ABBBA: "O",
      ABBBB: "P",
      BAAAA: "Q",
      BAAAB: "R",
      BAABA: "S",
      BAABB: "T",
      BABAA: "U",
      BABAB: "V",
      BABBA: "W",
      BABBB: "X",
      BBAAA: "Y",
      BBAAB: "Z",
    };
    return text
      .split(" ") // Preserve spaces between words
      .map(
        (word) =>
          word
            .match(/.{5}/g)
            ?.map((chunk) => reverseBaconMap[chunk] || "?")
            .join("") || ""
      )
      .join(" ");
  },
  morse: (text) => {
    const morseMap = {
      A: ".-",
      B: "-...",
      C: "-.-.",
      D: "-..",
      E: ".",
      F: "..-.",
      G: "--.",
      H: "....",
      I: "..",
      J: ".---",
      K: "-.-",
      L: ".-..",
      M: "--",
      N: "-.",
      O: "---",
      P: ".--.",
      Q: "--.-",
      R: ".-.",
      S: "...",
      T: "-",
      U: "..-",
      V: "...-",
      W: ".--",
      X: "-..-",
      Y: "-.--",
      Z: "--..",
    };

    // 1. Convert text to uppercase
    const upperText = text.toUpperCase();

    // 2. Split into words by space
    const words = upperText.split(" ");

    // 3. Convert each word to Morse, with spaces between letters
    const morseWords = words.map((word) => {
      return word
        .split("") // ['T','H','I','S']
        .map((char) => morseMap[char] || "") // e.g. '- .... .. ...'
        .join(" "); // Join letters with space
    });

    // 4. Join words with " / "
    // e.g. ["- .... .. ...", ".. ...", ".-", "- . ... -"] => "- .... .. ... / .. ... / .- / - . ... -"
    return morseWords.join(" / ");
  },
  morseDecrypt: (text) => {
    const reverseMorseMap = {
      ".-": "A",
      "-...": "B",
      "-.-.": "C",
      "-..": "D",
      ".": "E",
      "..-.": "F",
      "--.": "G",
      "....": "H",
      "..": "I",
      ".---": "J",
      "-.-": "K",
      ".-..": "L",
      "--": "M",
      "-.": "N",
      "---": "O",
      ".--.": "P",
      "--.-": "Q",
      ".-.": "R",
      "...": "S",
      "-": "T",
      "..-": "U",
      "...-": "V",
      ".--": "W",
      "-..-": "X",
      "-.--": "Y",
      "--..": "Z",
      " ": " ",
    };
    return text
      .split(" ")
      .map((char) => reverseMorseMap[char] || " ")
      .join("");
  },
  binary: (text) => {
    return text
      .split("")
      .map((char) => char.charCodeAt(0).toString(2).padStart(8, "0"))
      .join(" ");
  },
  binaryDecrypt: (text) => {
    return text
      .split(" ")
      .map((bin) => String.fromCharCode(parseInt(bin, 2)))
      .join("");
  },
  railFence: (text, rails = 3, offset = 0) => {
    rails = parseInt(rails, 10);
    offset = parseInt(offset, 10);

    if (isNaN(rails) || rails < 2) return text;
    if (isNaN(offset)) offset = 0;

    // Start at rail = offset (mod rails)
    let rail = offset % rails;
    let direction = 1;
    const fence = Array.from({ length: rails }, () => []);

    for (const char of text) {
      fence[rail].push(char);
      rail += direction;
      if (rail === 0 || rail === rails - 1) {
        direction *= -1;
      }
    }

    return fence.flat().join("");
  },

  railFenceDecrypt: (text, rails = 3, offset = 0) => {
    rails = parseInt(rails, 10);
    offset = parseInt(offset, 10);

    if (isNaN(rails) || rails < 2) return text;
    if (isNaN(offset)) offset = 0;

    let rail = offset % rails;
    let direction = 1;
    const positions = new Array(text.length);

    for (let i = 0; i < text.length; i++) {
      positions[i] = rail;
      rail += direction;
      if (rail === 0 || rail === rails - 1) {
        direction *= -1;
      }
    }

    const fence = Array.from({ length: rails }, () => []);
    let index = 0;
    positions
      .slice()
      .sort((a, b) => a - b)
      .forEach((r) => fence[r].push(text[index++]));

    return positions.map((r) => fence[r].shift()).join("");
  },

  vigenere: (text, passphrase = "KEY") => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let keyIndex = 0;
    return text
      .toUpperCase()
      .split("")
      .map((char) => {
        const charIndex = alphabet.indexOf(char);
        if (charIndex < 0) return char;
        const shift = alphabet.indexOf(
          passphrase[keyIndex % passphrase.length].toUpperCase()
        );
        keyIndex++;
        return alphabet[(charIndex + shift) % 26];
      })
      .join("");
  },
  vigenereDecrypt: (text, passphrase = "KEY") => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let keyIndex = 0;
    return text
      .toUpperCase()
      .split("")
      .map((char) => {
        const charIndex = alphabet.indexOf(char);
        if (charIndex < 0) return char;
        const shift = alphabet.indexOf(
          passphrase[keyIndex % passphrase.length].toUpperCase()
        );
        keyIndex++;
        return alphabet[(charIndex - shift + 26) % 26];
      })
      .join("");
  },

  trifid: (text, passphrase = "KEY", period = 5) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const grid = alphabet.repeat(3).split("");
    let encoded = "";
    const positions = text
      .toUpperCase()
      .split("")
      .map((char) => {
        const index = grid.indexOf(char);
        const plane = Math.floor(index / 26);
        const row = Math.floor((index % 26) / 9);
        const col = index % 9;
        return `${plane}${row}${col}`;
      })
      .join("");

    for (let i = 0; i < positions.length; i += period * 3) {
      const segment = positions.slice(i, i + period * 3);
      const reordered = [];
      for (let j = 0; j < period; j++) {
        reordered.push(
          segment[j],
          segment[period + j],
          segment[period * 2 + j]
        );
      }
      encoded += reordered.join("");
    }

    return encoded
      .match(/.{1,3}/g)
      .map((set) => {
        const plane = parseInt(set[0], 10);
        const row = parseInt(set[1], 10);
        const col = parseInt(set[2], 10);
        return grid[plane * 26 + row * 9 + col];
      })
      .join("");
  },

  trifidDecrypt: (text, passphrase = "KEY", period = 5) => {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const grid = alphabet.repeat(3).split("");

    // Step 1: Convert the ciphertext into a list of positions
    const positions = text
      .toUpperCase()
      .split("")
      .map((char) => {
        const index = grid.indexOf(char);
        const plane = Math.floor(index / 26);
        const row = Math.floor((index % 26) / 9);
        const col = index % 9;
        return `${plane}${row}${col}`;
      })
      .join("");

    // Step 2: Reorganize the positions for decryption
    let reordered = "";
    for (let i = 0; i < positions.length; i += period * 3) {
      const segment = positions.slice(i, i + period * 3);
      const planes = segment.slice(0, period);
      const rows = segment.slice(period, period * 2);
      const cols = segment.slice(period * 2);

      // Reorganizing the segments to match the original structure
      for (let j = 0; j < period; j++) {
        reordered += planes[j] + rows[j] + cols[j];
      }
    }

    // Step 3: Map the reorganized positions back to the grid
    return reordered
      .match(/.{1,3}/g) // Split the positions into triplets
      .map((set) => {
        const plane = parseInt(set[0], 10);
        const row = parseInt(set[1], 10);
        const col = parseInt(set[2], 10);
        return grid[plane * 26 + row * 9 + col];
      })
      .join(""); // Join the resulting characters back into a string
  },

  // Then inside your ciphers object:

  pigPen: (text) => {
    // We'll do a simple substitution: A→pigPenMap[A], B→pigPenMap[B], etc.
    // Non-alpha characters remain as is.
    return text
      .split("")
      .map((char) => {
        // Uppercase the letter for the map:
        const upperChar = char.toUpperCase();
        // If it's in [A–Z], map it
        if (pigPenMap[upperChar]) {
          return pigPenMap[upperChar];
        } else {
          return char; // punctuation, digits, spaces, etc.
        }
      })
      .join("");
  },

  pigPenDecrypt: (text) => {
    // 1. Convert the string to an array of Unicode grapheme clusters
    //    This helps keep emojis like 🏝️ together or at least
    //    ensures we handle codepoints consistently.
    const chars = Array.from(text);
    let result = "";
    let i = 0;

    while (i < chars.length) {
      // 2. Try a 2-char slice first, to catch things like "🏝️" which might be 2 code units
      let twoCharSlice = null;
      if (i + 1 < chars.length) {
        twoCharSlice = chars[i] + chars[i + 1];
      }

      // 3. Check if the 2-char slice is in the reverse map
      if (twoCharSlice && pigPenReverseMap[twoCharSlice]) {
        // If yes, decode it as a single letter
        result += pigPenReverseMap[twoCharSlice];
        i += 2; // Advance by 2
      } else {
        // 4. Otherwise, see if the single character is in the map
        const oneChar = chars[i];
        if (pigPenReverseMap[oneChar]) {
          result += pigPenReverseMap[oneChar];
        } else {
          // 5. Not recognized => pass it through unchanged
          result += oneChar;
        }
        i += 1;
      }
    }

    return result;
  },
};

export const EncryptText = () => {
  const { addNewNote } = useUserContext();
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [cipher, setCipher] = useState("atbash");
  const [passphrase, setPassphrase] = useState("aloha");
  const [error, setError] = useState("");
  const [shift, setShift] = useState("3");
  const [offset, setOffset] = useState("0");

  const CompileEmail = () => {
    const message = `${outputText}\n\n*Encrypted with a classic cipher—dare to decode? You can unlock it using the Aloha Planner App—be sure to ask your friend for more details.`;
    const encoded = encodeURIComponent(message);
    const url = `mailto:?body=${encoded}`;
    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const NewNote = () => {
    var text = outputText;
    var tags = [{ text: "secret", color: "indigo" }];
    var color = "pink";

    addNewNote(text, tags, color);

    toast("Saved To Your Notepad", {
      duration: 4000,
      position: "top-center",
      style: {
        backgroundColor: "lightgreen",
        border: "1px solid #713200",
        fontSize: "16px",
        padding: "10px",
        color: "black",
      },
    });
  };

  const CompileSMS = () => {
    const message = `${outputText}\n\n*Encrypted with a classic cipher—dare to decode? You can unlock it using the Aloha Planner App—be sure to ask your friend for more details.`;
    const encoded = encodeURIComponent(message);
    const url = `sms:?body=${encoded}`;

    if (isAndroid) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  var isAndroid = isMobile && !isIOS ? true : false;
  const CheckPlatform = () => {
    if (window.isNative) {
      // Post the message back to expo
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (window.isIOS) {
      // Post the message back to expo
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  };

  useEffect(() => {
    CheckPlatform();
    return () => {};
  }, []);

  const handleChangePassPhrase = (e) => {
    const newValue = e.target.value.replace(/[^a-z]/gi, "");
    setPassphrase(newValue);
  };

  const handleChangeOffset = (e) => {
    const numeric = e.target.value.replace(/\D/g, "");
    setOffset(numeric);
  };

  // NEW: numeric-only input for shift
  const handleChangeShift = (e) => {
    // remove non-digit characters
    const numeric = e.target.value.replace(/\D/g, "");
    setShift(numeric);
  };

  const handleEncrypt = () => {
    if (cipher in ciphers) {
      const encryptFunction = ciphers[cipher];
      if (cipher === "railFence") {
        // Convert shift and offset to numbers before passing:
        setOutputText(
          ciphers.railFence(
            inputText,
            parseInt(shift, 10), // "Rails"
            parseInt(offset, 10) // "Offset"
          )
        );
      } else if (cipher === "caesar") {
        // Use shift instead of passphrase
        setOutputText(ciphers.caesar(inputText, shift));
      } else if (cipher === "railFence") {
        // Just do: ciphers.railFence(inputText) or pass a numeric rails if you want
        setOutputText(ciphers.railFence(inputText, 3));
      } else {
        setOutputText(ciphers[cipher](inputText, passphrase));
      }
    }
  };

  const handleDecrypt = () => {
    const decryptCipher =
      cipher === "caesar"
        ? "caesarDecrypt"
        : cipher === "bacon"
        ? "baconDecrypt"
        : cipher === "pigPen"
        ? "pigPenDecrypt"
        : cipher === "morse"
        ? "morseDecrypt"
        : cipher === "scytale"
        ? "scytaleDecrypt"
        : cipher === "binary"
        ? "binaryDecrypt"
        : cipher === "railFence"
        ? "railFenceDecrypt"
        : cipher === "vigenere"
        ? "vigenereDecrypt"
        : cipher === "trifid"
        ? "trifidDecrypt"
        : cipher;

    if (!(decryptCipher in ciphers)) return;

    // 1) Grab the correct function
    const decryptFunction = ciphers[decryptCipher];

    // 2) Handle special cases with if/else instead of calling setOutputText twice
    if (decryptCipher === "caesarDecrypt") {
      setOutputText(decryptFunction(inputText, shift));
    } else if (decryptCipher === "railFenceDecrypt") {
      setOutputText(
        ciphers.railFenceDecrypt(
          inputText,
          parseInt(shift, 10),
          parseInt(offset, 10)
        )
      );
    } else {
      // 3) For everything else, do the normal passphrase-based call
      setOutputText(decryptFunction(inputText, passphrase));
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        margin: "0 auto",
        maxWidth: "600px",
      }}
    >
      <h1 style={{ textAlign: "center" }}>Timeless Ciphers:</h1>
      <p
        style={{
          fontSize: 20,
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: 20,
        }}
      >
        {" "}
        Send and Decode Secret Messages With Friends
      </p>

      {/* Row for input/preview textareas */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        {/* Input Text column */}
        <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
          <label
            htmlFor="inputText"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Input Text:
          </label>
          <textarea
            id="inputText"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={{
              width: "100%",
              height: "100px",
              padding: "10px",
              fontSize: 16,
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxSizing: "border-box",
              backgroundColor: "lightyellow",
            }}
          />
        </div>

        {/* Preview column */}
      </div>

      {/* Row for cipher selection and options */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Cipher Selection */}
        <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
          <label
            htmlFor="cipher"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Choose A Cipher:
          </label>
          <select
            id="cipher"
            value={cipher}
            onChange={(e) => setCipher(e.target.value)}
            style={{
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <option value="pigPen">Aloha Planner Cipher</option>
            <option value="atbash">Atbash Cipher</option>
            <option value="bacon">Bacon Cipher</option>
            <option value="binary">Binary Code</option>
            <option value="caesar">Caesar Cipher</option>
            <option value="scytale">Scytale Cipher</option>
            <option value="kamasutra">Kamasutra Cipher</option>
            <option value="morse">Morse Code</option>
            <option value="railFence">Rail Fence Cipher</option>
            <option value="vigenere">Vigenère Cipher</option>
            {/* <option value="trifid">Trifid Cipher</option> */}
          </select>
        </div>

        {/* FIELDS for Caesar and Rail Fence */}
        {(cipher === "caesar" || cipher === "railFence") && (
          <div
            style={{
              flex: "1 1 100%",
              minWidth: "250px",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* Shift becomes "Rails" if the user selected Rail Fence */}
            <label htmlFor="shift">
              {cipher === "caesar" ? "Shift:" : "Rails:"}
            </label>
            <input
              id="shift"
              type="number"
              min="0"
              value={shift}
              onChange={handleChangeShift}
              style={{ width: "60px" }}
            />

            {/* Only show Offset if it's Rail Fence */}
            {cipher === "railFence" && (
              <>
                <label htmlFor="offset">Offset:</label>
                <input
                  id="offset"
                  type="number"
                  min="0"
                  value={offset}
                  onChange={handleChangeOffset}
                  style={{ width: "60px" }}
                />
              </>
            )}
          </div>
        )}

        {/* FIELDS for Vigenere or Trifid */}
        {(cipher === "vigenere" || cipher === "trifid") && (
          <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
            <label
              htmlFor="passphrase"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Passphrase word:
            </label>
            <input
              id="passphrase"
              type="text"
              required
              value={passphrase}
              onChange={handleChangePassPhrase}
              style={{
                width: "100%",
                padding: "5px",
                boxSizing: "border-box",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
        )}

        {/* Convert Buttons */}
        <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={handleEncrypt}
              style={{
                flex: "1",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                backgroundColor: "darkorchid",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Encrypt
            </button>

            <button
              onClick={handleDecrypt}
              style={{
                flex: "1",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                backgroundColor: "#28A745",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Decrypt
            </button>
          </div>
        </div>
        <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
          <label
            htmlFor="outputText"
            style={{ display: "block", marginBottom: "5px" }}
          >
            Converted Text:
          </label>
          <textarea
            id="outputText"
            value={outputText}
            readOnly
            style={{
              width: "100%",
              height: "100px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontSize: 16,
              backgroundColor: "#f9f9f9",
              boxSizing: "border-box",
            }}
          />
        </div>
        {/* Buttons */}
        {outputText.length > 0 ? (
          <div style={{ flex: "1 1 100%", minWidth: "250px" }}>
            <label
              htmlFor="outputText"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Share With Friends
            </label>
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={CompileEmail}
                style={{
                  flex: "1",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "blue",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Email
              </button>

              <button
                onClick={CompileSMS}
                style={{
                  flex: "1",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "blue",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                SMS
              </button>
              <button
                onClick={NewNote}
                style={{
                  flex: "1",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "blue",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                Save To Notes
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <Toaster containerStyle={{ marginTop: 225 }} />
      <div style={{ paddingBottom: 150 }} />
    </div>
  );
};
