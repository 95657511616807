import cx from "classnames/bind";
import { useEffect, useState } from "react";
import classes from "./Spinner.module.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "Authentication";
const classnames = cx.bind(classes);

interface SpinnerProps {
  className?: string;
}

// Inspired by https://dev.to/stephencweiss/create-a-spinner-add-a-loader-in-react-4ic2
export const Spinner = (props: SpinnerProps) => {
  return (
    <div
      role="progressbar"
      className={classnames(classes.spinner, props.className)}
    />
  );
};

export const FullPageSpinner = (props: SpinnerProps) => {
  const [isReload, setIsReload] = useState(false);
  const { t } = useTranslation();
  const { logout } = useAuth();
  const reload = () => {
    //@ts-ignore
    window.location.reload(true);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsReload(true);
    }, 45000);

    return () => {
      // Clear the timeout when the component unmounts
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <Spinner className={classnames(classes.fullPage, props.className)} />
      {isReload ? (
        <div style={{ position: "absolute", bottom: 200, left: 0, right: 0 }}>
          <p
            style={{
              color: "blue",
              backgroundColor: "white",
              textDecoration: "underline",
              textAlign: "center",
              fontSize: 18,
              padding: 20,
              userSelect: "none",
            }}
            onClick={reload}
          >
            {t("spinner-refresh-app")}
          </p>
          <p
            style={{
              color: "blue",
              backgroundColor: "white",
              textDecoration: "underline",
              textAlign: "center",
              fontSize: 18,
              padding: 20,
              userSelect: "none",
            }}
            onClick={() => logout()}
          >
            You can also try logging out by tapping here and then signing in
            again.
          </p>
        </div>
      ) : null}
    </div>
  );
};
