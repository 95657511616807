import React, {
  createRef,
  FormEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
} from "react";
import CreatableSelect from "react-select/async-creatable";
import { ItemModel } from "../../models";
import { useItemInput } from "./useItemInput";
import { LoadOptionsCallback, SelectRef } from "./types";
import toast, { Toaster } from "react-hot-toast";
import classes from "./AddItem.module.css";
import { useTranslation } from "react-i18next";

export interface AddItemsHeaderProps {
  addedItems: ItemModel[];
  allItems: ItemModel[];
  category: string;
  isDarkMode: boolean;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setIsAddingNewItemCompleted: () => void;
  disableCategorySelect?: boolean;
  categories: any[];
}

export const AddItemHeader = forwardRef<SelectRef, AddItemsHeaderProps>(
  (props, ref) => {
    const {
      addedItems,
      category,
      allItems,
      setCategory,
      isDarkMode,
      setIsAddingNewItemCompleted,
      disableCategorySelect,
      categories,
    } = props;
    const { alreadyAdded, isValid, previouslyAdded, value, addItem, setValue } =
      useItemInput(addedItems, category, setCategory, allItems);
    const inputRef = createRef<SelectRef>();
    useImperativeHandle(ref, () => inputRef.current as SelectRef);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
    const [isCatAddItemModalOpen, setIsCatAddItemModalOpen] = useState(false);
    //const categories = useCategories()
    //const { categories } = useCategorySubscription();
    const [addIT, setAddIT] = useState(false);
    const [tempValue, setTempValue] = useState();
    const [quantity, setQuantity] = useState("1");
    const [categoryName, setCategoryName] = useState("");
    const [newList, setNewList] = useState([]);
    const { t } = useTranslation();

    var quickAddLock = 0;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontWeight: state.data.label === "Create" ? "bold" : "bold",
        color: "blue",
        fontSize: "1.2em",
        textDecoration: "underline",
      }),
    };

    const formatCreateLabel = (inputValue) => {
      return (
        <span
          style={{
            fontWeight: "bold",
            backgroundColor: "yellow",
            padding: 5,
            borderRadius: 5,
          }}
        >
          {t("grocery-list-create-item")}: "<em>{inputValue}</em>"
        </span>
      );
    };

    const handleCreateOption = (inputValue) => {
      //  console.log("Creating option:", inputValue);
      const selectedCategory = categories.find((c) => c.id === category);
      const newCatName = selectedCategory ? selectedCategory.name : "";
      setNewList([...newList, { name: inputValue, list: newCatName }]);
      addItem(inputValue, quantity);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      //   console.log("what is category on submit", category)
      // console.log("Does Submit fire?", value);
      event.preventDefault();
      addItem(value, quantity, category);
      setQuantity("1");
      // setNewList([...newList, { name: value, list: categoryName }]);
      // setIsAddingNewItemCompleted();
      inputRef.current?.focus();
    };

    const closeWindow = () => {
      setNewList([]);
      setCategoryName("");
      setIsAddingNewItemCompleted();
    };

    const options = useMemo(
      () =>
        allItems
          .map((i) => ({ value: i.name, label: i.name }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      [allItems]
    );

    const handleLoadOptions = (
      inputValue: string,
      callback: LoadOptionsCallback
    ) =>
      callback(
        options.filter((o) =>
          o.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );

    function quickAdd() {
      setAddIT(false);
      // console.log ("start", quickAddLock)

      if (value != "") {
        quickAddLock = quickAddLock + 1;

        if (quickAddLock == 1) {
          setTimeout(() => {
            quickAddLock = quickAddLock + 1;
            if (previouslyAdded) {
              addItem(value, quantity);
              setQuantity("1");
            } else {
              addItem(value, quantity);
              setQuantity("1");
            }
            setAddIT(false);
          }, 300);

          quickAddLock = quickAddLock + 1;

          toast(t("generic-list-added-toast"), {
            duration: 1000,
            position: "top-center",
            style: {
              backgroundColor: "lightgreen",
              border: "1px solid #713200",
              fontSize: "16px",
              padding: "10px",
              color: "black",
            },
          });
        } else {
          // console.log("end", quickAddLock);
        }
      }
    }

    useEffect(() => {
      const selectedCategory = categories.find((c) => c.id === category);

      if (value != "" || null) {
        const newCatName = selectedCategory ? selectedCategory.name : "";
        setNewList([...newList, { name: value, list: newCatName }]);
      }
    }, [category]);

    return (
      <>
        <div
          style={{
            backgroundColor: "snow",
            opacity: 0.8,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <form
            onSubmit={handleSubmit}
            className={classes.form}
            style={{ backgroundColor: "lavender" }}
          >
            <div className={classes.actions}>
              <label style={{ paddingTop: 15, paddingBottom: 0 }}>
                {newList.length >= 1 ? "" : ""}
              </label>
              {addIT && { isValid } ? quickAdd() : null}
            </div>
            <input
              type="text"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              style={{
                padding: 10,
                fontSize: "1em",
                width: 50,
                border: 1,
                borderRadius: 5,
                borderColor: "lightgrey",
                borderStyle: "inherit",
              }}
            />
            <span style={{ fontSize: "1em", paddingLeft: 0 }}>
              {" "}
              {t("grocery-list-how-many")}
            </span>
            <div style={{ paddingTop: 2 }} />
            <CreatableSelect
              placeholder={t("grocery-list-add-placholder")}
              value={{ label: t("grocery-list-add-placeholder"), value }}
              onChange={(newValue, actionMeta) => {
                if (
                  actionMeta.action === "create-option" ||
                  actionMeta.action === "select-option"
                ) {
                  const selectedCategoryId = newValue.value; // Assuming newValue.value contains the selected category ID
                  const selectedCategory = categories.find(
                    (c) => c.id === selectedCategoryId
                  );
                  setValue(newValue.value || "");
                  setAddIT(true);
                }
              }}
              className={classes.myform}
              loadOptions={handleLoadOptions}
              formatCreateLabel={formatCreateLabel}
              onCreateOption={handleCreateOption}
              noOptionsMessage={() => null}
              isClearable
              ref={inputRef}
              blurInputOnSelect={true}
              styles={customStyles}
            />

            <div
              style={{
                textAlign: "center",
                fontSize: 12,
                fontWeight: "normal",
                fontStyle: "italic",
                paddingTop: 5,
              }}
            >
              {t("grocery-list-add-info")}
            </div>
          </form>
        </div>
      </>
    );
  }
);
