import React, {
  createRef,
  FormEvent,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
} from "react";
import { AIListGenerator } from "../AIListGenerator/AIListGenerator";

//import { useCategories } from "../../CategoriesContext"
import { useCategorySubscriptionToDo } from "../../../ListToDo";
import { CategoryModel, ItemModel } from "../../models";
import { CategoryEditModalToDo } from "../CategoryModal/CategoryEditModalToDo";
import { useItemInputToDo } from "./useItemInputToDo";
import { useItemsToDo } from "../../../ListToDo";
import { LoadOptionsCallback, SelectRef } from "./types";
import toast, { Toaster } from "react-hot-toast";
import classes from "./AddItem.module.css";
import { useTranslation } from "react-i18next";

export interface AddItemsProps {
  addedItemsToDo: ItemModel[];
  allItemsToDo: ItemModel[];
  //categories: CategoryModel[];
  categoryToDo: string;
  setCategoryToDo: React.Dispatch<React.SetStateAction<string>>;
  isDarkMode: boolean;
  setIsAddingNewItemCompleted: () => void;
  disableCategorySelect: boolean;
}

export const AddItemToDo = forwardRef<SelectRef, AddItemsProps>(
  (props, ref) => {
    const {
      addedItemsToDo,
      categoryToDo,
      allItemsToDo,
      disableCategorySelect,
      setCategoryToDo,
      isDarkMode,
      //   categories,
      setIsAddingNewItemCompleted,
    } = props;
    const {
      alreadyAdded,
      isValid,
      previouslyAdded,
      value,
      addItemToDo,
      //  categories,
      setValue,
    } = useItemInputToDo(
      addedItemsToDo,
      categoryToDo,
      setCategoryToDo,
      allItemsToDo
    );
    const inputRef = createRef<SelectRef>();
    useImperativeHandle(ref, () => inputRef.current as SelectRef);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCatEditModalOpen, setIsCatEditModalOpen] = useState(false);
    //const [isCatAddItemModalOpen, setIsCatAddItemModalOpen] = useState(false);
    //const categories = useCategories()

    const { categoriesToDo } = useCategorySubscriptionToDo();
    //const [titleCategory, setTitleCategory] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [newList, setNewList] = useState([]);
    const { t } = useTranslation();

    const fireListMagicToast = () => {
      toast("New List Created", {
        duration: 3000,
        position: "top-center",
        style: {
          backgroundColor: "lightgreen",
          border: "1px solid #713200",
          fontSize: "16px",
          padding: "10px",
          color: "black",
        },
      });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      //   console.log("what is category on submit", category)
      // console.log("what is the item category", categoryName);
      event.preventDefault();
      addItemToDo(value, categoryToDo);
      inputRef.current?.focus();
      if (disableCategorySelect) {
        setNewList([...newList, { name: value, list: categoryName }]);
      }

      setValue("");

      //  setIsAddingNewItemCompleted();
    };

    //console.log("firing adding");

    const closeWindow = () => {
      setNewList([]);
      setCategoryName("");
      setIsAddingNewItemCompleted();
    };

    const handleEnterPressed = (event) => {
      if (event.key === "Enter") {
        // Enter key was pressed
        setIsModalOpen(true);
        // You can perform any desired action here
      }
    };

    const selectedCategory = categoriesToDo.find((c) => c.id === categoryToDo);
    const newCatName = selectedCategory ? selectedCategory.name : "General";

    useEffect(() => {
      if (!disableCategorySelect) {
        const selectedCategory = categoriesToDo.find(
          (c) => c.id === categoryToDo
        );

        const newCatName = selectedCategory ? selectedCategory.name : "General";
        setCategoryName(newCatName);
      }
    }, [categoryToDo]);

    return (
      <>
        <Toaster containerStyle={{ marginTop: 150 }} />
        <div
          // className={classes.form}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "lanvender",
          }}
        >
          <form
            onSubmit={handleSubmit}
            className={classes.form}
            style={{
              backgroundColor: "lavender",

              // display: "flex",
              //  alignItems: "center",
              //  justifyContent: "center",
              //  flexDirection: "column",
            }}
            // style={{ backgroundColor: "#3e98c7" }}
          >
            {disableCategorySelect ? (
              <h2 style={{ textAlign: "center" }}>
                {/*  {t("generic-list-additem-addtext")} {newCatName} List*/}
                Add to{" "}
                <span
                  style={{
                    color: "black",
                    backgroundColor: "yellow",
                    padding: 5,
                  }}
                >
                  {newCatName}
                </span>{" "}
              </h2>
            ) : null}

            <input
              placeholder="Enter item here"
              // onKeyDown={handleEnterPressed}
              // onBlur={handleEnterPressed}
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{
                fontFamily: "inherit",
                fontSize: "1.4em",
                padding: 10,
                width: "100%",
              }}
              required
              className={classes.myform}
            />
            {value != "" && !disableCategorySelect ? (
              <>
                <label style={{ paddingTop: 10 }}>
                  {!disableCategorySelect
                    ? t("tasks-list-selectlist")
                    : t("tasks-list-tolist")}{" "}
                  <span style={{ paddingRight: 10 }} />{" "}
                  <span
                    onClick={() => setIsCatEditModalOpen(true)}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "1em",
                      userSelect: "none",
                    }}
                  >
                    {t("tasks-list-add")}
                  </span>
                </label>
                <select
                  value={categoryToDo}
                  disabled={disableCategorySelect ? true : false}
                  onChange={(event) => {
                    const selectedCategoryId = event.target.value;
                    const selectedCategory = categoriesToDo.find(
                      (c) => c.id === selectedCategoryId
                    );
                    setCategoryToDo(selectedCategoryId);
                    setCategoryName(
                      selectedCategory ? selectedCategory.name : ""
                    );
                  }}
                  style={{
                    fontSize: "1.2em",
                    marginTop: 10,
                    padding: 10,
                    width: 175,
                    border: 0,
                    backgroundColor: "white",
                  }}
                >
                  <option value={"0"}>{t("generic-list-general")}</option>
                  {categoriesToDo
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((c) => (
                      <option key={c.id} value={c.id} title={c.name}>
                        {c.name}
                      </option>
                    ))}
                </select>
              </>
            ) : null}
            <div className={classes.actions}>
              <button
                type="submit"
                //  disabled={!isValid}
                //  className={classes.blinkingButton}
                style={{
                  width: "100%",
                  fontFamily: "inherit",
                  fontSize: 18,
                  color: "white",
                  backgroundColor: "#1e79c3",
                  fontWeight: 500,
                }}
              >
                {previouslyAdded || value === ""
                  ? t("tasks-list-additem")
                  : t("tasks-list-additem")}
              </button>
            </div>

            {/*    {alreadyAdded ? (
          <small className={classes.error}>
            {alreadyAdded.name} has already been added
          </small>
   ) : null} */}
          </form>
          <div style={{ maxWidth: 980 }}>
            {" "}
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                float: "right",

                paddingLeft: 2,
                // backgroundColor: "lavender",
                //  width: "100%",
                // maxWidth: 1000,
              }}
            >
              {!disableCategorySelect ? (
                <AIListGenerator fireToast={fireListMagicToast} />
              ) : null}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          {newList.length >= 1 ? (
            <div
              style={{
                padding: 20,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                maxHeight: "40vh",
                overflow: "scroll",
                overflowY: "scroll",
              }}
            >
              {" "}
              <label
                style={{
                  color: "turquoise",
                  textAlign: "center",
                  paddingBottom: 10,
                }}
              >
                {t("generic-list-additem-newlyadded")}
              </label>
              {newList.map((i) => (
                <div
                  style={{
                    color: "snow",
                    textAlign: "left",
                    paddingLeft: 10,
                    paddingRight: 10,
                    lineHeight: 1,
                    paddingBottom: 10,
                  }}
                >
                  {i.name}
                  {/*}     <span style={{ paddingLeft: 5, fontSize: 12, lineHeight: 1 }}>
                  {!disableCategorySelect
                    ? i.list != ""
                      ? "in " + i.list + " list"
                      : "in General" + " list"
                    : null}
                  </span>*/}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {disableCategorySelect ? (
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={closeWindow}
              //  disabled={!isValid}
              //  className={classes.blinkingButton}
              style={{
                width: newList.length >= 1 ? "85%" : 150,
                marginTop: 20,
                fontFamily: "inherit",
                fontSize: 18,
                color: "black",
                padding: 10,
                borderRadius: 5,
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                maxWidth: "100%",
              }}
            >
              {newList.length >= 1
                ? t("common-button-close")
                : t("common-button-cancel")}
            </button>
          </div>
        ) : newList.length >= 1 ? (
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={closeWindow}
              //  disabled={!isValid}
              //  className={classes.blinkingButton}
              style={{
                width: "100%",
                fontFamily: "inherit",
                fontSize: 18,
                color: "black",
                padding: 10,
                borderRadius: 5,
                backgroundColor: "lightgrey",
                fontWeight: "bold",
                maxWidth: 1000,
              }}
            >
              {t("common-button-clear")}
            </button>
          </div>
        ) : null}

        {newList.length >= 1 && disableCategorySelect ? (
          <p
            style={{
              textAlign: "center",
              padding: 10,
              color: "white",
              marginTop: -5,
              fontSize: 14,
            }}
          >
            {t("generic-list-additem-instruction")}
          </p>
        ) : null}
        {isCatEditModalOpen ? (
          <CategoryEditModalToDo
            isOpen={isCatEditModalOpen}
            close={() => setIsCatEditModalOpen(false)}
            isDarkMode={isDarkMode}
          />
        ) : null}
      </>
    );
  }
);
